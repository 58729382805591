require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/008ae4c76263df8468cb28edbd58dd79/code.js', () => { 

BackendlessUI.Functions.Custom['fn_008ae4c76263df8468cb28edbd58dd79'] = async function fn_008ae4c76263df8468cb28edbd58dd79(arrObj, groupByProperty, ordering, sortProperty) {
return (await (async function(arrObj, groupByProperty, ordering, sortProperty) {
	    if (!Array.isArray(arrObj)) {
	        console.log('No valid array provided');
	        return [];
	    }

	    // Set default values if ordering and sortProperty are not provided
	    ordering = Array.isArray(ordering) ? ordering : [];
	    sortProperty = typeof sortProperty === 'string' ? sortProperty : null;

	    // Group objects by the specified property
	    const groupedByProperty = arrObj.reduce((acc, obj) => {
	        const key = obj[groupByProperty];
	        if (!acc[key]) {
	            acc[key] = [];
	        }
	        acc[key].push(obj);
	        return acc;
	    }, {});

	    // Transform the grouped object into an array of objects
	    const groupedArray = Object.keys(groupedByProperty).map(key => ({
	        key,
	        items: groupedByProperty[key]
	    }));

	    // Sort the grouped array by the custom order, placing unspecified groups at the end
	    groupedArray.sort((a, b) => {
	        const indexA = ordering.indexOf(a.key);
	        const indexB = ordering.indexOf(b.key);

	        // Handling for keys not found in ordering array
	        if (indexA === -1 && indexB === -1) return 0; // Both keys are not in ordering
	        if (indexA === -1) return 1; // Only A is not in ordering, sort B before A
	        if (indexB === -1) return -1; // Only B is not in ordering, sort A before B

	        return indexA - indexB;
	    });

	    // If sortProperty is provided, sort the items within each group
	    if (sortProperty) {
	        groupedArray.forEach(group => {
	            group.items.sort((a, b) => {
	                if (a[sortProperty] < b[sortProperty]) {
	                    return -1;
	                }
	                if (a[sortProperty] > b[sortProperty]) {
	                    return 1;
	                }
	                return 0;
	            });
	        });
	    }

	    return groupedArray;
})(arrObj, groupByProperty, ordering, sortProperty))
}

})
define('./functions/01dc2ae668bc2e08cfc28bba63d7b1f5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_01dc2ae668bc2e08cfc28bba63d7b1f5'] = async function fn_01dc2ae668bc2e08cfc28bba63d7b1f5(arr1, arr2) {
return (await (async function(arr1, arr2) {
	    if(arr1.length === 0 && arr2.length === 0) return false; // Both arrays are empty, considered the same

	    const objectIds1 = arr1.map(obj => obj.objectId).sort().toString();
	    const objectIds2 = arr2.map(obj => obj.objectId).sort().toString();

	    return objectIds1 !== objectIds2;
})(arr1, arr2))
}

})
define('./functions/0543ab417161d6f88b2ae5729dd41eec/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0543ab417161d6f88b2ae5729dd41eec'] = async function fn_0543ab417161d6f88b2ae5729dd41eec(propertiesToRemove, obj) {
return (await (async function(propertiesToRemove, obj) {
	    propertiesToRemove.forEach(prop => {
	        delete obj[prop];
	    });
	    return obj;
})(propertiesToRemove, obj))
}

})
define('./functions/0825bd12567a646d018a52644f41183e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0825bd12567a646d018a52644f41183e'] = async function fn_0825bd12567a646d018a52644f41183e() {
  await (async function() {
  	const script = document.createElement('script');
  	script.src = 'https://unpkg.com/luxon/build/global/luxon.min.js';
  	script.onload = function() {
  	  console.log('Luxon is loaded and ready to use!');
  	  // You can now use Luxon functions after this point.
  	  // Example:
  	  const now = luxon.DateTime.local().setZone('Europe/London').toString();
  	  console.log('London time:', now);
  	};
  	script.onerror = function() {
  	  console.error('Failed to load Luxon.');
  	};
  	document.head.appendChild(script);
  })();

return 
}

})
define('./functions/0915b211c351e798c3c54ba97ad3350e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0915b211c351e798c3c54ba97ad3350e'] = async function fn_0915b211c351e798c3c54ba97ad3350e() {
var toDate, fromDate, localToDate, localFromDate;


  localFromDate = (new Date((new Date()).getTime() - ((6 * 86400) * 1000)));
  localToDate = (new Date());
  fromDate = (await BackendlessUI.Functions.Custom['fn_7b05cfca6696cc956289817a7b99e6cc']((localFromDate.toDateString()), '00:00'));
  toDate = (await BackendlessUI.Functions.Custom['fn_7b05cfca6696cc956289817a7b99e6cc'](((new Date(localToDate.getTime() + ((1 * 86400) * 1000))).toDateString()), '00:00'));

return ({ [`localFromDate`]: localFromDate,[`localToDate`]: localToDate,[`fromDate`]: fromDate,[`toDate`]: toDate })
}

})
define('./functions/14e6171198bfcd5c156a26f3f49af858/code.js', () => { 

BackendlessUI.Functions.Custom['fn_14e6171198bfcd5c156a26f3f49af858'] = function fn_14e6171198bfcd5c156a26f3f49af858(start, breaks, duration) {
var durationWithBreaks, breaks;


  if (breaks) {
    breaks = breaks;
  } else {
    breaks = 0;
  }
  durationWithBreaks = duration + breaks;

return (start + durationWithBreaks * 60000)
}

})
define('./functions/1624b8f8f3c1ab74f0f27ea1fc049fb5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1624b8f8f3c1ab74f0f27ea1fc049fb5'] = async function fn_1624b8f8f3c1ab74f0f27ea1fc049fb5(bundles, bundleStatus, propertyNames) {
return (await (async function(data, bundleStatus, propertyNames) {
	function sumProperties(data, propertyNames, bundleStatus = null) {
	    if (!Array.isArray(data)) {
	        console.error('Input data is not an array');
	        return 0;
	    }

	    // Convert single string inputs to arrays for consistency
	    const propertyNamesArray = Array.isArray(propertyNames) ? propertyNames : [propertyNames];
	    const bundleStatusArray = Array.isArray(bundleStatus) ? bundleStatus : (bundleStatus ? [bundleStatus] : null);

	    // Helper function to convert string numbers to actual numbers
	    const parseStringNumber = (value) => {
	        if (typeof value === 'string') {
	            // Remove commas and convert to number
	            return Number(value.replace(/,/g, ''));
	        }
	        return Number(value);
	    };

	    return data.reduce((total, item) => {
	        // Check bundle status if provided
	        if (bundleStatusArray !== null && !bundleStatusArray.includes(item.status)) {
	            return total;
	        }

	        // Sum up all specified properties
	        const propertySum = propertyNamesArray.reduce((propTotal, propName) => {
	            const value = item[propName];
	            const numericValue = parseStringNumber(value);
	            return propTotal + (isNaN(numericValue) ? 0 : numericValue);
	        }, 0);

	        return total + propertySum;
	    }, 0);
	}

	return sumProperties(data, propertyNames, bundleStatus)
})(bundles, bundleStatus, propertyNames))
}

})
define('./functions/175bed3b3e7cf2c4284426d555ce2d29/code.js', () => { 

BackendlessUI.Functions.Custom['fn_175bed3b3e7cf2c4284426d555ce2d29'] = async function fn_175bed3b3e7cf2c4284426d555ce2d29(PD, objectId) {
var parent2, response, tableName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  tableName = (getObjectProperty(PD, 'selectedType'));
  response = (await Backendless.Data.of(tableName).findById(objectId, Backendless.DataQueryBuilder.create().setRelationsDepth(1)));
  parent2 = (await Backendless.Data.of(tableName).find(Backendless.DataQueryBuilder.create().setWhereClause((['children.objectId = \'',objectId,'\''].join(''))).setPageSize(10)))[0];
  PD['selected'] = ({ [`data`]: (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](response)),[`originalData`]: response,[`parent`]: parent2 });
  console.log('Record loaded, Page data updated:');
  console.log(PD);

return 
}

})
define('./functions/1924fbc5a316058b786863aa7c4f6102/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1924fbc5a316058b786863aa7c4f6102'] = function fn_1924fbc5a316058b786863aa7c4f6102(entityType) {
var entityType;


  entityType = entityType;
  if (entityType == 'locums') {
    return ({ [`single`]: 'locum',[`Label`]: 'name',[`subLabel`]: 'billingEmail' });
  } else if (entityType == 'clients') {
    return ({ [`single`]: 'client',[`Label`]: 'companyName',[`subLabel`]: 'billingEmail' });
  } else if (entityType == 'agencies') {
    return ({ [`single`]: 'agency',[`Label`]: 'companyName',[`subLabel`]: 'agencyCode' });
  } else {
    throw (new Error('ERROR: getEntityConfig - Unknown Entity Type'))
  }

return 
}

})
define('./functions/1a2f7f89a6cb872ca68d3c063693fd6a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1a2f7f89a6cb872ca68d3c063693fd6a'] = async function fn_1a2f7f89a6cb872ca68d3c063693fd6a(nextPayDate) {
var agencyTableData, locumTableData, agencyInvoices, locumInvoices, txResults, error, tx, nextPayDate, rowData, tableData, j, invoices;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function prepareLocumTableData(invoices) {
  tableData = [];
  for (var j_index in invoices) {
    j = invoices[j_index];
    rowData = ({ [`status`]: (getObjectProperty(j, 'status')),[`name`]: (getObjectProperty(j, 'locum.name')),[`invoiceRef`]: (getObjectProperty(j, 'invoiceRef')),[`amount`]: (await BackendlessUI.Functions.Custom['fn_ac5be6b99b3b4a27fe69566b9a68d8e2']((getObjectProperty(j, 'purchasePrice')), 2)),[`objectId`]: (getObjectProperty(j, 'objectId')) });
    addItemToList(tableData, rowData);
  }
  return tableData
}

/**
 * Describe this function...
 */
async function prepareAgencyTableData(invoices) {
  tableData = [];
  for (var j_index2 in invoices) {
    j = invoices[j_index2];
    rowData = ({ [`status`]: (getObjectProperty(j, 'status')),[`name`]: (getObjectProperty(j, 'agency.companyName')),[`invoiceRef`]: (getObjectProperty(j, 'invoiceRef')),[`amount`]: (await BackendlessUI.Functions.Custom['fn_ac5be6b99b3b4a27fe69566b9a68d8e2']((getObjectProperty(j, 'purchasePrice')), 2)),[`objectId`]: (getObjectProperty(j, 'objectId')) });
    addItemToList(tableData, rowData);
  }
  return tableData
}


  nextPayDate = nextPayDate;
  tx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async () => {
    const _uow = tx;

    _uow.find('locumInvoices', Backendless.DataQueryBuilder.create().setWhereClause((String('(status = \'AUTHORISED\' OR status = \'PAYING\') AND dueDate <') + String(nextPayDate))).setRelated(['locum']).setRelationsDepth(1).setPageSize(100)).setOpResultId('locumInvoices');
    _uow.find('agencyInvoices', Backendless.DataQueryBuilder.create().setWhereClause((String('(status = \'AUTHORISED\' OR status = \'PAYING\') AND dueDate <') + String(nextPayDate))).setRelated(['agency']).setRelationsDepth(1).setPageSize(100)).setOpResultId('agencyInvoices');

  })();

  try {
    txResults = (await tx.execute().then(function(result) {
        const error = result && result.error

        if (error) {
          throw error
        }

      return result
      }));

  } catch (error) {
    console.log(error);
    (function (message) { alert(message) })(error);

  }
  console.log('=== tx results');
  console.log(txResults);
  agencyInvoices = (getObjectProperty(txResults, 'results.agencyInvoices.result'));
  locumInvoices = (getObjectProperty(txResults, 'results.locumInvoices.result'));
  console.log('=== locum results');
  console.log(locumInvoices);
  console.log('=== agency results');
  console.log(agencyInvoices);
  locumTableData = await prepareLocumTableData(locumInvoices);
  agencyTableData = await prepareAgencyTableData(agencyInvoices);

return ({ [`locum`]: locumTableData,[`agency`]: agencyTableData })
}

})
define('./functions/1f082225135261fbb908c962d4b69eb8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1f082225135261fbb908c962d4b69eb8'] = async function fn_1f082225135261fbb908c962d4b69eb8(obj, propName) {
return (await (async function(obj, propName) {
	    // Check if the property exists
	    if (!obj.hasOwnProperty(propName)) {
	        return false;
	    }

	    // Get the value of the property
	    const value = obj[propName];

	    // Check if the value is null
	    if (value === null) {
	        return false;
	    }

	    // Check if it's an array and empty
	    if (Array.isArray(value) && value.length === 0) {
	        return false;
	    }

	    return true;
})(obj, propName))
}

})
define('./functions/273918556e9710582ecea65182a733c1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_273918556e9710582ecea65182a733c1'] = async function fn_273918556e9710582ecea65182a733c1() {
var parsedResults, results, item, sessions, transaction;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  transaction = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async () => {
    const _uow = transaction;

    _uow.find('locumTimesheets_view', Backendless.DataQueryBuilder.create().setPageSize(50)).setOpResultId('timesheets');
    _uow.find('locumSessions_view', Backendless.DataQueryBuilder.create().setPageSize(50)).setOpResultId('sessions');
    _uow.find('locumClientList_view', Backendless.DataQueryBuilder.create().setPageSize(50)).setOpResultId('clientList');
    _uow.find('locumAgencyList_view', Backendless.DataQueryBuilder.create().setPageSize(50)).setOpResultId('agencyList');
    _uow.find('locums', Backendless.DataQueryBuilder.create().setPageSize(50)).setOpResultId('locum');

  })();

  results = (await transaction.execute());
  console.log(results);
  sessions = (await asyncListSort((getObjectProperty((getObjectProperty((getObjectProperty(results, 'results')), 'sessions')), 'result')), 1, async (item) => {


   return (getObjectProperty(item, 'start'));
  }));
  // Remove the unnecessary object layers
  parsedResults = ({ [`lastUpdated`]: (new Date()),[`timesheets`]: (await asyncListSort((await (async function(timesheets, sessions) {
  	    return timesheets.map(timesheet => {
  	        // Filter sessions that have the same timesheetId as the current timesheet
  	        const associatedSessions = sessions.filter(session => session.timesheetId === timesheet.timesheetId);

  	        // Add the associated sessions to the timesheet object
  	        timesheet.sessions = associatedSessions;

  	        return timesheet;
  	    });
  })((getObjectProperty((getObjectProperty((getObjectProperty(results, 'results')), 'timesheets')), 'result')), sessions)), 1, async (item) => {


   return (getObjectProperty(item, 'created'));
  })),[`clientList`]: (getObjectProperty((getObjectProperty((getObjectProperty(results, 'results')), 'clientList')), 'result')),[`agencyList`]: (getObjectProperty((getObjectProperty((getObjectProperty(results, 'results')), 'agencyList')), 'result')),[`locum`]: (getObjectProperty((getObjectProperty((getObjectProperty(results, 'results')), 'locum')), 'result'))[0] });

return parsedResults
}

})
define('./functions/308c29a3ac341c6210ff7992dc6c0c16/code.js', () => { 

BackendlessUI.Functions.Custom['fn_308c29a3ac341c6210ff7992dc6c0c16'] = async function fn_308c29a3ac341c6210ff7992dc6c0c16(PgData, entity, entities) {
var entity, data, entities;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

/**
 * Describe this function...
 */
async function setupPageForNew() {
  PgData['formData'] = ({ [`ui`]: ({ [`readOnly`]: false,[`formTitle`]: String('New ') + String(textToTitleCase(entity)),[`editButton`]: false,[`newButton`]: true }) });
}

/**
 * Describe this function...
 */
async function setupPageForEdit() {
  data = (await Backendless.Data.of(entities).findById((getObjectProperty((getObjectProperty(PgData, 'formData')), 'objectId')), Backendless.DataQueryBuilder.create().setRelationsDepth(2)));
  PgData['originalData'] = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](data));
  Object.assign(data, ({ [`ui`]: ({ [`readOnly`]: true,[`formTitle`]: ['Edit ',textToTitleCase(entity),' Details'].join(''),[`editButton`]: true,[`newButton`]: false }) }));
  PgData['formData'] = data;
}


  console.log(['\\/ ======= [',((function () { return BackendlessUI.Navigator.getCurrentPageName() })()),'] ======= \\/'].join(''));
  PgData['dataModel'] = ({ [`entity`]: entity,[`entities`]: entities });
  if (getObjectProperty(PgData, 'formData')) {
    await setupPageForEdit();
  } else {
    await setupPageForNew();
  }
  console.log('Page Data:');
  console.log(PgData);
  console.log(['/\\ ======= [',((function () { return BackendlessUI.Navigator.getCurrentPageName() })()),'] ======= /\\'].join(''));

return 
}

})
define('./functions/3a6150753c7cfa5c62852c7b6e221eaf/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3a6150753c7cfa5c62852c7b6e221eaf'] = async function fn_3a6150753c7cfa5c62852c7b6e221eaf(inputValue, orgType, componentId, PD) {
var options, org, results;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}

/**
 * Describe this function...
 */
async function populateDropdown() {
  if (orgType == 'locums') {
    return (await locum());
  }
  if (orgType == 'clients') {
    return (await client());
  }
  if (orgType == 'agencies') {
    return (await agency());
  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function locum() {
  options = [];
  results = (await Backendless.Data.of('publicLocumsList').find(Backendless.DataQueryBuilder.create().setWhereClause((['name LIKE \'%',inputValue,'%\' OR prn LIKE \'%',inputValue,'%\''].join(''))).setPageSize(100)));
  for (var org_index in results) {
    org = results[org_index];
    addItemToList(options, ({ [`value`]: (getObjectProperty(org, 'objectId')),[`label`]: [(getObjectProperty(org, 'name')),' (',(getObjectProperty(org, 'prn')),')'].join('') }));
  }
  return options
}

/**
 * Describe this function...
 */
async function client() {
  options = [];
  results = (await Backendless.Data.of('publicClientsList').find(Backendless.DataQueryBuilder.create().setWhereClause((['companyName LIKE \'%',inputValue,'%\' OR crn LIKE \'%',inputValue,'%\''].join(''))).setPageSize(100)));
  for (var org_index2 in results) {
    org = results[org_index2];
    addItemToList(options, ({ [`value`]: (getObjectProperty(org, 'objectId')),[`label`]: [(getObjectProperty(org, 'companyName')),' (',(getObjectProperty(org, 'crn')),')'].join('') }));
  }
  return options
}

/**
 * Describe this function...
 */
async function agency() {
  options = [];
  results = (await Backendless.Data.of('publicAgenciesList').find(Backendless.DataQueryBuilder.create().setWhereClause((['companyName LIKE \'%',inputValue,'%\' OR agencyCode LIKE \'%',inputValue,'%\''].join(''))).setPageSize(100)));
  for (var org_index3 in results) {
    org = results[org_index3];
    addItemToList(options, ({ [`value`]: (getObjectProperty(org, 'objectId')),[`label`]: [(getObjectProperty(org, 'companyName')),' (',(getObjectProperty(org, 'agencyCode')),')'].join('') }));
  }
  return options
}


  console.log('input Value');
  console.log(inputValue);
  stopSetTimeout('lazySearch');
  if ((new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$', '')).test(inputValue)) {
    return inputValue;
  } else if (!inputValue) {
    console.log('null inputValue');
  } else {

    ;(function() {
      const callback = async () => {
          options = await populateDropdown();
      if (!!options.length) {
        (getObjectProperty(PD, '___ui'))[componentId] = options;
      }

      };

      const timerId = 'lazySearch';
      const timerDelay = 500;

      runSetTimeout(timerId, callback, timerDelay)
    })()
  }

return 
}

})
define('./functions/3b54db2e49ee77fe587cf71b49b8bdc9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3b54db2e49ee77fe587cf71b49b8bdc9'] = async function fn_3b54db2e49ee77fe587cf71b49b8bdc9(AD, session) {
var data, rateLocumNet, hpsFeeBAgency, hpsFeeAAgency, hpsFeeBLocum, hpsFeeALocum, hpsFeeB_25, costAgency, hpsFeeA_25, extraAgency, duration, rateAgency, session, costLocumNet, costLocum, extraLocumNet, extraLocum, rateLocum;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  hpsFeeA_25 = (getObjectProperty(AD, 'rates.feeA'));
  hpsFeeB_25 = (getObjectProperty(AD, 'rates.feeB'));
  session = session;
  rateLocum = (getObjectProperty(session, 'rateLocum'));
  rateLocumNet = (await BackendlessUI.Functions.Custom['fn_b85246019f927edfeea7c0a48d1b58dd']((rateLocum * (1 - (hpsFeeA_25 + hpsFeeB_25)))));
  extraLocum = (getObjectProperty(session, 'extraLocum'));
  if (!extraLocum) {
    extraLocum = 0;
  }
  extraLocumNet = (await BackendlessUI.Functions.Custom['fn_b85246019f927edfeea7c0a48d1b58dd']((extraLocum * (1 - (hpsFeeA_25 + hpsFeeB_25)))));
  duration = (await (async function(start, end, breaks) {
  	  // If breaks is null or undefined, treat it as 0
  	  const effectiveBreaks = breaks == null ? 0 : breaks;

  	  // Calculate total duration in milliseconds
  	  const totalDurationMs = end - start;

  	  // Convert effective breaks from minutes to milliseconds and subtract from total duration
  	  const breaksMs = effectiveBreaks * 60000; // 1 minute = 60,000 milliseconds
  	  const durationAfterBreaksMs = totalDurationMs - breaksMs;

  	  // Convert the final duration from milliseconds to hours
  	  const durationInHours = durationAfterBreaksMs / 3600000; // 1 hour = 3,600,000 milliseconds

  	  return durationInHours;
  })((getObjectProperty(session, 'start')), (getObjectProperty(session, 'end')), (getObjectProperty(session, 'breaks'))));
  costLocum = (await BackendlessUI.Functions.Custom['fn_b85246019f927edfeea7c0a48d1b58dd']((rateLocum * duration + extraLocum)));
  costLocumNet = (await BackendlessUI.Functions.Custom['fn_b85246019f927edfeea7c0a48d1b58dd']((rateLocumNet * duration + extraLocumNet)));
  hpsFeeALocum = (await BackendlessUI.Functions.Custom['fn_b85246019f927edfeea7c0a48d1b58dd']((costLocum * hpsFeeA_25)));
  hpsFeeBLocum = (await BackendlessUI.Functions.Custom['fn_b85246019f927edfeea7c0a48d1b58dd']((costLocum - (costLocumNet + hpsFeeALocum))));
  rateAgency = (getObjectProperty(session, 'rateAgency'));
  extraAgency = (getObjectProperty(session, 'extraAgency'));
  if (!extraAgency) {
    extraAgency = 0;
  }
  costAgency = (await BackendlessUI.Functions.Custom['fn_b85246019f927edfeea7c0a48d1b58dd']((rateAgency * duration + extraAgency)));
  hpsFeeAAgency = (await BackendlessUI.Functions.Custom['fn_b85246019f927edfeea7c0a48d1b58dd']((costAgency * hpsFeeA_25)));
  hpsFeeBAgency = (await BackendlessUI.Functions.Custom['fn_b85246019f927edfeea7c0a48d1b58dd']((costAgency * hpsFeeB_25)));
  data = ({ [`hpsFeeALocum`]: hpsFeeALocum,[`hpsFeeBLocum`]: hpsFeeBLocum,[`hpsFeeAAgency`]: hpsFeeAAgency,[`hpsFeeBAgency`]: hpsFeeBAgency,[`rateLocumNet`]: rateLocumNet });

return data
}

})
define('./functions/3e05e1564369ba3972f29448d781cc2d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3e05e1564369ba3972f29448d781cc2d'] = async function fn_3e05e1564369ba3972f29448d781cc2d(timestamp) {
return (await (async function(timestamp) {
	// Create a Date object from the timestamp
	let date = new Date(timestamp);

	// Create Intl.DateTimeFormat objects for date and time formatting
	let dateFormatter = new Intl.DateTimeFormat('en-GB', {
	  timeZone: 'Europe/London',
	  year: '2-digit',
	  month: '2-digit',
	  day: '2-digit'
	});

	let timeFormatter = new Intl.DateTimeFormat('en-GB', {
	  timeZone: 'Europe/London',
	  hour: '2-digit',
	  minute: '2-digit',
	  hour12: false
	});

	// Format the date and time
	let formattedDate = dateFormatter.format(date);
	let formattedTime = timeFormatter.format(date);

	// Return the object with date and time
	return {
	  date: formattedDate,
	  time: formattedTime
	};
})(timestamp))
}

})
define('./functions/3fc304d26564606fdef10399a96a1a45/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3fc304d26564606fdef10399a96a1a45'] = async function fn_3fc304d26564606fdef10399a96a1a45(startTimestamp, endTimestamp) {
return (await (async function(startTimestamp, endTimestamp) {
	/** PROMPT:
	A JS function that does this:

	* param = startTimestamp & endTimestamp (in milliseconds)
	* return date + time in this format:
	- timezone = en-GB

	The string it'll return should look like:

	Fr, 24/11 08:00-14:00

	If the endTimestamp is a different day, then display it like this:
	Fr, 24/11, 08:00-14:00+1d <--- NOTE, it will always assume it's +1 day

	If the start timestamp is a different year to the current year, then display it like this:
	Fr, 24/11/23, 08:00-14:00

	If the start timestamp is a different year to the current year and the endTimestamp is a different day, then display it like this:
	Fr, 24/11/23, 08:00-14:00+1d

	**/

	// Convert timestamps to London timezone strings with two-digit year
	const toLondonTimeString = (timestamp) => {
	  return new Date(timestamp).toLocaleString('en-GB', {
	    timeZone: 'Europe/London',
	    year: '2-digit',
	    month: '2-digit',
	    day: '2-digit',
	    hour: '2-digit',
	    minute: '2-digit'
	  });
	};

	const startDateString = toLondonTimeString(startTimestamp);
	const endDateString = toLondonTimeString(endTimestamp);
	const nowDateString = toLondonTimeString(Date.now());

	// Extract components for comparison and formatting
	const extractComponents = (dateString) => {
	  const [date,
	    time] = dateString.split(', ');
	  const [day,
	    month,
	    year] = date.split('/').map(num => parseInt(num, 10));
	  return {
	    date,
	    time,
	    day,
	    month,
	    year
	  };
	};

	const {
	  date: startDate,
	  time: startTime,
	  year: startYear
	} = extractComponents(startDateString);
	const {
	  date: endDate,
	  time: endTime,
	  year: endYear
	} = extractComponents(endDateString);
	const {
	  year: currentYear
	} = extractComponents(nowDateString);

	// Compute day difference based on the first two digits (day)
	const dayDifference = startDateString.slice(0, 2) !== endDateString.slice(0, 2) ? 1: 0;

	// Get abbreviated weekday name
	const startWeekday = new Date(startTimestamp).toLocaleString('en-GB', {
	  weekday: 'short', timeZone: 'Europe/London'
	}).slice(0, -1);

	// Construct start and end formats
	let startFormat = `${startWeekday}, ${startYear === currentYear ? startDate.split('/')[0] + '/' + startDate.split('/')[1]: startDate}, ${startTime}`;
	let endFormat = endTime;

	// Append day difference if greater than zero
	if (dayDifference > 0) {
	  endFormat += `+${dayDifference}d`;
	}

	// Format the final output
	let formattedOutput = `${startFormat} - ${endFormat}`;
	return formattedOutput.replace(" - ", "-");
})(startTimestamp, endTimestamp))
}

})
define('./functions/562d9e7fc3ab43a9c705d48da1997484/code.js', () => { 

BackendlessUI.Functions.Custom['fn_562d9e7fc3ab43a9c705d48da1997484'] = async function fn_562d9e7fc3ab43a9c705d48da1997484(originalData, data) {
return (await (async function(originalData, data) {
	const changes = {};

	if (data && originalData) {
	  Object.keys(data).forEach(key => {
	    if (data[key] !== undefined && originalData[key] !== undefined) {
	      if (Array.isArray(data[key]) && Array.isArray(originalData[key])) {


	        // Perform deep comparison for arrays
	        if (!deepArrayEqual(data[key], originalData[key])) {
	          changes[key] = data[key];
	        }
	      } else if (data[key] !== originalData[key]) {
	        // Handle non-array values
	        changes[key] = data[key];
	      }
	    }
	  });
	}

	return changes;

	// Helper function for deep array comparison
	function deepArrayEqual(arr1, arr2) {
	  if (arr1.length !== arr2.length) {
	    return false;
	  }

	  for (let i = 0; i < arr1.length; i++) {
	    if (typeof arr1[i] === 'object' && typeof arr2[i] === 'object') {
	      if (!deepObjectEqual(arr1[i], arr2[i])) {
	        return false;
	      }
	    } else if (arr1[i] !== arr2[i]) {
	      return false;
	    }
	  }

	  return true;
	}
	function deepObjectEqual(obj1, obj2) {
	  // Handle the case where one or both might be null or undefined
	  if (obj1 === obj2) {
	    return true;
	  }

	  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
	    return false;
	  }

	  const keys1 = Object.keys(obj1);
	  const keys2 = Object.keys(obj2);

	  if (keys1.length !== keys2.length) {
	    return false;
	  }

	  for (let key of keys1) {
	    if (!obj2.hasOwnProperty(key)) {
	      return false;
	    }

	    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
	      if (!deepObjectEqual(obj1[key], obj2[key])) {
	        return false;
	      }
	    } else if (obj1[key] !== obj2[key]) {
	      return false;
	    }
	  }

	  return true;
	}
})((await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](originalData)), (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](data))))
}

})
define('./functions/62f22d354471f48466f1159176eeb226/code.js', () => { 

BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226'] = function fn_62f22d354471f48466f1159176eeb226(timestamp) {
return (new Intl.DateTimeFormat(undefined, ({ [`weekday`]: 'short',[`year`]: 'numeric',[`month`]: 'short',[`day`]: 'numeric',[`timeZone`]: 'Europe/London' })).format((new Date(timestamp))))
}

})
define('./functions/67837848890441d1a81faaf4aee44b29/code.js', () => { 

BackendlessUI.Functions.Custom['fn_67837848890441d1a81faaf4aee44b29'] = async function fn_67837848890441d1a81faaf4aee44b29(filterOptions) {
var bundles, WHERE_CLAUSE, FILTER_OPTIONS, item, bundleStatuses, toDate, fromDate, trimmedOrgs, j, orgs, criteria;

/**
 * Describe this function...
 */
async function bundleStatus() {
  bundleStatuses = (await BackendlessUI.Functions.Custom['fn_6a17ced8fc43780300e93d26cf56a579'](FILTER_OPTIONS, 'bundleStatus'));
  WHERE_CLAUSE = ['status in (',(await Promise.all(bundleStatuses.map(async item => {; return (['\'',item,'\''].join(''));}))).join(','),')'].join('');
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function bundleIssueDate() {
  if ((await BackendlessUI.Functions.Custom['fn_6a17ced8fc43780300e93d26cf56a579'](FILTER_OPTIONS, 'useBundleIssueDate'))) {
    console.log('=== DEBUG fromDate2');
    fromDate = (getObjectProperty(FILTER_OPTIONS, 'bundleIssueDate.fromDate'));
    console.log(getObjectProperty(FILTER_OPTIONS, 'dateRange'));
    console.log(fromDate);
    toDate = (getObjectProperty(FILTER_OPTIONS, 'bundleIssueDate.toDate'));
    if (fromDate && toDate) {
      WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND issueDate >= \'',fromDate,'\' AND issueDate < \'',toDate,'\''].join(''));
    }
  }
}

/**
 * Describe this function...
 */
async function bundlePaidDate() {
  if ((await BackendlessUI.Functions.Custom['fn_6a17ced8fc43780300e93d26cf56a579'](FILTER_OPTIONS, 'useBundlePaidDate'))) {
    fromDate = (getObjectProperty(FILTER_OPTIONS, 'bundlePaidDate.fromDate'));
    toDate = (getObjectProperty(FILTER_OPTIONS, 'bundlePaidDate.toDate'));
    if (fromDate && toDate) {
      WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND paidDate >= \'',fromDate,'\' AND paidDate < \'',toDate,'\''].join(''));
    }
  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function clients() {
  orgs = (getObjectProperty(FILTER_OPTIONS, 'clients'));
  trimmedOrgs = [];
  if (orgs) {
    orgs = orgs.split(',');
    for (var j_index in orgs) {
      j = orgs[j_index];
      j = j.trim();
      addItemToList(trimmedOrgs, j);
    }
    WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND (',(await (async function(names) {
    	return names.map(name => `client.companyName LIKE '${name}%'`).join(' OR ');
    })(trimmedOrgs)),')'].join(''));
  }
}

/**
 * Describe this function...
 */
async function agencies() {
  orgs = (getObjectProperty(FILTER_OPTIONS, 'agencies'));
  trimmedOrgs = [];
  if (orgs) {
    orgs = orgs.split(',');
    for (var j_index2 in orgs) {
      j = orgs[j_index2];
      j = j.trim();
      addItemToList(trimmedOrgs, j);
    }
    WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND (',(await (async function(names) {
    	return names.map(name => `agencyInvoice.agency.companyName LIKE '${name}%'`).join(' OR ');
    })(trimmedOrgs)),')'].join(''));
  }
}

/**
 * Describe this function...
 */
async function locums() {
  orgs = (getObjectProperty(FILTER_OPTIONS, 'locums'));
  trimmedOrgs = [];
  if (orgs) {
    orgs = orgs.split(',');
    for (var j_index3 in orgs) {
      j = orgs[j_index3];
      j = j.trim();
      addItemToList(trimmedOrgs, j);
    }
    WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND (',(await (async function(names) {
    	return names.map(name => `locumInvoices.locum.name LIKE '${name}%'`).join(' OR ');
    })(trimmedOrgs)),')'].join(''));
  }
}

/**
 * Describe this function...
 */
async function bundleRef() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'bundleRef'));
  if (criteria) {
    criteria = criteria.trim();
    WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND bundleRef LIKE \'%',criteria,'%\''].join(''));
  }
}

/**
 * Describe this function...
 */
async function timesheetType() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'timesheetType'));
  if (criteria && criteria != 'ANY') {
    WHERE_CLAUSE = [WHERE_CLAUSE,' AND locumInvoices.timesheets.type = \'',criteria,'\''].join('');
  }
}

/**
 * Describe this function...
 */
async function sessionDates() {
  if ((await BackendlessUI.Functions.Custom['fn_6a17ced8fc43780300e93d26cf56a579'](FILTER_OPTIONS, 'useSessionDates'))) {
    fromDate = (getObjectProperty(FILTER_OPTIONS, 'sessionDates.fromDate'));
    toDate = (getObjectProperty(FILTER_OPTIONS, 'sessionDates.toDate'));
    if (fromDate && toDate) {
      WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND locumInvoices.timesheets.sessions.start >= \'',fromDate,'\' AND locumInvoices.timesheets.sessions.start < \'',toDate,'\''].join(''));
    }
  }
}

/**
 * Describe this function...
 */
async function agencyInvoiceStatus() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'agencyInvStatus'));
  if (criteria && !!criteria.length) {
    WHERE_CLAUSE = [WHERE_CLAUSE,' AND agencyInvoice.status in (',(await Promise.all(criteria.map(async item => {; return (['\'',item,'\''].join(''));}))).join(','),')'].join('');
  }
}

/**
 * Describe this function...
 */
async function timesheetStatus() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'timesheetStatus'));
  if (criteria && !!criteria.length) {
    WHERE_CLAUSE = [WHERE_CLAUSE,' AND locumInvoices.timesheets.status in (',(await Promise.all(criteria.map(async item => {; return (['\'',item,'\''].join(''));}))).join(','),')'].join('');
  }
}

/**
 * Describe this function...
 */
async function timesheetSubmitId() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'timesheetSubmitId'));
  if (criteria) {
    criteria = criteria.trim();
    WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND locumInvoices.timesheets.submitId LIKE \'%',criteria,'%\''].join(''));
  }
}

/**
 * Describe this function...
 */
async function timesheetSubmittedDate() {
  if ((await BackendlessUI.Functions.Custom['fn_6a17ced8fc43780300e93d26cf56a579'](FILTER_OPTIONS, 'useTimesheetSubmittedDate'))) {
    fromDate = (getObjectProperty(FILTER_OPTIONS, 'timesheetSubmittedDate.fromDate'));
    toDate = (getObjectProperty(FILTER_OPTIONS, 'timesheetSubmittedDate.toDate'));
    if (fromDate && toDate) {
      WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND locumInvoices.timesheets.created >= \'',fromDate,'\' AND locumInvoices.timesheets.created < \'',toDate,'\''].join(''));
    }
  }
}

/**
 * Describe this function...
 */
async function timesheetUnapprovedOnly() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'timesheetUnapprovedOnly'));
  if (criteria) {
    WHERE_CLAUSE = String(WHERE_CLAUSE) + String(' AND locumInvoices.timesheets.approved != TRUE');
  }
}


  FILTER_OPTIONS = filterOptions;
  WHERE_CLAUSE = '';
  await bundleStatus();
  await bundleIssueDate();
  await clients();
  await agencies();
  await locums();
  await bundleRef();
  await agencyInvoiceStatus();
  await bundlePaidDate();
  await timesheetType();
  await timesheetStatus();
  await timesheetSubmitId();
  await timesheetSubmittedDate();
  await timesheetUnapprovedOnly();
  await sessionDates();
  console.log('=== DEBUG WHERE CLAUSE');
  console.log(WHERE_CLAUSE);
  bundles = (await Backendless.Data.of('clientBundles').find(Backendless.DataQueryBuilder.create().setWhereClause(WHERE_CLAUSE).setRelationsDepth(3).setSortBy('created DESC').setPageSize(100).setDistinct(true)));
  console.log('==== DEBUG');
  console.log(bundles);
  bundles = (await BackendlessUI.Functions.Custom['fn_008ae4c76263df8468cb28edbd58dd79'](bundles, 'status', ['DRAFT', 'PROCESSING', 'SUBMITTED', 'DELETED', 'AUTHORISED', 'PAID', 'VOIDED'], 'created'));

return bundles
}

})
define('./functions/6982ce25f4e59172a2474f4a2811c67c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6982ce25f4e59172a2474f4a2811c67c'] = async function fn_6982ce25f4e59172a2474f4a2811c67c(dateTime) {
return (await (async function(dateTime) {
	// const now = new Date();
	// const past = new Date(dateTime);
	// const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

	// const diffInSeconds = Math.floor((now - past) / 1000);

	// if (Math.abs(diffInSeconds) < 60) {
	//   return rtf.format(-Math.round(diffInSeconds), 'second');
	// }

	// const diffInMinutes = Math.floor(diffInSeconds / 60);
	// if (Math.abs(diffInMinutes) < 60) {
	//   return rtf.format(-Math.round(diffInMinutes), 'minute');
	// }

	// const diffInHours = Math.floor(diffInMinutes / 60);
	// if (Math.abs(diffInHours) < 24) {
	//   return rtf.format(-Math.round(diffInHours), 'hour');
	// }

	// const diffInDays = Math.floor(diffInHours / 24);
	// if (Math.abs(diffInDays) < 7) {
	//   return rtf.format(-Math.round(diffInDays), 'day');
	// }

	// const diffInWeeks = Math.floor(diffInDays / 7);
	// if (Math.abs(diffInWeeks) < 4) {
	//   return rtf.format(-Math.round(diffInWeeks), 'week');
	// }

	// const diffInMonths = Math.floor(diffInDays / 30);
	// if (Math.abs(diffInMonths) < 12) {
	//   return rtf.format(-Math.round(diffInMonths), 'month');
	// }

	// const diffInYears = Math.floor(diffInDays / 365);
	// return rtf.format(-Math.round(diffInYears), 'year');

	if (dateTime === null || dateTime === undefined) {
	  return null;
	}

	const now = new Date();
	const past = new Date(dateTime);
	const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

	const diffInSeconds = Math.floor((now - past) / 1000);

	if (Math.abs(diffInSeconds) < 60) {
	  return rtf.format(-Math.round(diffInSeconds), 'second');
	}

	const diffInMinutes = Math.floor(diffInSeconds / 60);
	if (Math.abs(diffInMinutes) < 60) {
	  return rtf.format(-Math.round(diffInMinutes), 'minute');
	}

	const diffInHours = Math.floor(diffInMinutes / 60);
	if (Math.abs(diffInHours) < 24) {
	  return rtf.format(-Math.round(diffInHours), 'hour');
	}

	const diffInDays = Math.floor(diffInHours / 24);
	if (Math.abs(diffInDays) < 7) {
	  return rtf.format(-Math.round(diffInDays), 'day');
	}

	const diffInWeeks = Math.floor(diffInDays / 7);
	if (Math.abs(diffInWeeks) < 4) {
	  return rtf.format(-Math.round(diffInWeeks), 'week');
	}

	const diffInMonths = Math.floor(diffInDays / 30);
	if (Math.abs(diffInMonths) < 12) {
	  return rtf.format(-Math.round(diffInMonths), 'month');
	}

	const diffInYears = Math.floor(diffInDays / 365);
	return rtf.format(-Math.round(diffInYears), 'year');

})(dateTime))
}

})
define('./functions/6a17ced8fc43780300e93d26cf56a579/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6a17ced8fc43780300e93d26cf56a579'] = async function fn_6a17ced8fc43780300e93d26cf56a579(obj, nestedPath) {
return (await (async function(obj, nestedPath) {
	/**
	 * Safely retrieves the value of a deeply nested property from an object.
	 *
	 * @param {Object} obj - The object from which to extract the property.
	 * @param {String} nestedPath - A string representation of the property path,
	 *                              with each property name separated by '.'.
	 *                              For example, 'property.subProperty'.
	 *
	 * This function iterates over the `nestedPath`, attempting to access each nested
	 * property in turn. If at any point the next property in the path does not exist
	 * on the current object, the function returns null. This prevents runtime errors
	 * that would occur from attempting to access a property of undefined.
	 *
	 * If the entire path is successfully traversed, the value of the final property
	 * in the path is returned. Otherwise, null is returned to indicate the absence
	 * of a complete path.
	 *
	 * Example usage:
	 * let obj = { a: { b: { c: 1 } } };
	 * let value = getNestedProperty(obj, 'a.b.c'); // Returns 1
	 * let missingValue = getNestedProperty(obj, 'a.b.c.d'); // Returns null
	 *
	 * This function is useful for safely accessing properties deep within an object
	 * structure without needing to manually check the existence of each intermediate
	 * step.
	 */


	const properties = nestedPath.split('.');
	let current = obj;

	for (let i = 0; i < properties.length; i++) {
	  if (current[properties[i]] === undefined) {
	    return null;
	  }
	  current = current[properties[i]];
	}

	return current;

})(obj, nestedPath))
}

})
define('./functions/6b9e65768e7cc24e79061ac4f1822e81/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6b9e65768e7cc24e79061ac4f1822e81'] = async function fn_6b9e65768e7cc24e79061ac4f1822e81(date, time) {
return (await (async function(date, time) {
	 // Convert the parameters to Date objects
	    const dateObj = new Date(date);
	    const startObj = new Date(time);

	    // Extract day, month, and year from the date parameter
	    const day = dateObj.getDate();
	    const month = dateObj.getMonth();
	    const year = dateObj.getFullYear();

	    // Set these values to the time Date object
	    startObj.setDate(day);
	    startObj.setMonth(month);
	    startObj.setFullYear(year);

	    // Return the updated time timestamp
	    return startObj.getTime();
})(date, time))
}

})
define('./functions/746c1e77c956a88fa12d075dabef5d64/code.js', () => { 

BackendlessUI.Functions.Custom['fn_746c1e77c956a88fa12d075dabef5d64'] = async function fn_746c1e77c956a88fa12d075dabef5d64(objectType, orgType) {
var admin, locums, clients, agencies, orgType, newObj, end, start;

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * Describe this function...
 */
async function timesheet() {
  newObj = ({ [`objectId`]: 'new',[`submitId`]: (generateUUID().short()),[`sessions`]: [await session()],[`status`]: 'DRAFT' });
  if (orgType == admin) {
    Object.assign(newObj, ({ [`status`]: 'CLIENT APPROVED' }));
  }
  return newObj
}

/**
 * Describe this function...
 */
async function session() {
  start = (await (async function(hour) {
  	    // Validate the hour
  	    if (hour < 0 || hour > 23) {
  	        throw new Error("Invalid hour. Please provide an hour between 0 and 23.");
  	    }

  	    // Create a date object for today
  	    const now = new Date();

  	    // Convert to UTC
  	    const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

  	    // London is normally 0 hours ahead of UTC, but during DST it's +1 hour
  	    // Assuming DST in London is from the last Sunday in March to the last Sunday in October
  	    const lastSundayMarch = new Date(Date.UTC(utc.getUTCFullYear(), 2, 31));
  	    const lastSundayOctober = new Date(Date.UTC(utc.getUTCFullYear(), 9, 31));
  	    lastSundayMarch.setUTCDate(lastSundayMarch.getUTCDate() - lastSundayMarch.getUTCDay());
  	    lastSundayOctober.setUTCDate(lastSundayOctober.getUTCDate() - lastSundayOctober.getUTCDay());

  	    let londonOffset = 0; // UTC+0
  	    if (utc >= lastSundayMarch && utc < lastSundayOctober) {
  	        londonOffset = 60; // UTC+1 during DST
  	    }

  	    // Set time to the specified hour in London
  	    const londonTime = new Date(utc.getTime() + londonOffset * 60000);
  	    londonTime.setUTCHours(hour, 0, 0, 0);

  	    // Return the timestamp
  	    return londonTime.getTime();
  })(9));
  end = (await (async function(hour) {
  	    // Validate the hour
  	    if (hour < 0 || hour > 23) {
  	        throw new Error("Invalid hour. Please provide an hour between 0 and 23.");
  	    }

  	    // Create a date object for today
  	    const now = new Date();

  	    // Convert to UTC
  	    const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

  	    // London is normally 0 hours ahead of UTC, but during DST it's +1 hour
  	    // Assuming DST in London is from the last Sunday in March to the last Sunday in October
  	    const lastSundayMarch = new Date(Date.UTC(utc.getUTCFullYear(), 2, 31));
  	    const lastSundayOctober = new Date(Date.UTC(utc.getUTCFullYear(), 9, 31));
  	    lastSundayMarch.setUTCDate(lastSundayMarch.getUTCDate() - lastSundayMarch.getUTCDay());
  	    lastSundayOctober.setUTCDate(lastSundayOctober.getUTCDate() - lastSundayOctober.getUTCDay());

  	    let londonOffset = 0; // UTC+0
  	    if (utc >= lastSundayMarch && utc < lastSundayOctober) {
  	        londonOffset = 60; // UTC+1 during DST
  	    }

  	    // Set time to the specified hour in London
  	    const londonTime = new Date(utc.getTime() + londonOffset * 60000);
  	    londonTime.setUTCHours(hour, 0, 0, 0);

  	    // Return the timestamp
  	    return londonTime.getTime();
  })(11));
  newObj = ({ [`rowId`]: (generateUUID().short()),[`date`]: ((new Date()).valueOf()),[`start`]: start,[`end`]: end,[`dates`]: ({ [`start`]: (await BackendlessUI.Functions.Custom['fn_3e05e1564369ba3972f29448d781cc2d'](start)),[`end`]: (await BackendlessUI.Functions.Custom['fn_3e05e1564369ba3972f29448d781cc2d'](end)) }),[`duration`]: 120,[`rateLocum`]: 85,[`extraLocum`]: null,[`notesLocum`]: null });
  if (orgType == admin || orgType == agencies) {
    Object.assign(newObj, ({ [`rateAgency`]: 10 }));
  }
  return newObj
}


  orgType = orgType;
  agencies = 'agencies';
  clients = 'clients';
  locums = 'locums';
  admin = 'admin';
  if (objectType == 'timesheet') {
    return (await timesheet());
  } else if (objectType == 'session') {
    return (await session());
  }

return 
}

})
define('./functions/7b05cfca6696cc956289817a7b99e6cc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7b05cfca6696cc956289817a7b99e6cc'] = async function fn_7b05cfca6696cc956289817a7b99e6cc(dateString, timeString) {
return (await (async function(dateString, timeString) {
	/**
	* Converts a specified date and time in London time to its equivalent UTC timestamp, attempting
	* to account for Daylight Saving Time (DST). This function is a simplified approach and may not
	* accurately reflect all historical or future DST changes in the Europe/London timezone. It
	* combines the provided date and time as if they were in London time, then adjusts to UTC,
	* attempting to consider whether the date falls within the typical DST period for London.
	*
	* Note: The accuracy of DST adjustment is based on a general rule and may not apply in all cases.
	*
	* @param {string} dateString - The date in a format like "Sun Mar 31 2024". Assumes the date is in the Gregorian calendar.
	* @param {string} timeString - The time in "HH:MM" format (24-hour clock), assumed to be in London time.
	* @returns {number} The UTC timestamp (milliseconds since the Unix Epoch) for the specified London time, with an attempt to account for DST.
	*/

	// Combine date and time strings and assume it's in London time
	const dateTimeString = `${dateString} ${timeString}`;

	// Convert to a Date object as if it were UTC
	const dateInUTC = new Date(`${dateTimeString} UTC`);

	// Attempt to determine if date is in DST
	// This example uses a simple method for 2021 and beyond; it will not be accurate for all years.
	const month = dateInUTC.getUTCMonth() + 1; // 1-12
	const day = dateInUTC.getUTCDate();
	const isDST = (month > 3 && month < 10) ||
	(month === 3 && day >= 25) ||
	(month === 10 && day < 25);

	// Adjust for London's timezone offset from UTC
	// Note: London is either UTC or UTC+1 depending on DST.
	const londonOffset = isDST ? -60: 0; // London is UTC+1 during DST, which means we subtract 60 minutes

	// Adjust the UTC timestamp to account for London's DST
	const utcTimestamp = dateInUTC.getTime() + londonOffset * 60 * 1000;

	return utcTimestamp;
})(dateString, timeString))
}

})
define('./functions/8111343ee683711cbe8724295429ac7c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'] = async function fn_8111343ee683711cbe8724295429ac7c(variable) {
return (await (async function(variable) {
	// Check if the variable is null or undefined, return null if true
	if (variable === null || variable === undefined) {
	    return null;
	}

	// Deep copy the variable
	const copiedVariable = JSON.parse(JSON.stringify(variable));

	return copiedVariable;

})(variable))
}

})
define('./functions/8277f9b1052729382e74263567a5484b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8277f9b1052729382e74263567a5484b'] = async function fn_8277f9b1052729382e74263567a5484b(sessions) {
return (await (async function(sessions) {
	    if (!Array.isArray(sessions)) {
	        console.error('The provided input is not a valid array of sessions');
	        return "0.00";
	    }

	    const totalCost = sessions.reduce((acc, session) => {
	        return acc + (session.costLocum || 0); // If costLocum is undefined, it will use 0
	    }, 0);

	    return totalCost.toFixed(2);
})(sessions))
}

})
define('./functions/853668b213d913717a429cf7b3de8951/code.js', () => { 

BackendlessUI.Functions.Custom['fn_853668b213d913717a429cf7b3de8951'] = async function fn_853668b213d913717a429cf7b3de8951(arrOfValues, valueToTest) {
var status2;



return (await (async function(arrOfValues, valueToTest) {
	    return !arrOfValues.includes(valueToTest);
})(arrOfValues, valueToTest))
}

})
define('./functions/85f73c63143d8a66534a2e0ffde67a95/code.js', () => { 

BackendlessUI.Functions.Custom['fn_85f73c63143d8a66534a2e0ffde67a95'] = async function fn_85f73c63143d8a66534a2e0ffde67a95(date, start, duration) {
var end, start, duration;


  if (duration == 0) {
    duration = 2;
  } else {
    duration = duration;
  }
  console.log('-------- duration');
  console.log(duration);
  start = (await BackendlessUI.Functions.Custom['fn_6b9e65768e7cc24e79061ac4f1822e81'](date, start));
  console.log(duration * 3600000);
  end = start + duration * 3600000;
  console.log(end);

return ({ [`start`]: start,[`end`]: end })
}

})
define('./functions/8b17c7e758c1a3683aa952f75eedab73/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8b17c7e758c1a3683aa952f75eedab73'] = async function fn_8b17c7e758c1a3683aa952f75eedab73(strNum) {
return (await (async function(str) {
	  if (typeof str !== 'string') {
	    throw new Error('Input must be a string');
	  }

	  // Remove commas and convert to float
	  const cleanedStr = str.replace(/,/g, '');
	  const number = parseFloat(cleanedStr);

	  if (isNaN(number)) {
	    throw new Error('Invalid number format');
	  }

	  return number;
})(strNum))
}

})
define('./functions/8d0fb074024e2b3901fa17c7542fe12b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'] = async function fn_8d0fb074024e2b3901fa17c7542fe12b(num) {
return (await (async function(num) {
	//   // Truncate the number to two decimal places
	// const truncated = Number((num * 100).toFixed(0)) / 100;

	// // Convert the truncated number to a string with two decimal places
	// return truncated.toFixed(2);


	    // Check for null and undefined explicitly

	    if (num === null || num === undefined) {
	        console.error('formatToTwoDecimals : The provided input is null or undefined');
	        return "0.00";
	    }

	    // If the input is a string, try to convert it to a number
	    if (typeof num === 'string') {
	        num = parseFloat(num);
	        if (isNaN(num)) {
	            console.error('formatToTwoDecimals : The provided string is not convertible to a number');
	            return "0.00";
	        }
	    } else if (typeof num !== 'number') {
	        console.error('formatToTwoDecimals : The provided input is not a valid number');
	        return "0.00";
	    }

	    // Format the number to two decimal places
	    return num.toFixed(2);


})(num))
}

})
define('./functions/953ad59737414af3d40b6a3d8c5f2201/code.js', () => { 

BackendlessUI.Functions.Custom['fn_953ad59737414af3d40b6a3d8c5f2201'] = function fn_953ad59737414af3d40b6a3d8c5f2201(AD) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return ((getObjectProperty(AD, 'currentOrg.___class')) == 'admin')
}

})
define('./functions/95ba4446c12cc8fc5e3977e10f082fff/code.js', () => { 

BackendlessUI.Functions.Custom['fn_95ba4446c12cc8fc5e3977e10f082fff'] = async function fn_95ba4446c12cc8fc5e3977e10f082fff(data) {
var children, users, arr;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getObjectIds(arr) {
  return (await (async function(arrayOfObjects) {
	// Check if the input is undefined or not an array
	if (!Array.isArray(arrayOfObjects)) {
	  return [];
	}

	// Return an array of objects containing only the objectId property
	return arrayOfObjects.map(object => ({ objectId: object.objectId }));

})(arr))
}


  users = (getObjectProperty(data, 'users'));
  children = (getObjectProperty(data, 'children'));
  if (users) {
    data['users'] = (await getObjectIds(users));
  }
  if (children) {
    data['children'] = (await getObjectIds(children));
  }

return (await (async function(data) {
	    const result = {};
	    for (const key in data) {
	        if (!key.startsWith('___')) {
	            result[key] = data[key];
	        }
	    }
	    return result;
})(data))
}

})
define('./functions/9bc571d21c383c3a238bc45bd609c301/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9bc571d21c383c3a238bc45bd609c301'] = async function fn_9bc571d21c383c3a238bc45bd609c301(dateStr) {
return (await (async function(dateStr) {
	// Parse the date string into day, month, and year
	const [day, month, year] = dateStr.split('/').map(num => parseInt(num, 10));

	// Check if year, month, and day are valid numbers
	if (isNaN(year) || isNaN(month) || isNaN(day)) {
	    return "Invalid date!";
	}

	// Adjust for yy format to yyyy (assuming 21st century)
	const fullYear = year + (year < 100 ? 2000 : 0);

	// Create a Date object (months are 0-indexed in JavaScript Date)
	const inputDate = new Date(fullYear, month - 1, day);

	// Check if it's an invalid date (e.g., 30th of February)
	if (inputDate.getFullYear() !== fullYear || inputDate.getMonth() !== month - 1 || inputDate.getDate() !== day) {
	    return "Invalid date!";
	}

	// Get today's date without time for comparison
	const today = new Date();
	today.setHours(0, 0, 0, 0);

	// Check if the date is in the future
	if (inputDate > today) {
	    return "Date cannot be in future";
	}

	return true;

})(dateStr))
}

})
define('./functions/9be1843aef6b25e95f9ce7ebced72c53/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9be1843aef6b25e95f9ce7ebced72c53'] = async function fn_9be1843aef6b25e95f9ce7ebced72c53(groupArray, newItem, moveToGroup, propToMatch) {
  await (async function(groupArray, newItem, moveToGroup, propToMatch) {

  	    // Set default property to match if not provided
  	    propToMatch = propToMatch || 'objectId';

  	    // Find the group containing the item to be moved
  	    const currentGroup = groupArray.find(group =>
  	        group.items.some(item => item[propToMatch] === newItem[propToMatch]));

  	    if (currentGroup) {
  	        // Remove the item from its current group
  	        currentGroup.items = currentGroup.items.filter(item =>
  	            item[propToMatch] !== newItem[propToMatch]);
  	    }

  	    // Find the group to move the item to
  	    const targetGroup = groupArray.find(group => group.key === moveToGroup);

  	    if (targetGroup) {
  	        // Add the new item to the target group
  	        targetGroup.items.push(newItem);
  	    } else {
  	        // If the target group does not exist, create it and add the item
  	        groupArray.push({
  	            key: moveToGroup, items: [newItem]
  	        });
  	    }


  })(groupArray, newItem, moveToGroup, propToMatch);

return 
}

})
define('./functions/9cfbe496e6b8ffd1c2ab3ee189e875f2/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9cfbe496e6b8ffd1c2ab3ee189e875f2'] = async function fn_9cfbe496e6b8ffd1c2ab3ee189e875f2(data) {
return (await (async function(data) {
	let flattenedArray = [];
	    for (let key in data) {
	        if (Array.isArray(data[key])) {
	            flattenedArray = flattenedArray.concat(data[key]);
	        }
	    }
	    return flattenedArray;
})(data))
}

})
define('./functions/a2db9ec81f04b2cd4cabedd5ab774c1b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a2db9ec81f04b2cd4cabedd5ab774c1b'] = async function fn_a2db9ec81f04b2cd4cabedd5ab774c1b(weeksBack, humanReadable) {
return (await (async function(weeksBack, humandReadable) {
	const now = new Date();
	const dayOfWeek = now.getDay(); // Sunday - 0, Monday - 1, etc.
	const firstDayOfWeek = new Date(now);
	firstDayOfWeek.setDate(now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6: 1)); // Set to previous Monday
	firstDayOfWeek.setHours(0, 0, 0, 0); // Start of the day

	const startOfWeek = new Date(firstDayOfWeek);
	startOfWeek.setDate(firstDayOfWeek.getDate() - 7 * weeksBack);

	const endOfWeek = new Date(startOfWeek);
	endOfWeek.setDate(startOfWeek.getDate() + 6);

	return {
	  start: humanReadable ? formatDate(startOfWeek): startOfWeek.getTime(),
	  end: humanReadable ? formatDate(endOfWeek): endOfWeek.getTime()
	};


	function formatDate(date) {
	  const day = String(date.getDate()).padStart(2, '0');
	  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
	  const year = date.getFullYear();
	  return `${day}/${month}/${year}`;
	}
})(weeksBack, humanReadable))
}

})
define('./functions/a6649aae860ad2589b2b1691eca72b44/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'] = async function fn_a6649aae860ad2589b2b1691eca72b44(obj, nestedPath, value) {
  await (async function(obj, nestedPath, value) {
  	/**
  	 * Sets a deeply nested value within an object, creating intermediate objects as needed.
  	 *
  	 * @param {Object} obj - The base object on which to set the value.
  	 * @param {String} nestedPath - A dot-separated string indicating the path where the value should be set.
  	 * @param {*} value - The value to set at the target location.
  	 *
  	 * The function first splits `nestedPath` into an array of strings representing each property in the path.
  	 * It then iterates through these properties, ensuring each exists and is an object. If a property in the path
  	 * does not exist or is not an object, it is initialized as an empty object. This process continues until the
  	 * script reaches the second-to-last property in the path.
  	 *
  	 * Finally, the function sets `value` on the final property in the path, ensuring that the deeply nested
  	 * structure is updated as intended. This utility is helpful for dynamically generating nested structures
  	 * without manually verifying and creating each level of the hierarchy.
  	 *
  	 * Example Usage:
  	 * let obj = {};
  	 * setNestedValue(obj, 'a.b.c', 4);
  	 * // obj is now { a: { b: { c: 4 } } }
  	 */

  	const properties = nestedPath.split('.');
  	let current = obj;

  	for (let i = 0; i < properties.length - 1; i++) {
  	  const property = properties[i];

  	  // If the property doesn't exist, or is not an object, create it
  	  if (!current[property] || typeof current[property] !== 'object') {
  	    current[property] = {};
  	  }

  	  current = current[property];
  	}

  	// Set the value for the final property
  	current[properties[properties.length - 1]] = value;

  })(obj, nestedPath, value);

return 
}

})
define('./functions/a80c86cafe9ac2ba7d276a696a9fdb1c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a80c86cafe9ac2ba7d276a696a9fdb1c'] = function fn_a80c86cafe9ac2ba7d276a696a9fdb1c(startTimeString, endTimeString) {
var endHour, startHour, endMin, startMin;


  if (!((new RegExp('^(?:[01]\\d|2[0-3]):[0-5]\\d$', '')).test(startTimeString)) || !((new RegExp('^(?:[01]\\d|2[0-3]):[0-5]\\d$', '')).test(endTimeString))) {
    return 'calculateDayDiffBetweenTimeStrings: Incorrect TimeStrings';
  }
  startHour = (Number((startTimeString.slice(0, 2))));
  endHour = (Number((endTimeString.slice(0, 2))));
  startMin = (Number((startTimeString.slice(3, 5))));
  endMin = (Number((endTimeString.slice(3, 5))));
  if (startHour == endHour) {
    if (startMin >= endMin) {
      return 1;
    } else {
      return 0;
    }
  }
  if (startHour > endHour) {
    return 1;
  } else {
    return 0;
  }

return 
}

})
define('./functions/ab1c9e4dddcc718449eda1a4d06cdfce/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ab1c9e4dddcc718449eda1a4d06cdfce'] = async function fn_ab1c9e4dddcc718449eda1a4d06cdfce(duration) {
return (await (async function(duration) {
	    // Check if (duration * 2) / 60 is a whole number
	    if ((duration * 2) % 60 === 0) {
	        const hours = duration / 60;
	        const hourStr = hours === 1 ? "hr" : "hrs";
	        return `${hours}${hourStr}`;
	    } else {
	        // Convert duration into hours and minutes
	        const hours = Math.floor(duration / 60);
	        const minutes = duration % 60;
	        const hoursStr = hours > 0 ? `${hours}h ` : "";
	        const minutesStr = `${minutes}m`;
	        return hoursStr + minutesStr;
	    }
})(duration))
}

})
define('./functions/ac5be6b99b3b4a27fe69566b9a68d8e2/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ac5be6b99b3b4a27fe69566b9a68d8e2'] = async function fn_ac5be6b99b3b4a27fe69566b9a68d8e2(number, decimalPlaces) {
return (await (async function(number, decimalPlaces) {
	function formatNumber(number, decimalPlaces = 2) {
	  // Ensure the input is a number
	  if (typeof number !== 'number') {
	    number = parseFloat(number);
	  }

	  // Check if the parsed number is valid
	  if (isNaN(number)) {
	    return 0;
	  }

	  // Format the number to the specified decimal places
	  const formattedNumber = number.toFixed(decimalPlaces);

	  // Split the number into integer and decimal parts
	  const [integerPart, decimalPart] = formattedNumber.split('.');

	  // Add commas to the integer part
	  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	  // Combine the formatted integer part with the decimal part (if any)
	  return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
	}

	return formatNumber(number,decimalPlaces);
})(number, decimalPlaces))
}

})
define('./functions/af8487abc61991810b27ab23ad6b00b6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_af8487abc61991810b27ab23ad6b00b6'] = async function fn_af8487abc61991810b27ab23ad6b00b6(arrObj, groupByProperty, childPropertyName) {
return (await (async function(arrObj, groupByProperty, childPropertyName) {
	const uniqueItems = extractUniqueItems(arrObj, groupByProperty);
	const results = assignGroupedItems(arrObj, uniqueItems, groupByProperty, childPropertyName);
	return results;


	function extractUniqueItems(arrObj, groupByProperty) {
	  const uniqueItemsMap = new Map();

	  arrObj.forEach(item => {
	    const key = item[groupByProperty];
	    if (key && !uniqueItemsMap.has(key.objectId)) {
	      uniqueItemsMap.set(key.objectId, key);
	    }
	  });

	  return Array.from(uniqueItemsMap.values());
	}

	function assignGroupedItems(arrObj, uniqueItems, groupByProperty, childPropertyName) {
	  // Iterate over uniqueItems to enrich them with child items
	  uniqueItems.forEach(uniqueItem => {
	    const groupedItems = arrObj.filter(item => item[groupByProperty].objectId === uniqueItem.objectId)
	                               .map(item => {
	                                 const itemCopy = {...item};
	                                 delete itemCopy[groupByProperty];
	                                 return itemCopy;
	                               });

	    uniqueItem[childPropertyName] = groupedItems;
	  });

	  return uniqueItems;
	}
})(arrObj, groupByProperty, childPropertyName))
}

})
define('./functions/b05c7583efeabed9193f45e5570114cc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc'] = async function fn_b05c7583efeabed9193f45e5570114cc(num) {
return (await (async function(num) {
	    // If the input is a string, try to convert it to a number
	    if (typeof num === 'string') {
	        num = parseFloat(num);
	        if (isNaN(num)) {
	            console.error('formatToOneDecimal : The provided string is not convertible to a number');
	            return null;
	        }
	    } else if (typeof num !== 'number') {
	        // console.error('formatToOneDecimal : The provided input is not a valid number or string');
	        return null;
	    }

	    // Truncate the number to one decimal place
	    const truncated = Math.floor(num * 10) / 10;

	    // Convert the truncated number to a string with one decimal place
	    return truncated.toFixed(1);
})(num))
}

})
define('./functions/b13640f1bb6a1bb21cd26e5662e3b20d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b13640f1bb6a1bb21cd26e5662e3b20d'] = function fn_b13640f1bb6a1bb21cd26e5662e3b20d(startTimestamp, endTimestamp) {
var durationHours, durationMilliseconds;


  durationMilliseconds = endTimestamp - startTimestamp;
  durationHours = durationMilliseconds / 3600000;

return (Math.round(durationHours * 10) / 10)
}

})
define('./functions/b58d8df10e261e42867bdfc29fe57c28/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b58d8df10e261e42867bdfc29fe57c28'] = async function fn_b58d8df10e261e42867bdfc29fe57c28(arr, propertyToMatch, valueToMatch) {
return (await (async function(arr, propertyToMatch, valueToMatch) {
	/**
	This function searches within an array of objects to find and return the first object that has a specific property matching a given value.

	Parameters:

	arr: An array of objects where the search will be conducted.
	propertyToMatch: The property (key) within the objects that the function should look for.
	valueToMatch: The value that should match the specified property within the object.
	Return Value:

	If a matching object is found, the function returns the object.
	If no match is found or if the provided arr is not an array, the function returns null.
	Example Usage:

	Given an array of objects like [{name: "John", age: 30}, {name: "Jane", age: 25}], calling findObjectByProperty(objects, "name", "Jane") would return the object {name: "Jane", age: 25}.

	*/


	    // Check if arr is an array
	    if (!Array.isArray(arr)) {
	        return null;
	    }

	    // Find and return the first object that matches the criteria
	    return arr.find(obj => obj[propertyToMatch] === valueToMatch) || null;

})(arr, propertyToMatch, valueToMatch))
}

})
define('./functions/b85246019f927edfeea7c0a48d1b58dd/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b85246019f927edfeea7c0a48d1b58dd'] = function fn_b85246019f927edfeea7c0a48d1b58dd(num) {
return (Math.round(num * 100) / 100)
}

})
define('./functions/b9cb147c2b12b97283a200f7517fb5af/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b9cb147c2b12b97283a200f7517fb5af'] = async function fn_b9cb147c2b12b97283a200f7517fb5af(sessions) {
return (await (async function(sessions) {
	    if (!Array.isArray(sessions)) {
	        console.error('The provided input is not a valid array of sessions');
	        return "0.00";
	    }

	    const totalCost = sessions.reduce((acc, session) => {
	        return acc + (session.costAgency || 0); // If costAgency is undefined, it will use 0
	    }, 0);

	    return totalCost.toFixed(2);
})(sessions))
}

})
define('./functions/c627fabbfaee5fd7f19b87b4e00b9624/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c627fabbfaee5fd7f19b87b4e00b9624'] = async function fn_c627fabbfaee5fd7f19b87b4e00b9624(tableName, data, originalData, orgId) {
var changes, tableName, submitData, originalData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  submitData = data;
  originalData = originalData;
  tableName = tableName;
  console.log('Submitted data:');
  console.log(submitData);
  console.log('Original Data:');
  console.log(originalData);
  changes = (await BackendlessUI.Functions.Custom['fn_562d9e7fc3ab43a9c705d48da1997484'](originalData, submitData));
  changes = (Object.assign(changes, ({ [`objectId`]: (getObjectProperty(submitData, 'objectId')) })));
  changes = (await BackendlessUI.Functions.Custom['fn_95ba4446c12cc8fc5e3977e10f082fff'](changes));
  console.log('Changes to be submitted:');
  console.log(changes);
  await Backendless.Request.put(`${Backendless.appPath}/services/ACCOUNTS/upsertEntity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(changes, 'objectId')),'data': changes,'orgType': tableName });

return 
}

})
define('./functions/c7349f45881464bd6bffa0b1c118b2c3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c7349f45881464bd6bffa0b1c118b2c3'] = async function fn_c7349f45881464bd6bffa0b1c118b2c3(arrObj, propertyToOrder, generalOrder, specificOrder) {
return (await (async function(propertyToOrder, generalOrder, specificOrder, arrObj) {
	// Check and set defaults if specificOrder or generalOrder are not provided
	specificOrder = specificOrder || [];
	generalOrder = generalOrder || 'ASC';

	return arrObj.sort((a, b) => {
	  const indexA = specificOrder.indexOf(a[propertyToOrder]);
	  const indexB = specificOrder.indexOf(b[propertyToOrder]);

	  // If specificOrder is specified and both elements are in it
	  if (specificOrder.length > 0 && indexA !== -1 && indexB !== -1) {
	    return indexA - indexB;
	  }

	  // If specificOrder is specified but one of the elements is not in it
	  if (specificOrder.length > 0 && (indexA === -1 || indexB === -1)) {
	    if (indexA === -1 && indexB !== -1) {
	      return 1; // a goes after b
	    } else if (indexA !== -1 && indexB === -1) {
	      return -1; // a goes before b
	    }
	    // If both are not in specificOrder, fall through to generalOrder sorting
	  }

	  // GeneralOrder sorting (applies to items not in specificOrder or when specificOrder is not provided)
	  if (generalOrder.toUpperCase() === 'ASC') {
	    return (a[propertyToOrder] > b[propertyToOrder]) ? 1: (a[propertyToOrder] < b[propertyToOrder]) ? -1: 0;
	  } else {
	    // DESC
	    return (a[propertyToOrder] < b[propertyToOrder]) ? 1: (a[propertyToOrder] > b[propertyToOrder]) ? -1: 0;
	  }
	});
})(propertyToOrder, generalOrder, specificOrder, arrObj))
}

})
define('./functions/cd19d82930a3250ac16fcdea33b85299/code.js', () => { 

BackendlessUI.Functions.Custom['fn_cd19d82930a3250ac16fcdea33b85299'] = async function fn_cd19d82930a3250ac16fcdea33b85299(time) {
return (await (async function(dateTime) {
	    // Convert the dateTime timestamp to a Date object and extract local date and time
	    const localDateObj = new Date(dateTime);
	    const extractedDate = {
	        day: localDateObj.getDate(),
	        month: localDateObj.getMonth() + 1,  // getMonth() returns 0-based month
	        year: localDateObj.getFullYear(),
	        hours: localDateObj.getHours(),
	        minutes: localDateObj.getMinutes()
	    };

	    // Construct a date-time string using the extracted values
	    const dateTimeString = `${extractedDate.year}-${String(extractedDate.month).padStart(2, '0')}-${String(extractedDate.day).padStart(2, '0')}T${String(extractedDate.hours).padStart(2, '0')}:${String(extractedDate.minutes).padStart(2, '0')}:00`;

	    // Parse the date-time string into a Date object
	    const combinedDateObj = new Date(dateTimeString);

	    // Adjust for the Europe/London timezone
	    const utcOffset = combinedDateObj.getTimezoneOffset();
	    const londonOffset = new Intl.DateTimeFormat('en-GB', {
	        timeZone: 'Europe/London',
	        timeZoneName: 'short'
	    }).format(combinedDateObj).split(' ')[2] === 'GMT' ? 0 : -60;
	    const offsetDifference = utcOffset - londonOffset;

	    // Adjust the combined timestamp by the offset difference to get London time
	    const londonTimestamp = combinedDateObj.getTime() - offsetDifference * 60 * 1000;

	    return londonTimestamp;
})(time))
}

})
define('./functions/cdfe47760139c8ec60d8d36d5d3ad607/code.js', () => { 

BackendlessUI.Functions.Custom['fn_cdfe47760139c8ec60d8d36d5d3ad607'] = function fn_cdfe47760139c8ec60d8d36d5d3ad607(startTimestamp, endTimestamp) {
var endTime, startTime, startDate, end, start;


  start = (new Date(startTimestamp));
  // Only show year if it's a different year
  if ((new Date(start).getFullYear()) != (new Date((new Date())).getFullYear())) {
    startDate = (new Intl.DateTimeFormat('en-GB', ({ [`weekday`]: 'short',[`day`]: 'numeric',[`month`]: '2-digit',[`year`]: '2-digit',[`timeZone`]: 'Europe/London' })).format(start));
  } else {
    startDate = (new Intl.DateTimeFormat('en-GB', ({ [`weekday`]: 'short',[`day`]: 'numeric',[`month`]: '2-digit',[`timeZone`]: 'Europe/London' })).format(start));
  }
  startTime = (new Intl.DateTimeFormat('en-GB', ({ [`hour`]: '2-digit',[`minute`]: '2-digit',[`timeZone`]: 'Europe/London' })).format(start));
  end = (new Date(endTimestamp));
  endTime = (new Intl.DateTimeFormat('en-GB', ({ [`hour`]: '2-digit',[`minute`]: '2-digit',[`timeZone`]: 'Europe/London' })).format(end));

return ([startDate,'     ',startTime,'-',endTime].join(''))
}

})
define('./functions/cffa0ba7bef80f0a47bb556ff72ecae4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_cffa0ba7bef80f0a47bb556ff72ecae4'] = async function fn_cffa0ba7bef80f0a47bb556ff72ecae4(endTimestampMonth, noOfMonths, AD, PD) {
var BUNDLES_BY_MONTH, overallSums, facilityUsed, agencyPurchasePrice, locumPurchasePrice, totalPaid, totalFactored, monthlyFee, month, bundle, tx, monthlyTimestamps, firstMonth, trendData, j, RESPONSE;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  RESPONSE = (await Backendless.Request.get(`${Backendless.appPath}/services/ADMIN/getRevenue_v1`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'endTimestamp': endTimestampMonth,'noOfMonths': noOfMonths }).send());
  BUNDLES_BY_MONTH = (getObjectProperty(RESPONSE, 'revenueByMonth'));
  PD['overall'] = ({ [`totalFactored`]: (await BackendlessUI.Functions.Custom['fn_1624b8f8f3c1ab74f0f27ea1fc049fb5'](BUNDLES_BY_MONTH, null, 'bundlesTotal')),[`unpaidBundles`]: (await BackendlessUI.Functions.Custom['fn_1624b8f8f3c1ab74f0f27ea1fc049fb5'](BUNDLES_BY_MONTH, null, 'bundlesUnpaid')),[`securedProfit`]: (await BackendlessUI.Functions.Custom['fn_1624b8f8f3c1ab74f0f27ea1fc049fb5'](BUNDLES_BY_MONTH, null, 'AFees_Earned')) + (await BackendlessUI.Functions.Custom['fn_1624b8f8f3c1ab74f0f27ea1fc049fb5'](BUNDLES_BY_MONTH, null, 'LFees_Earned')),[`currentFacilitySize`]: (await BackendlessUI.Functions.Custom['fn_1624b8f8f3c1ab74f0f27ea1fc049fb5'](BUNDLES_BY_MONTH, null, 'usedFacilitySize')),[`projectedFacilitySize`]: (await BackendlessUI.Functions.Custom['fn_1624b8f8f3c1ab74f0f27ea1fc049fb5'](BUNDLES_BY_MONTH, null, ['usedFacilitySize', 'LInvPP_Due', 'AInvPP_Due'])),[`avgDailyProfit`]: (getObjectProperty(RESPONSE, 'avgDailyProfit')) });
  BUNDLES_BY_MONTH = (await (async function(inputData) {
  	function formatNumbers(data) {
  	  // Check if a value is likely a timestamp (13-digit number)
  	  function isTimestamp(value) {
  	    return typeof value === 'number' && value.toString().length === 13;
  	  }

  	  // Format a value if it's a number (but not a timestamp)
  	  function formatValue(value) {
  	    if (typeof value === 'number' && !isTimestamp(value)) {
  	      // Format number to have 0 decimal places
  	      return value.toLocaleString('en-US', {
  	        minimumFractionDigits: 0,
  	        maximumFractionDigits: 0
  	      });
  	    }
  	    // Return non-number values as-is
  	    return value;
  	  }

  	  // Recursively process an object or array
  	  function processObject(obj) {
  	    for (let key in obj) {
  	      if (typeof obj[key] === 'object' && obj[key] !== null) {
  	        // Recursively process nested objects
  	        obj[key] = processObject(obj[key]);
  	      } else if (!isTimestamp(obj[key])) {
  	        // Format the value if it's not a timestamp
  	        obj[key] = formatValue(obj[key]);
  	      }
  	      // Timestamps are left as-is
  	    }
  	    return obj;
  	  }

  	  // Process each item in the input data
  	  // Create a deep copy of each item to avoid modifying the original data
  	  return data.map(item => processObject(JSON.parse(JSON.stringify(item))));
  	}

  	// Usage
  	return formatNumbers(inputData);
  })(BUNDLES_BY_MONTH));
  for (var j_index in BUNDLES_BY_MONTH) {
    j = BUNDLES_BY_MONTH[j_index];
    j['bundlesCnt'] = ([(getObjectProperty(j, 'bundlesPaidCnt')),'/',(getObjectProperty(j, 'bundlesUnpaidCnt'))].join(''));
  }
  PD['revenue'] = BUNDLES_BY_MONTH;
  trendData = (await Backendless.Data.of('trendData').find(Backendless.DataQueryBuilder.create().setSortBy('dateTime').setPageSize(100)));
  PD['trendData'] = trendData;

return BUNDLES_BY_MONTH
}

})
define('./functions/d66839f8b842abc1e0232eb54a75c3fc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d66839f8b842abc1e0232eb54a75c3fc'] = async function fn_d66839f8b842abc1e0232eb54a75c3fc(filterOptions) {
var WHERE_CLAUSE, FILTER_OPTIONS, criteria, trimmedCriteria, j, toDate, fromDate, item, bundleStatuses;

function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function timesheetType() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'timesheetType'));
  if (criteria && criteria != 'ANY') {
    WHERE_CLAUSE = [WHERE_CLAUSE,' AND type = \'',criteria,'\''].join('');
  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function clients() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'clients'));
  trimmedCriteria = [];
  if (criteria) {
    criteria = criteria.split(',');
    for (var j_index in criteria) {
      j = criteria[j_index];
      j = j.trim();
      addItemToList(trimmedCriteria, j);
    }
    WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND (',(await (async function(names) {
    	return names.map(name => `client.companyName LIKE '${name}%'`).join(' OR ');
    })(trimmedCriteria)),')'].join(''));
  }
}

/**
 * Describe this function...
 */
async function sessionDates() {
  if ((await BackendlessUI.Functions.Custom['fn_6a17ced8fc43780300e93d26cf56a579'](FILTER_OPTIONS, 'useSessionDates'))) {
    fromDate = (getObjectProperty(FILTER_OPTIONS, 'sessionDates.fromDate'));
    toDate = (getObjectProperty(FILTER_OPTIONS, 'sessionDates.toDate'));
    if (fromDate && toDate) {
      WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND sessions.start >= \'',fromDate,'\' AND sessions.start < \'',toDate,'\''].join(''));
    }
  }
}

/**
 * Describe this function...
 */
async function timesheetStatus() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'timesheetStatus'));
  if (criteria && !!criteria.length) {
    WHERE_CLAUSE = [WHERE_CLAUSE,' AND status in (',(await Promise.all(criteria.map(async item => {; return (['\'',item,'\''].join(''));}))).join(','),')'].join('');
  }
}

/**
 * Describe this function...
 */
async function bundleStatus() {
  criteria = (await BackendlessUI.Functions.Custom['fn_6a17ced8fc43780300e93d26cf56a579'](FILTER_OPTIONS, 'bundleStatus'));
  if (criteria && !!criteria.length) {
    WHERE_CLAUSE = [WHERE_CLAUSE,' AND locumInvoices[timesheets].clientBundles[locumInvoices].status in (',(await Promise.all(criteria.map(async item => {; return (['\'',item,'\''].join(''));}))).join(','),')'].join('');
  }
}

/**
 * Describe this function...
 */
async function agencies() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'agencies'));
  trimmedCriteria = [];
  if (criteria) {
    criteria = criteria.split(',');
    for (var j_index2 in criteria) {
      j = criteria[j_index2];
      j = j.trim();
      addItemToList(trimmedCriteria, j);
    }
    WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND (',(await (async function(names) {
    	return names.map(name => `agency.companyName LIKE '${name}%'`).join(' OR ');
    })(trimmedCriteria)),')'].join(''));
  }
}

/**
 * Describe this function...
 */
async function timesheetSubmitId() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'timesheetSubmitId'));
  if (criteria) {
    criteria = criteria.trim();
    WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND submitId LIKE \'%',criteria,'%\''].join(''));
  }
}

/**
 * Describe this function...
 */
async function agencyInvoiceStatus() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'agencyInvStatus'));
  if (criteria && !!criteria.length) {
    WHERE_CLAUSE = [WHERE_CLAUSE,' AND agencyInvoices[timesheets].status in (',(await Promise.all(criteria.map(async item => {; return (['\'',item,'\''].join(''));}))).join(','),')'].join('');
  }
}

/**
 * Describe this function...
 */
async function locums() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'locums'));
  trimmedCriteria = [];
  if (criteria) {
    criteria = criteria.split(',');
    for (var j_index3 in criteria) {
      j = criteria[j_index3];
      j = j.trim();
      addItemToList(trimmedCriteria, j);
    }
    WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND (',(await (async function(names) {
    	return names.map(name => `locum.name LIKE '${name}%'`).join(' OR ');
    })(trimmedCriteria)),')'].join(''));
  }
}

/**
 * Describe this function...
 */
async function timesheetSubmittedDate() {
  if ((await BackendlessUI.Functions.Custom['fn_6a17ced8fc43780300e93d26cf56a579'](FILTER_OPTIONS, 'useTimesheetSubmittedDate'))) {
    fromDate = (getObjectProperty(FILTER_OPTIONS, 'timesheetSubmittedDate.fromDate'));
    toDate = (getObjectProperty(FILTER_OPTIONS, 'timesheetSubmittedDate.toDate'));
    if (fromDate && toDate) {
      WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND created >= \'',fromDate,'\' AND created < \'',toDate,'\''].join(''));
    }
  }
}

/**
 * Describe this function...
 */
async function timesheetUnapprovedOnly() {
  criteria = (getObjectProperty(FILTER_OPTIONS, 'timesheetUnapprovedOnly'));
  if (criteria) {
    WHERE_CLAUSE = String(WHERE_CLAUSE) + String(' AND approved != TRUE');
  }
}

/**
 * Describe this function...
 */
async function bundleIssueDate() {
  if ((await BackendlessUI.Functions.Custom['fn_6a17ced8fc43780300e93d26cf56a579'](FILTER_OPTIONS, 'useBundleIssueDate'))) {
    fromDate = (getObjectProperty(FILTER_OPTIONS, 'bundleIssueDate.fromDate'));
    toDate = (getObjectProperty(FILTER_OPTIONS, 'bundleIssueDate.toDate'));
    if (fromDate && toDate) {
      WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND locumInvoices[timesheets].clientBundles[locumInvoices].issueDate >= \'',fromDate,'\' AND locumInvoices[timesheets].clientBundles[locumInvoices].issueDate < \'',toDate,'\''].join(''));
    }
  }
}

/**
 * Describe this function...
 */
async function bundlePaidDate() {
  if ((await BackendlessUI.Functions.Custom['fn_6a17ced8fc43780300e93d26cf56a579'](FILTER_OPTIONS, 'useBundlePaidDate'))) {
    fromDate = (getObjectProperty(FILTER_OPTIONS, 'bundlePaidDate.fromDate'));
    toDate = (getObjectProperty(FILTER_OPTIONS, 'bundlePaidDate.toDate'));
    if (fromDate && toDate) {
      WHERE_CLAUSE = String(WHERE_CLAUSE) + String([' AND locumInvoices[timesheets].clientBundles[locumInvoices].paidDate >= \'',fromDate,'\' AND locumInvoices[timesheets].clientBundles[locumInvoices].paidDate < \'',toDate,'\''].join(''));
    }
  }
}


  FILTER_OPTIONS = filterOptions;
  WHERE_CLAUSE = '';
  await clients();
  await agencies();
  await locums();
  await bundleStatus();
  await agencyInvoiceStatus();
  await bundleIssueDate();
  await bundlePaidDate();
  await timesheetStatus();
  await timesheetUnapprovedOnly();
  await timesheetType();
  await timesheetSubmitId();
  await timesheetSubmittedDate();
  await sessionDates();
  WHERE_CLAUSE = WHERE_CLAUSE.slice(4, getValueLength(WHERE_CLAUSE));
  console.log('WHERE_CLAUSE:');
  console.log(WHERE_CLAUSE);

return WHERE_CLAUSE
}

})
define('./functions/da8e44740b877e0c27168a0b025d7e34/code.js', () => { 

BackendlessUI.Functions.Custom['fn_da8e44740b877e0c27168a0b025d7e34'] = async function fn_da8e44740b877e0c27168a0b025d7e34(num) {
return (await (async function(num) {
	    // Check if the number has more than two decimal places
	    if (num.toString().split(".")[1]?.length > 2) {
	        return Number(num.toFixed(2));
	    }
	    return num;
})(num))
}

})
define('./functions/dc1b276aaa6103ad46639636515e1fd5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'] = function fn_dc1b276aaa6103ad46639636515e1fd5(data, single) {
var class2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof data === 'string') {
    class2 = data;
  } else {
    class2 = (getObjectProperty(data, '___class'));
  }
  if (single == true) {
    if (class2 == 'locums') {
      return 'locum';
    } else if (class2 == 'agencies') {
      return 'agency';
    } else {
      return 'client';
    }
  } else {
    return class2;
  }

return 
}

})
define('./functions/df0fb7e70159da6cb31112e4d8da310a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a'] = async function fn_df0fb7e70159da6cb31112e4d8da310a(dateString, timeString) {
var dateTime;


  dateTime = (await (async function(dateString, timeString) {
  	    const { DateTime } = luxon;

  	    // Initialize variables for date parts and format
  	    let formatString = 'dd/MM/yyyy HH:mm';

  	    // Check and adjust the date format based on input patterns
  	    if (dateString.includes('/')) {  // DD/MM/YYYY or DD/MM/YY
  	        const dateParts = dateString.split('/');
  	        if (dateParts[2].length === 2) {
  	            dateParts[2] = '20' + dateParts[2];  // Assuming the year is in the 2000s
  	            dateString = dateParts.join('/');
  	        }
  	        formatString = `${dateParts[2].length === 4 ? 'dd/MM/yyyy' : 'dd/MM/yy'} HH:mm`;
  	    } else if (dateString.includes(' ')) {  // "Tue Apr 16 2024" format
  	        // Split the string and reassemble without the day name
  	        const parts = dateString.split(' ');
  	        dateString = parts.slice(1).join(' ');  // Remove the weekday part
  	        formatString = 'MMM dd yyyy HH:mm';  // Update format to match "Apr 16 2024 HH:mm"
  	    }

  	    // Combine the adjusted date string with the time string
  	    const combinedDateTimeString = `${dateString} ${timeString}`;

  	    // Parse the combined string into a DateTime object with the specified format
  	    // and assume it's in the 'Europe/London' timezone
  	    const dateTime = DateTime.fromFormat(combinedDateTimeString, formatString, {
  	        zone: 'Europe/London'
  	    });

  	    // Check if the date is valid, handle invalid dates
  	    if (dateTime.isValid) {
  	        // Return the DateTime object as a JavaScript Date object
  	        return dateTime.toJSDate();
  	    } else {
  	        throw new Error('Invalid date format or values provided.');
  	    }
  })(dateString, timeString));
  dateTime = (dateTime.valueOf());

return dateTime
}

})
define('./functions/f20e60b6c1db0fccab2e247dbe5bcfa4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f20e60b6c1db0fccab2e247dbe5bcfa4'] = async function fn_f20e60b6c1db0fccab2e247dbe5bcfa4(statuses) {
var item, list, statusList, unTimesheets, whereClause;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  statusList = [];
  if (getObjectProperty(statuses, 'REJECTED')) {
    addItemToList(statusList, 'REJECTED');
  }
  if (getObjectProperty(statuses, 'DRAFT')) {
    addItemToList(statusList, 'DRAFT');
  }
  if (getObjectProperty(statuses, 'VOID')) {
    addItemToList(statusList, 'VOID');
  }
  if (getObjectProperty(statuses, 'CLIENT_APPROVED')) {
    addItemToList(statusList, 'CLIENT APPROVED');
  }
  whereClause = ['status in (',(await Promise.all(statusList.map(async item => {; return (['\'',item,'\''].join(''));}))).join(','),') AND tinnyStatus <> \'PROCESSED\''].join('');
  unTimesheets = (await Backendless.Data.of('timesheets').find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setRelationsDepth(1).setSortBy('timesheetFilename').setPageSize(100)));

return unTimesheets
}

})
define('./functions/f24a820d3b195c3e29bdfe4df435e613/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f24a820d3b195c3e29bdfe4df435e613'] = async function fn_f24a820d3b195c3e29bdfe4df435e613(timestamp) {
return (await (async function(timestamp) {
	    // Create a new Date object using the timestamp
	    const date = new Date(timestamp);

	    // Define options for toLocaleString()
	    const options = {
	        weekday: 'short',
	        year: 'numeric',
	        month: 'short',
	        day: 'numeric',
	        hour: '2-digit',
	        minute: '2-digit',
	        timeZone: 'Europe/London'
	    };

	    // Convert to desired format with en-GB locale
	    return date.toLocaleString('en-GB', options);
})(timestamp))
}

})
define('./functions/f4453866f827b3e206511b54bcec8ab3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f4453866f827b3e206511b54bcec8ab3'] = function fn_f4453866f827b3e206511b54bcec8ab3(bundle) {
var sessions, locums, session, locum, timesheet, timesheets, locumInvoice;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  sessions = [];
  if (getObjectProperty(bundle, 'agencyInvoice')) {
    timesheets = (getObjectProperty(bundle, 'agencyInvoice.timesheets'));
    for (var timesheet_index in timesheets) {
      timesheet = timesheets[timesheet_index];
      locum = (getObjectProperty(timesheet, 'locum'));
      var session_list = (getObjectProperty(timesheet, 'sessions'));
      for (var session_index in session_list) {
        session = session_list[session_index];
        Object.assign(session, locum);
        addItemToList(sessions, session);
      }
    }
  } else {
    var locumInvoice_list = (getObjectProperty(bundle, 'locumInvoices'));
    for (var locumInvoice_index in locumInvoice_list) {
      locumInvoice = locumInvoice_list[locumInvoice_index];
      locum = (getObjectProperty(locumInvoice, 'locum'));
      timesheets = (getObjectProperty(locumInvoice, 'timesheets'));
      for (var timesheet_index2 in timesheets) {
        timesheet = timesheets[timesheet_index2];
        var session_list2 = (getObjectProperty(timesheet, 'sessions'));
        for (var session_index2 in session_list2) {
          session = session_list2[session_index2];
          Object.assign(session, locum);
          session['cost'] = (getObjectProperty(session, 'costLocum'));
          session['rate'] = (getObjectProperty(session, 'rateLocum'));
          session['extra'] = (getObjectProperty(session, 'extraLocum'));
          addItemToList(sessions, session);
        }
      }
    }
  }

return sessions
}

})
define('./functions/f71618cc35f44343d8589f0e125fa34e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f71618cc35f44343d8589f0e125fa34e'] = async function fn_f71618cc35f44343d8589f0e125fa34e(date, time) {
return (await (async function(time, date) {

	    // Convert the time timestamp to a Date object and extract local hours and minutes
	    const timeObj = new Date(time);
	    const extractedTime = {
	        hours: timeObj.getHours(),
	        minutes: timeObj.getMinutes()
	    };

	    // Convert the date timestamp to a Date object and extract the local date
	    const dateObj = new Date(date);
	    const extractedDate = {
	        day: dateObj.getDate(),
	        month: dateObj.getMonth() + 1,  // getMonth() returns 0-based month
	        year: dateObj.getFullYear()
	    };

	    // Construct a date-time string using the extracted values
	    const dateTimeString = `${extractedDate.year}-${String(extractedDate.month).padStart(2, '0')}-${String(extractedDate.day).padStart(2, '0')}T${String(extractedTime.hours).padStart(2, '0')}:${String(extractedTime.minutes).padStart(2, '0')}:00`;

	    // Parse the date-time string into a Date object
	    const combinedDateObj = new Date(dateTimeString);

	    // Adjust for the Europe/London timezone
	    const utcOffset = combinedDateObj.getTimezoneOffset();
	    const londonOffset = new Intl.DateTimeFormat('en-GB', {
	        timeZone: 'Europe/London',
	        timeZoneName: 'short'
	    }).format(combinedDateObj).split(' ')[2] === 'GMT' ? 0 : -60;
	    const offsetDifference = utcOffset - londonOffset;

	    // Adjust the combined timestamp by the offset difference to get London time
	    const londonTimestamp = combinedDateObj.getTime() - offsetDifference * 60 * 1000;

	    return londonTimestamp;

})(time, date))
}

})
define('./functions/f72a166723522cd1028ba41fe8aef554/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f72a166723522cd1028ba41fe8aef554'] = async function fn_f72a166723522cd1028ba41fe8aef554(start, end, breaks) {
var duration, breaksInHour;


  if (breaks) {
    breaksInHour = breaks;
  } else {
    breaksInHour = 0;
  }
  duration = (await (async function(start, end) {
  	const MILLISECONDS_IN_HOUR = 3600000;  // 60 * 60 * 1000
  	const MILLISECONDS_IN_DAY = 86400000;  // 24 * 60 * 60 * 1000
  	const MILLISECONDS_IN_MINUTE = 60000;  // 60 * 1000

  	// Create a new Date object for today's date set to midnight (start of the day)
  	const today = new Date();
  	today.setHours(0, 0, 0, 0);

  	// Extract hours and minutes from the start timestamp and set them to the today's date
  	const startHours = new Date(start).getHours();
  	const startMinutes = new Date(start).getMinutes();
  	today.setHours(startHours, startMinutes);
  	start = today.getTime();

  	// Reset today's date to midnight again
  	today.setHours(0, 0, 0, 0);

  	// Extract hours and minutes from the end timestamp and set them to the today's date
  	const endHours = new Date(end).getHours();
  	const endMinutes = new Date(end).getMinutes();
  	today.setHours(endHours, endMinutes);
  	end = today.getTime();

  	// Strip out the seconds and milliseconds from start and end
  	start = Math.floor(start / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;
  	end = Math.floor(end / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;

  	let duration;

  	// Check if start and end are exactly the same
  	if (start === end) {
  	    duration = 24 * 60; // Duration is 1440 minutes (24 hours)
  	} else if (end > start) {
  	    // Normal case where end is after start
  	    duration = (end - start) / MILLISECONDS_IN_MINUTE;
  	} else {
  	    // Case where end is on the next day
  	    duration = (end + MILLISECONDS_IN_DAY - start) / MILLISECONDS_IN_MINUTE;
  	}

  	return duration;

  	/** BELOW FUNCTION WORKS, BUT UNIT OF DURATION IS HOURS.**/

  	// const MILLISECONDS_IN_HOUR = 3600000;  // 60 * 60 * 1000
  	// const MILLISECONDS_IN_DAY = 86400000;  // 24 * 60 * 60 * 1000
  	// const MILLISECONDS_IN_MINUTE = 60000;  // 60 * 1000

  	// // Create a new Date object for today's date set to midnight (start of the day)
  	// const today = new Date();
  	// today.setHours(0, 0, 0, 0);

  	// // Extract hours and minutes from the start timestamp and set them to the today's date
  	// const startHours = new Date(start).getHours();
  	// const startMinutes = new Date(start).getMinutes();
  	// today.setHours(startHours, startMinutes);
  	// start = today.getTime();

  	// // Reset today's date to midnight again
  	// today.setHours(0, 0, 0, 0);

  	// // Extract hours and minutes from the end timestamp and set them to the today's date
  	// const endHours = new Date(end).getHours();
  	// const endMinutes = new Date(end).getMinutes();
  	// today.setHours(endHours, endMinutes);
  	// end = today.getTime();

  	// // Strip out the seconds and milliseconds from start and end
  	// start = Math.floor(start / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;
  	// end = Math.floor(end / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;

  	// let duration;

  	// // Check if start and end are exactly the same
  	// if (start === end) {
  	//     duration = 24; // Duration is 24 hours
  	// } else if (end > start) {
  	//     // Normal case where end is after start
  	//     duration = (end - start) / MILLISECONDS_IN_HOUR;
  	// } else {
  	//     // Case where end is on the next day
  	//     duration = (end + MILLISECONDS_IN_DAY - start) / MILLISECONDS_IN_HOUR;
  	// }

  	// return duration;


  	/** BELOW FUNCTION WORKS, BUT WHEN START AND END TIME IS EXACTLY THE SAME, IT ASSUMES THE DURATION IS ZERO.**/

  	// const MILLISECONDS_IN_HOUR = 3600000;  // 60 * 60 * 1000
  	// const MILLISECONDS_IN_DAY = 86400000;  // 24 * 60 * 60 * 1000
  	// const MILLISECONDS_IN_MINUTE = 60000;  // 60 * 1000

  	// // Create a new Date object for today's date set to midnight (start of the day)
  	// const today = new Date();
  	// today.setHours(0, 0, 0, 0);

  	// // Extract hours and minutes from the start timestamp and set them to the today's date
  	// const startHours = new Date(start).getHours();
  	// const startMinutes = new Date(start).getMinutes();
  	// today.setHours(startHours, startMinutes);
  	// start = today.getTime();

  	// // Reset today's date to midnight again
  	// today.setHours(0, 0, 0, 0);

  	// // Extract hours and minutes from the end timestamp and set them to the today's date
  	// const endHours = new Date(end).getHours();
  	// const endMinutes = new Date(end).getMinutes();
  	// today.setHours(endHours, endMinutes);
  	// end = today.getTime();

  	// // Strip out the seconds and milliseconds from start and end
  	// start = Math.floor(start / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;
  	// end = Math.floor(end / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;

  	// let duration;

  	// // Compare start and end
  	// if (end >= start) {
  	//     duration = (end - start) / MILLISECONDS_IN_HOUR;
  	// } else {
  	//     duration = (end + MILLISECONDS_IN_DAY - start) / MILLISECONDS_IN_HOUR;
  	// }

  	// return duration;



  })(start, end)) - breaksInHour;
  if (!(duration % 1 == 0)) {
    duration = Math.round(duration * 100) / 100;
  }

return duration
}

})
define('./functions/f8b896a36d2637c4ee8b1426f3c5ae0e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f8b896a36d2637c4ee8b1426f3c5ae0e'] = function fn_f8b896a36d2637c4ee8b1426f3c5ae0e(email) {
return ((new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$', 'g')).test(email))
}

})
define('./functions/fdb8d50ca42772581ec7210b66b81681/code.js', () => { 

BackendlessUI.Functions.Custom['fn_fdb8d50ca42772581ec7210b66b81681'] = async function fn_fdb8d50ca42772581ec7210b66b81681() {
var data, clients, invoiceCountText, maxOverdueDate, futureDueAmount, nearlyDueAmount, overdueAmount, totalDueAmount, client, totalDueInvoiceCount, overdueInvoiceCount, amount, _14days, dueDate, today, bundle;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function overdueAmount2(clients) {
  today = (await (async function() {
  	  const now = new Date();
  	  const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
  	   // Returns timestamp in milliseconds
  	  return endOfDay.getTime();
  })());
  console.log(today);
  _14days = (new Date((new Date()).getTime() + ((14 * 86400) * 1000)));
  for (var client_index in clients) {
    client = clients[client_index];
    totalDueAmount = null;
    overdueAmount = null;
    overdueInvoiceCount = null;
    nearlyDueAmount = null;
    futureDueAmount = null;
    totalDueAmount = null;
    maxOverdueDate = null;
    totalDueInvoiceCount = (getObjectProperty(client, 'bundles')).length;
    var bundle_list = (getObjectProperty(client, 'bundles'));
    for (var bundle_index in bundle_list) {
      bundle = bundle_list[bundle_index];
      dueDate = (getObjectProperty(bundle, 'dueDate'));
      amount = (getObjectProperty(bundle, 'totalIncVat'));
      totalDueAmount = amount + totalDueAmount;
      if (dueDate < today) {
        overdueAmount = amount + overdueAmount;
        overdueInvoiceCount = (typeof overdueInvoiceCount == 'number' ? overdueInvoiceCount : 0) + 1;
        if (!maxOverdueDate || dueDate < maxOverdueDate) {
          maxOverdueDate = dueDate;
        }
      } else if (today <= dueDate && dueDate <= _14days) {
        nearlyDueAmount = amount + nearlyDueAmount;
      } else {
        futureDueAmount = amount + futureDueAmount;
      }
    }
    if (overdueInvoiceCount) {
      invoiceCountText = [overdueInvoiceCount,'/',totalDueInvoiceCount,' overdue'].join('');
    } else {
      invoiceCountText = String(totalDueInvoiceCount) + String(' due');
    }
    Object.assign(client, ({ [`totalDueAmount`]: totalDueAmount,[`overdueAmount`]: overdueAmount,[`nearlyDueAmount`]: nearlyDueAmount,[`futureDueAmount`]: futureDueAmount,[`maxOverdueDays`]: (await (async function(timestamp) {
    	  // Check if timestamp is null or undefined
    	  if (timestamp === null || timestamp === undefined) {
    	    return null;
    	  }

    	  // Create a Date object for today's date
    	  const today = new Date();
    	  // Reset the time part to ensure the calculation is based only on dates
    	  const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    	  // Convert the past timestamp into a Date object
    	  const pastDate = new Date(timestamp);
    	  // Calculate the difference in milliseconds
    	  const diffInMs = startOfDay - pastDate;
    	  // Convert milliseconds to days
    	  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    	  return diffInDays+1;
    })(maxOverdueDate)),[`InvoiceCount`]: invoiceCountText }));
  }
  return clients
}


  data = (await Backendless.Data.of('clientBundles').find(Backendless.DataQueryBuilder.create().setWhereClause('status = \'AUTHORISED\'').setRelated(['client']).setRelationsDepth(2).setSortBy('dueDate').setPageSize(100)));
  console.log(data);
  data = (await BackendlessUI.Functions.Custom['fn_af8487abc61991810b27ab23ad6b00b6'](data, 'client', 'bundles'));
  data = await overdueAmount2(data);
  console.log('PROCESSED');
  console.log(data);

return data
}

})
define('./functions/fdb94a6c4612bd388ffc675dd1fc0c5d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_fdb94a6c4612bd388ffc675dd1fc0c5d'] = async function fn_fdb94a6c4612bd388ffc675dd1fc0c5d(timesheets) {
var timesheet, sessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something() {
  sessions = [];
  for (var timesheet_index in timesheets) {
    timesheet = timesheets[timesheet_index];
    sessions = (sessions.concat((getObjectProperty(timesheet, 'sessions'))));
  }
}



return (await (async function(timesheets) {
	  if (!Array.isArray(timesheets)) {
	    // console.error('Input is not an array');
	    return [];
	  }

	  // Extract sessions from each timesheet
	  let sessions = timesheets.reduce((acc, timesheet) => {
	    if (timesheet.sessions && Array.isArray(timesheet.sessions)) {
	      return acc.concat(timesheet.sessions);
	    }
	    return acc;
	  }, []);

	  // Sort the sessions by start
	  sessions.sort((a, b) => a.start - b.start);

	  return sessions;
})(timesheets))
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/admin-Bundles/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var proformaBundles, bundleObject, bundleRef, bundle, agencyInvoices, latestBundleNo, today, agencyTotalIncVat, agencyTimesheets, bundles, defaultFilters, locumInvoices, invoice, locum, locumTotalInvVat, timesheets, counter, cost, session, sessions, vatRate, totalIncVat, vatNo;

/**
 * Describe this function...
 */
async function setDefaultFilters() {
  defaultFilters = ({ [`bundleStatus`]: ['PROCESSING', 'SUBMITTED', 'AUTHORISED'] });
  await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'ui.defaultFilters', defaultFilters);
  await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'filters', (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](defaultFilters)));
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('\\/ ========= [Bundle] ========= \\/');
  await setDefaultFilters();
  bundles = (await BackendlessUI.Functions.Custom['fn_67837848890441d1a81faaf4aee44b29']((getObjectProperty(___arguments.context.pageData, 'filters'))));
  ___arguments.context.pageData['bundleGroup'] = bundles;
  console.log('Page Data:');
  console.log(___arguments.context.pageData);
  console.log('/\\ ========= [Bundle] ========= /\\');

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin-Bundles/components/c3c6af16b991f541fa3d42377d8877ff/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'key')),' (',(getObjectProperty(___arguments.context.dataModel, 'items')).length,')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/977a5d1858a6fa847acc7f691dd75243/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7866951acae68c6ee00c371b59e1b9f5'), 'client.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/26fe4709ff29dcaa3aead22f0eadc278/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var cost, session, timesheet, locum;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7866951acae68c6ee00c371b59e1b9f5'), 'totalIncVat'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/7866951acae68c6ee00c371b59e1b9f5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedBundle'] = ___arguments.context.dataModel;
  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'locum') {
    ___arguments.context.pageData['selectedOrg'] = null;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'agency' && !(getObjectProperty(___arguments.context.pageData, 'selectedBundle.agencyInvoice'))) {
    ___arguments.context.pageData['selectedOrg'] = null;
  }
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedBundle.objectId')) == (getObjectProperty(___arguments.context.dataModel, 'objectId'))) {
    return ['hps-list-item-selected'];
  } else if ((getObjectProperty(___arguments.context.dataModel, 'processStatus')) == 'success') {
    return ['hps-list-item-processed'];
  } else if ((getObjectProperty(___arguments.context.dataModel, 'processStatus')) == 'failed') {
    return ['hps-list-item-failed'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/2f3645d1d31db51dc6591382ca4870ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedBundle'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/86dca91febd9732604485b0cc7f51cd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice.agency.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice.agency.companyName')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice.agency.companyName'));
  } else {
    return '-- None --';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/d388b232cac5ad08b3adda8ab2e5dc2a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'client.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Bundles/components/d99919def9d34a9c80620bba5f365838/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('35d34a15bea8107e4d14a6443fea95e4'), 'locum.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/d72a77ee97fe484ed42a92943fc7a66a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('510775ff32b2b76efb5693cdf428eaea'), 'approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/f04d91dc243ecf7e09a5a7a79a3a00f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = ({ [`type`]: 'client',[`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'client.objectId')) });
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'client') {
    return ['hps-list-item-selected'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/79e7f665eebddafee96a015f008fdfc3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'client')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/29f75ba8f666036820feece92f654eef/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice'))) {
    return [];
  }
  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'agency') {
    return ['hps-list-item-selected'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = ({ [`type`]: 'agency',[`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agency.objectId')) });
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Bundles/components/35d34a15bea8107e4d14a6443fea95e4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = ({ [`type`]: 'locum',[`objectId`]: (getObjectProperty(___arguments.context.dataModel, 'invoiceRef')) });
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.objectId')) == (getObjectProperty(___arguments.context.dataModel, 'invoiceRef'))) {
    return ['hps-list-item-selected'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/6d6479c8ffda8b8b18da34cafe972b36/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'agency')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/a6689b74c2af0206a0582022bc3d04d3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'locum')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/9f0c1a39cb7b5b11876cca74d888219a/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status'));
  } else {
    return 'DRAFT';
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/29f13e5abb0325c858c2029d9bdbc1a5/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_f4453866f827b3e206511b54bcec8ab3'](___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b')))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/265812dfff3bbc5c07fbafdf0f5abc24/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var sessionCount;


  sessionCount = ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5').length;
  if (sessionCount <= 1) {
    return (['Session (',sessionCount,')'].join(''));
  } else {
    return (['Sessions (',sessionCount,')'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Bundles/components/82f2a0097d7653e6967bc27adc98d005/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5')

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/cda1386232e40c28bf85936a749ad244/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var breaksText, duration, timeText, dateText, breaks, separator, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  text = (await BackendlessUI.Functions.Custom['fn_3fc304d26564606fdef10399a96a1a45']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'end'))));
  separator = text.lastIndexOf(', ') + 1;
  dateText = text.slice(0, separator - 1);
  timeText = text.slice(((separator + 2) - 1), text.length);

  return ([dateText,'<br>',timeText].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/94edbd0550165fc28ed498260be7b961/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extraText, extra, breaks, breaksText, duration, separator, text, timeText, dateText;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'breaks'));
  breaksText = null;
  if (breaks) {
    breaksText = [' + ',breaks,'m breaks'].join('');
  }
  return (['(',(await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc'](((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'duration')) / 60))),'hr x £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'rate')))),'/hr)',breaksText].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/1e55234a60542365b042a65470f8b4ef/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'cost'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/872d37481c85ae8ef55cfb7ece31b0b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalCost, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  totalCost = null;
  var j_list = ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalCost = totalCost + (getObjectProperty(j, 'cost'));
  }
  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](totalCost))));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/482a0135a28eb78671a1035560b50a58/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalDuration, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  totalDuration = null;
  var j_list = ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalDuration = totalDuration + (getObjectProperty(j, 'duration'));
  }
  return (String((await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc']((totalDuration / 60)))) + String(' hrs total'));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/adba68becc34256c34459f85d056770c/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'status')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'status')) == 'FOR BUNDLING') {
    return 'NOT ISSUED';
  } else {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'invoiceRef'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/296414d37792394e9fc53bcb6455297e/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_f4453866f827b3e206511b54bcec8ab3']((getObjectProperty(___arguments.context.pageData, 'selectedBundle'))))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/3faa99a561282f4c85711d6be85d86f6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var breaksText, breaks, extraText, extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'breaks'));
  breaksText = null;
  if (breaks) {
    breaksText = [' + ',breaks,'m breaks'].join('');
  }
  return (['(',(await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc'](((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'duration')) / 60))),'hr x £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'rateAgency')))),'/hr)',breaksText].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/eaedf938e87e4747cc4869428f51a197/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var timeText, dateText, separator, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  text = (await BackendlessUI.Functions.Custom['fn_3fc304d26564606fdef10399a96a1a45']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'end'))));
  separator = text.lastIndexOf(', ') + 1;
  dateText = text.slice(0, separator - 1);
  timeText = text.slice(((separator + 2) - 1), text.length);

  return ([dateText,'<br>',timeText].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/4e3462bb23f803ee0096f80d642c4347/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e')

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/e2832243fdfbc2df0b93a5913114bdee/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'notesAgency'));
  if (notes) {
    return (['Agency note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/33ef96345a25da170d5f093318eb88ee/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'notesAgency'));
  if (notes) {
    return (['Note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/3a7ef4dd27fdeae12ad23d5e94d66153/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalCost, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'totalIncVat'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/f2636c4a9655430eb4e6d13d11a87861/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalDuration, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e')) {
    return ;
  }
  totalDuration = 0;
  var j_list = ___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalDuration = totalDuration + (getObjectProperty(j, 'duration'));
  }
  return (String((await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc']((totalDuration / 60)))) + String(' hrs total'));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/3b6c45d503850b639ad64279e3050919/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'costAgency'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/e94bf9ddc95e9d2241de036252772fa4/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b58d8df10e261e42867bdfc29fe57c28']((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'selectedBundle')), 'locumInvoices')), 'invoiceRef', (getObjectProperty(___arguments.context.pageData, 'selectedOrg.objectId'))))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/35baebb984bf7c2af89f517ee4e462b5/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'locum.prnType')),'-',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'locum.prn'))].join(''))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/aa8ffef7004969a9900472058d5ea5ba/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'status')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'status')) == 'FOR BUNDLING') {
    return 'NOT ISSUED';
  } else {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'invoiceRef'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/8a63d58a0f017f51d96d50a54dcc7468/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var sessionCount;


  sessionCount = ___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e').length;
  if (sessionCount <= 1) {
    return (['Session (',sessionCount,')'].join(''));
  } else {
    return (['Sessions (',sessionCount,')'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/45b44049df1be25dc6a37a66444e9e1c/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_fdb94a6c4612bd388ffc675dd1fc0c5d']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'timesheets'))))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/b05113c728e90069569e401f877120bf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var separator, text, timeText, dateText;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  text = (await BackendlessUI.Functions.Custom['fn_3fc304d26564606fdef10399a96a1a45']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'end'))));
  separator = text.lastIndexOf(', ') + 1;
  dateText = text.slice(0, separator - 1);
  timeText = text.slice(((separator + 2) - 1), text.length);

  return ([dateText,'<br>',timeText].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/f3928bbb52be2243223e10f9f4c6472d/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('45b44049df1be25dc6a37a66444e9e1c')

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/d405e0d4d22414727fa5abc2b0194c4b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var breaksText, breaks, extraText, session, extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'breaks'));
  breaksText = null;
  if (breaks) {
    breaksText = [' + ',breaks,'m breaks'].join('');
  }
  return (['(',(await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc'](((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'duration')) / 60))),'hr x £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'rateLocum')))),'/hr)',breaksText].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/c03d79b79bac79c7ba01a3fc6768bf47/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'notesLocum'));
  if (notes) {
    return (['Note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/04fbde667f982c08e09576f798b2e20b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'costLocum'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/e677bbca4855585c7afd9b1845088598/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalDuration, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  totalDuration = null;
  var j_list = ___arguments.context.getComponentDataStoreByUid('45b44049df1be25dc6a37a66444e9e1c');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalDuration = totalDuration + (getObjectProperty(j, 'duration'));
  }
  return (String((await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc']((totalDuration / 60)))) + String(' hrs total'));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/180df389bea17f4ad7ce0803e4e7adb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'totalIncVat'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/0778b8464b17c9011c133bae0da51ab3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var sessionCount;


  sessionCount = ___arguments.context.getComponentDataStoreByUid('45b44049df1be25dc6a37a66444e9e1c').length;
  if (sessionCount <= 1) {
    return (['Session (',sessionCount,')'].join(''));
  } else {
    return (['Sessions (',sessionCount,')'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/f67e74742ac58df7436b7d28be9e6fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status')) == 'FOR BUNDLING') {
    return 'NOT ISSUED';
  } else {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'bundleRef'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/c0b8572bcdca92abbe4cfabb17421e15/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['(of which £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'vat')))),' is VAT)'].join(''))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'vat'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/b2d55872b3d9bb2cb9c81ed93404fa8a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['(of which £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'vat')))),' is VAT)'].join(''))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'vat'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/9553b6e97d4cbadd156363c80c2f7e32/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'notesLocum'));
  if (notes) {
    return (['Locum note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/fb865753d4a9fdd948c72753bba6565c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  extra = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'extra'));
  if (!(extra % 1 == 0)) {
    extra = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](extra));
  }
  return (['+ £',extra,' extra charge'].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/8447928c7327ef1f266e8fe299835d2e/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'issueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/6844f2f98d5c1771b6c69953801403b5/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'dueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/30a46c95baad6b47fd37da83fc65dba8/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'issueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/2a24edae06fc267fd3c87364ebda04b9/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'dueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/fb6dbf7f998464f1ed5b05cdc913db67/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  extra = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'extraAgency'));
  if (!(extra % 1 == 0)) {
    extra = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](extra));
  }

  return (['+ £',extra,' extra charge'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/662774575a658e81ff29b726b75938c9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  extra = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'extraLocum'));
  if (!(extra % 1 == 0)) {
    extra = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](extra));
  }
  return (['+ £',extra,' extra charge'].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/7807bd6a10ea0fa7d58b70e7a4d179c4/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'issueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/7516127cb3659cab2d8df6781e1ebc86/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'dueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/9245637fb29bab11fdf9ceb22bae4e6a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://drive.google.com/drive/folders/') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'googleFolderId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Bundles/components/eea19a13a33abb17f2579554d1646114/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://drive.google.com/drive/folders/') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'googleFolderId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Bundles/components/e9136d8dbf885fd720456216e434cf01/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://drive.google.com/drive/folders/') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'googleFolderId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Bundles/components/6436d21ac5de8d0943efa27a050d8957/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var statusDesc, status2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  status2 = (getObjectProperty(___arguments.context.dataModel, 'key'));
  if (status2 == 'DRAFT') {
    statusDesc = 'Draft for Locum to confirm, not on Xero';
  }
  if (status2 == 'PROCESSING') {
    statusDesc = 'Not yet on Xero';
  }
  if (status2 == 'SUBMITTED') {
    statusDesc = 'On Xero, awaiting approval';
  }
  if (status2 == 'AUTHORISED') {
    statusDesc = 'On Xero, approved & emailed';
  }

  return statusDesc

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/e57c4e6c540728ee8f66c17ac6d1f2b2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'status')) == 'SUBMITTED')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/c30381d3edaedd6f12ba8feb209ad6a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newBundle, bundleGroup, response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cffa51815041ec1d93431a1fb3abc941', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79fdfd44e617ca51e12a530cc2d1fc24', false);
  try {
    response = (await Backendless.Request.post(`${Backendless.appPath}/services/XeroConnect/approveAndEmailBundle`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'objectId'))])));
    console.log(response);
    newBundle = ___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4');
    bundleGroup = (getObjectProperty(___arguments.context.pageData, 'bundleGroup'));
    newBundle['status'] = 'AUTHORISED';
    await BackendlessUI.Functions.Custom['fn_9be1843aef6b25e95f9ce7ebced72c53'](bundleGroup, newBundle, 'AUTHORISED');

  } catch (error) {
    console.log(error);
    (function (message) { alert(message) })(error);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cffa51815041ec1d93431a1fb3abc941', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79fdfd44e617ca51e12a530cc2d1fc24', true);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Bundles/components/281dbc3f2a97ed93ce456df11f46296a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'xeroInvoiceId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Bundles/components/532f637268d89971637b18281a03ee97/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'xeroInvoiceId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Bundles/components/8467df6c338371557b32bd826269e459/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'xeroInvoiceId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Bundles/components/75526127e87d89be93549faa012ea192/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var bundles, showFilter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  showFilter = (getObjectProperty(___arguments.context.pageData, 'ui.showFilter'));
  await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'ui.showFilter', (!showFilter));
  if (showFilter) {
    bundles = (await BackendlessUI.Functions.Custom['fn_67837848890441d1a81faaf4aee44b29']((getObjectProperty(___arguments.context.pageData, 'filters'))));
    await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'bundleGroup', bundles);
  }
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var defaultFilters, differences, filters;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'ui.showFilter')) {
    return 'Apply Filter';
  } else {
    defaultFilters = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c']((getObjectProperty(___arguments.context.pageData, 'ui.defaultFilters'))));
    filters = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c']((getObjectProperty(___arguments.context.pageData, 'filters'))));
    if ((await (async function(obj1, obj2) {

    	  // Both are the same object or both are null/undefined
    	  if (obj1 === obj2) {
    	    return true;
    	  }

    	  // One of them is null/undefined but not the other
    	  if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    	    return false;
    	  }

    	  const keys1 = Object.keys(obj1);
    	  const keys2 = Object.keys(obj2);

    	  // Different number of properties
    	  if (keys1.length !== keys2.length) {
    	    return false;
    	  }

    	  for (let key of keys1) {
    	    if (!keys2.includes(key)) {
    	      return false;
    	    }

    	    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
    	      // Recursive check for nested objects and arrays
    	      if (!isObjectSame(obj1[key], obj2[key])) {
    	        return false;
    	      }
    	    } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
    	      // Array comparison
    	      if (!deepArrayEqual(obj1[key], obj2[key])) {
    	        return false;
    	      }
    	    } else if (obj1[key] !== obj2[key]) {
    	      // Direct value comparison
    	      return false;
    	    }
    	  }

    	  return true;


    	function deepArrayEqual(arr1, arr2) {
    	  if (arr1.length !== arr2.length) {
    	    return false;
    	  }

    	  for (let i = 0; i < arr1.length; i++) {
    	    if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
    	      if (!deepArrayEqual(arr1[i], arr2[i])) {
    	        return false;
    	      }
    	    } else if (typeof arr1[i] === 'object' && typeof arr2[i] === 'object') {
    	      if (!isObjectSame(arr1[i], arr2[i])) {
    	        return false;
    	      }
    	    } else if (arr1[i] !== arr2[i]) {
    	      return false;
    	    }
    	  }

    	  return true;
    	}

    })(defaultFilters, filters))) {
      console.log('No diff');
      console.log(defaultFilters);
      console.log(filters);
      return 'Show Filter';
    } else {
      console.log('Has diff');
      console.log(defaultFilters);
      console.log(filters);
      return 'Edit Filter';
    }
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/6e1e76ad9997652ddbbff5b15ed79e45/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'ui.showFilter'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/cad72f5bb21244ba5a54cbda2162bd3d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status')) == 'AUTHORISED')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Bundles/components/9a5517dbe277a1dc6fdec21160e8993a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fb6acde99f122224eae335af761ff68d', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Bundles/components/8ac55accf7e452a924fbe1684b0c8dfa/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    var void_confirmed;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'ui.voidConfirmText')) == 'VOID THIS BUNDLE') {
    return false;
  }

  return true

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, bundles, response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b0ef55680ea201e70f7c7280aace823', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8ac55accf7e452a924fbe1684b0c8dfa', false);
    response = (await Backendless.Request.put(`${Backendless.appPath}/services/BUNDLES/voidBundle`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'objectId')))));
    console.log(response);
    (function (message) { alert(message) })('Bundle & associated invoices are made VOID. Please inform impacted client, locums & agency');
    ___arguments.context.pageData['selectedBundle'] = null;
    bundles = (await BackendlessUI.Functions.Custom['fn_67837848890441d1a81faaf4aee44b29']((getObjectProperty(___arguments.context.pageData, 'filters'))));
    await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'bundleGroup', bundles);
    await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'selectedOrg', null);

  } catch (error) {
    (function (message) { alert(message) })(error);

  } finally {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b0ef55680ea201e70f7c7280aace823', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8ac55accf7e452a924fbe1684b0c8dfa', true);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Chase/components/099a86d985095d8717a9c02aa8b91525/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Chase/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var tableData, nextPayDate;


  tableData = (await BackendlessUI.Functions.Custom['fn_fdb8d50ca42772581ec7210b66b81681']());
  ___arguments.context.pageData['tableData'] = tableData;

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin-Chase/components/de1299b81b7cc23f415bfc03d87aef31/bundle.js', [], () => ({
  /* content */
  /* handler:columnDefsLogic */
  ['columnDefsLogic'](___arguments) {
    var columns;


  columns = [({ [`field`]: 'companyName',[`headerName`]: 'Client',[`flex`]: 40,[`maxWidth`]: undefined,[`type`]: ['shaded'] }), ({ [`field`]: 'maxOverdueDays',[`flex`]: 20,[`maxWidth`]: undefined,[`type`]: ['numericColumn'] }), ({ [`field`]: 'overdueAmount',[`headerName`]: 'Overdue (£)',[`flex`]: 20,[`maxWidth`]: undefined,[`type`]: ['currency'],[`cellDataType`]: 'number' }), ({ [`field`]: 'nearlyDueAmount',[`headerName`]: '0-14 days',[`flex`]: 20,[`maxWidth`]: undefined,[`type`]: ['numericColumn'] }), ({ [`field`]: 'futureDueAmount',[`headerName`]: '14+ days',[`flex`]: 20,[`maxWidth`]: undefined,[`type`]: ['numericColumn'] }), ({ [`field`]: 'totalDueAmount',[`flex`]: 20,[`maxWidth`]: undefined,[`type`]: ['numericColumn'] }), ({ [`field`]: 'InvoiceCount',[`flex`]: 20,[`maxWidth`]: undefined,[`type`]: ['rightAligned'] })];

  return columns

  },
  /* handler:columnDefsLogic */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var selected, bundles, dueDateString, bundle;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function loadBundles() {
  bundles = (await Backendless.Data.of('clientBundles').find(Backendless.DataQueryBuilder.create().setWhereClause((['client.objectId = \'',(getObjectProperty(selected, 'objectId')),'\''].join(''))).setSortBy('dueDate DESC').setPageSize(100)));
  for (var bundle_index in bundles) {
    bundle = bundles[bundle_index];
    dueDateString = (new Intl.DateTimeFormat(undefined, ({ [`dateStyle`]: 'short' })).format((new Date((getObjectProperty(bundle, 'dueDate'))))));
    bundle['dueDateString'] = dueDateString;
  }
  return bundles
}


  selected = (getObjectProperty(___arguments['cellParams'], 'data'));
  selected['bundles'] = (await loadBundles());
  ___arguments.context.pageData['selectedOrg'] = selected;

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/admin-Chase/components/655cc6754db75fa22939d8c46b4a303c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var tableData;


  tableData = (await BackendlessUI.Functions.Custom['fn_fdb8d50ca42772581ec7210b66b81681']());
  ___arguments.context.pageData['tableData'] = tableData;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Chase/components/7423be68d887b062a8345886e607d876/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var selected;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selected = (getObjectProperty(___arguments['cellParams'], 'data'));
  console.log(selected);
  await (async function(url, tabName) {
  	  // Attempt to open a new tab in the background
  	  const newWindow = window.open(url, tabName);

  	  // Attempt to blur the new window to keep the current window focused
  	  if (newWindow) {
  	    newWindow.blur();
  	  }

  	  // Keep the current window focused
  	  window.focus();
  })((String('https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=') + String(getObjectProperty(selected, 'xeroInvoiceId'))), 'hpsToXero');

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/admin-ForBundle/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var proformaBundles, bundleObject, bundleRef, bundle, agencyInvoices, latestBundleNo, today, agencyTotalIncVat, agencyTimesheets, bundles, item, bundleGroup, pendingBundles, readyBundles, locum, status2, j, locumInvoices, invoice, locumTotalInvVat, timesheets, counter, cost, session, sessions, vatRate, totalIncVat, vatNo;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function do_something() {
  console.log('\\/ ========= [For Bundling] ========= \\/');
  bundles = (await Backendless.Request.get(`${Backendless.appPath}/services/BUNDLES/proforma`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  console.log('==== DEBUG');
  readyBundles = (await asyncListFilter(bundles, async (item) => {


   return ((getObjectProperty(item, 'status')) == 'FOR BUNDLING');
  }));
  pendingBundles = (await asyncListFilter(bundles, async (item) => {


   return ((getObjectProperty(item, 'status')) == 'PENDING');
  }));
  ___arguments.context.pageData['bundleGroup'] = [({ [`status`]: 'FOR BUNDLING',[`bundles`]: readyBundles }), ({ [`status`]: 'PENDING',[`bundles`]: pendingBundles })];
  console.log('Page Data:');
  console.log(___arguments.context.pageData);
  console.log('/\\ ========= [For Bundling] ========= /\\');
}


  console.log('\\/ ========= [For Bundling] ========= \\/');
  bundles = (await Backendless.Request.get(`${Backendless.appPath}/services/BUNDLES/proforma`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  console.log('==== DEBUG');
  readyBundles = (await asyncListFilter(bundles, async (item) => {


   return ((getObjectProperty(item, 'status')) == 'FOR BUNDLING');
  }));
  pendingBundles = (await asyncListFilter(bundles, async (item) => {


   return ((getObjectProperty(item, 'status')) == 'PENDING');
  }));
  ___arguments.context.pageData['bundleGroup'] = [({ [`key`]: 'FOR BUNDLING',[`items`]: readyBundles }), ({ [`key`]: 'PENDING',[`items`]: pendingBundles })];
  console.log('Page Data:');
  console.log(___arguments.context.pageData);
  console.log('/\\ ========= [For Bundling] ========= /\\');

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin-ForBundle/components/c3c6af16b991f541fa3d42377d8877ff/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'key')),' (',(getObjectProperty(___arguments.context.dataModel, 'items')).length,')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/977a5d1858a6fa847acc7f691dd75243/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7866951acae68c6ee00c371b59e1b9f5'), 'client.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/26fe4709ff29dcaa3aead22f0eadc278/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var cost, session, timesheet, locum;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7866951acae68c6ee00c371b59e1b9f5'), 'totalIncVat'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/3c589fdeb4351aeff6881f8c847c3be5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7866951acae68c6ee00c371b59e1b9f5'), 'agencyInvoice')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7866951acae68c6ee00c371b59e1b9f5'), 'agencyInvoice.agency.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7866951acae68c6ee00c371b59e1b9f5'), 'agencyInvoices'))[0]), 'agency.agencyCode'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-ForBundle/components/bf5403c333f6eefba9b10a8bcd9b98a7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-ForBundle/components/7866951acae68c6ee00c371b59e1b9f5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedBundle'] = ___arguments.context.dataModel;
  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'locum') {
    ___arguments.context.pageData['selectedOrg'] = null;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'agency' && !(getObjectProperty(___arguments.context.pageData, 'selectedBundle.agencyInvoice'))) {
    ___arguments.context.pageData['selectedOrg'] = null;
  }
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedBundle.tempId')) == (getObjectProperty(___arguments.context.dataModel, 'tempId'))) {
    return ['hps-list-item-selected'];
  } else if ((getObjectProperty(___arguments.context.dataModel, 'processStatus')) == 'success') {
    return ['hps-list-item-processed'];
  } else if ((getObjectProperty(___arguments.context.dataModel, 'processStatus')) == 'failed') {
    return ['hps-list-item-failed'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/2f3645d1d31db51dc6591382ca4870ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedBundle'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/86dca91febd9732604485b0cc7f51cd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice.agency.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice.agency.companyName')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice.agency.companyName'));
  } else {
    return '-- None --';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/d388b232cac5ad08b3adda8ab2e5dc2a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'client.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-ForBundle/components/d99919def9d34a9c80620bba5f365838/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('35d34a15bea8107e4d14a6443fea95e4'), 'locum.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/d72a77ee97fe484ed42a92943fc7a66a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('510775ff32b2b76efb5693cdf428eaea'), 'approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/f04d91dc243ecf7e09a5a7a79a3a00f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = ({ [`type`]: 'client',[`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'client.objectId')) });
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'client') {
    return ['hps-list-item-selected'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/79e7f665eebddafee96a015f008fdfc3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'client')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/29f75ba8f666036820feece92f654eef/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice'))) {
    return [];
  }
  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'agency') {
    return ['hps-list-item-selected'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = ({ [`type`]: 'agency',[`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agency.objectId')) });
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-ForBundle/components/35d34a15bea8107e4d14a6443fea95e4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = ({ [`type`]: 'locum',[`objectId`]: (getObjectProperty(___arguments.context.dataModel, 'invoiceRef')) });
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.objectId')) == (getObjectProperty(___arguments.context.dataModel, 'invoiceRef'))) {
    return ['hps-list-item-selected'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/6d6479c8ffda8b8b18da34cafe972b36/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'agency')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/a6689b74c2af0206a0582022bc3d04d3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'locum')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/9f0c1a39cb7b5b11876cca74d888219a/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status'));
  } else {
    return 'DRAFT';
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/29f13e5abb0325c858c2029d9bdbc1a5/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_f4453866f827b3e206511b54bcec8ab3'](___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b')))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/265812dfff3bbc5c07fbafdf0f5abc24/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var sessionCount;


  sessionCount = ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5').length;
  if (sessionCount <= 1) {
    return (['Session (',sessionCount,')'].join(''));
  } else {
    return (['Sessions (',sessionCount,')'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-ForBundle/components/82f2a0097d7653e6967bc27adc98d005/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5')

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/cda1386232e40c28bf85936a749ad244/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var breaksText, duration, timeText, dateText, breaks, separator, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  text = (await BackendlessUI.Functions.Custom['fn_3fc304d26564606fdef10399a96a1a45']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'end'))));
  separator = text.lastIndexOf(', ') + 1;
  dateText = text.slice(0, separator - 1);
  timeText = text.slice(((separator + 2) - 1), text.length);

  return ([dateText,'<br>',timeText].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/94edbd0550165fc28ed498260be7b961/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extraText, extra, breaks, breaksText, duration, separator, text, timeText, dateText;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'breaks'));
  breaksText = null;
  if (breaks) {
    breaksText = [' + ',breaks,'m breaks'].join('');
  }
  return (['(',(await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc'](((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'duration')) / 60))),'hr x £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'rate')))),'/hr)',breaksText].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/1e55234a60542365b042a65470f8b4ef/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'cost'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/872d37481c85ae8ef55cfb7ece31b0b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalCost, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  totalCost = null;
  var j_list = ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalCost = totalCost + (getObjectProperty(j, 'cost'));
  }
  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](totalCost))));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/482a0135a28eb78671a1035560b50a58/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalDuration, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  totalDuration = null;
  var j_list = ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalDuration = totalDuration + (getObjectProperty(j, 'duration'));
  }
  return (String((await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc']((totalDuration / 60)))) + String(' hrs total'));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/adba68becc34256c34459f85d056770c/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'status')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'status')) == 'FOR BUNDLING') {
    return 'NOT ISSUED';
  } else {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'InvoiceRef'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/296414d37792394e9fc53bcb6455297e/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_f4453866f827b3e206511b54bcec8ab3']((getObjectProperty(___arguments.context.pageData, 'selectedBundle'))))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/3faa99a561282f4c85711d6be85d86f6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var breaksText, breaks, extraText, extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'breaks'));
  breaksText = null;
  if (breaks) {
    breaksText = [' + ',breaks,'m breaks'].join('');
  }
  return (['(',(await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc'](((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'duration')) / 60))),'hr x £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'rateAgency')))),'/hr)',breaksText].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/eaedf938e87e4747cc4869428f51a197/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var timeText, dateText, separator, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  text = (await BackendlessUI.Functions.Custom['fn_3fc304d26564606fdef10399a96a1a45']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'end'))));
  separator = text.lastIndexOf(', ') + 1;
  dateText = text.slice(0, separator - 1);
  timeText = text.slice(((separator + 2) - 1), text.length);

  return ([dateText,'<br>',timeText].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/4e3462bb23f803ee0096f80d642c4347/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e')

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/e2832243fdfbc2df0b93a5913114bdee/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'notesAgency'));
  if (notes) {
    return (['Agency note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/33ef96345a25da170d5f093318eb88ee/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'notesAgency'));
  if (notes) {
    return (['Note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/3a7ef4dd27fdeae12ad23d5e94d66153/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalCost, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'totalIncVat'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/f2636c4a9655430eb4e6d13d11a87861/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalDuration, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e')) {
    return ;
  }
  totalDuration = 0;
  var j_list = ___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalDuration = totalDuration + (getObjectProperty(j, 'duration'));
  }
  return (String((await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc']((totalDuration / 60)))) + String(' hrs total'));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/3b6c45d503850b639ad64279e3050919/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'costAgency'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/e94bf9ddc95e9d2241de036252772fa4/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b58d8df10e261e42867bdfc29fe57c28']((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'selectedBundle')), 'locumInvoices')), 'invoiceRef', (getObjectProperty(___arguments.context.pageData, 'selectedOrg.objectId'))))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/35baebb984bf7c2af89f517ee4e462b5/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'locum.prnType')),'-',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'locum.prn'))].join(''))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/aa8ffef7004969a9900472058d5ea5ba/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'status')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'status')) == 'FOR BUNDLING') {
    return 'NOT ISSUED';
  } else {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'bundleRef'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/8a63d58a0f017f51d96d50a54dcc7468/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var sessionCount;


  sessionCount = ___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e').length;
  if (sessionCount <= 1) {
    return (['Session (',sessionCount,')'].join(''));
  } else {
    return (['Sessions (',sessionCount,')'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/45b44049df1be25dc6a37a66444e9e1c/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_fdb94a6c4612bd388ffc675dd1fc0c5d']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'timesheets'))))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/b05113c728e90069569e401f877120bf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var separator, text, timeText, dateText;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  text = (await BackendlessUI.Functions.Custom['fn_3fc304d26564606fdef10399a96a1a45']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'end'))));
  separator = text.lastIndexOf(', ') + 1;
  dateText = text.slice(0, separator - 1);
  timeText = text.slice(((separator + 2) - 1), text.length);

  return ([dateText,'<br>',timeText].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/f3928bbb52be2243223e10f9f4c6472d/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('45b44049df1be25dc6a37a66444e9e1c')

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/d405e0d4d22414727fa5abc2b0194c4b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var breaksText, breaks, extraText, session, extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'breaks'));
  breaksText = null;
  if (breaks) {
    breaksText = [' + ',breaks,'m breaks'].join('');
  }
  return (['(',(await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc'](((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'duration')) / 60))),'hr x £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'rateLocum')))),'/hr)',breaksText].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/c03d79b79bac79c7ba01a3fc6768bf47/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'notesLocum'));
  if (notes) {
    return (['Note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/04fbde667f982c08e09576f798b2e20b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'costLocum'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/e677bbca4855585c7afd9b1845088598/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalDuration, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  totalDuration = null;
  var j_list = ___arguments.context.getComponentDataStoreByUid('45b44049df1be25dc6a37a66444e9e1c');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalDuration = totalDuration + (getObjectProperty(j, 'duration'));
  }
  return (String((await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc']((totalDuration / 60)))) + String(' hrs total'));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/180df389bea17f4ad7ce0803e4e7adb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'totalIncVat'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/0778b8464b17c9011c133bae0da51ab3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var sessionCount;


  sessionCount = ___arguments.context.getComponentDataStoreByUid('45b44049df1be25dc6a37a66444e9e1c').length;
  if (sessionCount <= 1) {
    return (['Session (',sessionCount,')'].join(''));
  } else {
    return (['Sessions (',sessionCount,')'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/f67e74742ac58df7436b7d28be9e6fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status')) == 'FOR BUNDLING') {
    return 'NOT ISSUED';
  } else {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'bundleRef'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/c0b8572bcdca92abbe4cfabb17421e15/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['(of which £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'vat')))),' is VAT)'].join(''))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'vat'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/b2d55872b3d9bb2cb9c81ed93404fa8a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['(of which £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'vat')))),' is VAT)'].join(''))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'vat'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/a2bdc598351b64825fd7a8c5daeec998/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var timesheets, error, newBundle, bundleGroup, y, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function bundleAll() {
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0412633f7a73fd38a56a1ad6fa770e7e', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c331835796ae49f8304bab9fb998c999', false);
  (function (message) { alert(message) })('Processing selected bundle. Keep this window open.');
  timesheets = [];
  var j_list = (getObjectProperty(___arguments.context.pageData, 'selectedBundle.locumInvoices'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    var y_list = (getObjectProperty(j, 'timesheets'));
    for (var y_index in y_list) {
      y = y_list[y_index];
      addItemToList(timesheets, (getObjectProperty(y, 'objectId')));
    }
  }
  try {
    await Backendless.Request.post(`${Backendless.appPath}/services/BUNDLES/addBundle`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(timesheets));
    bundleGroup = (getObjectProperty(___arguments.context.pageData, 'bundleGroup'));
    newBundle = (getObjectProperty(___arguments.context.pageData, 'selectedBundle'));
    newBundle['processStatus'] = 'success';
    newBundle['status'] = 'BUNDLED';
    await BackendlessUI.Functions.Custom['fn_9be1843aef6b25e95f9ce7ebced72c53'](bundleGroup, newBundle, 'BUNDLED', 'tempId');
    await new Promise(r => setTimeout(r, 200 || 0));
    (function (message) { alert(message) })('All done.');

  } catch (error) {
    (function (message) { alert(message) })(error);
    (getObjectProperty(___arguments.context.pageData, 'selectedBundle'))['processStatus'] = 'failed';
    console.log(error);

  } finally {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0412633f7a73fd38a56a1ad6fa770e7e', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c331835796ae49f8304bab9fb998c999', true);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-ForBundle/components/d4a5d625081a819d84e75151f3d36ff9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var _BundledList, _forBundlingList, bundle, bundleGroup, bundles, counter, error, item, locum, newBundle, result, timesheets, y;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function bundleAll() {
  bundles = (getObjectProperty(((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'bundleGroup')), async (item) => {


   return ((getObjectProperty(item, 'key')) == 'FOR BUNDLING');
  }))[0]), 'items'));
  console.log(bundles);
  counter = 0;
  for (var bundle_index in bundles) {
    bundle = bundles[bundle_index];
    if (counter != 0) {
      await new Promise(r => setTimeout(r, 10000 || 0));
    }
    await processBundle(bundle);
  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function processBundle(bundle) {
  timesheets = [];
  var locum_list = (getObjectProperty(bundle, 'locumInvoices'));
  for (var locum_index in locum_list) {
    locum = locum_list[locum_index];
    var y_list = (getObjectProperty(locum, 'timesheets'));
    for (var y_index in y_list) {
      y = y_list[y_index];
      addItemToList(timesheets, (getObjectProperty(y, 'objectId')));
    }
  }
  try {
    result = (await Backendless.Request.post(`${Backendless.appPath}/services/BUNDLES/addBundle`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(timesheets)));
    bundleGroup = (getObjectProperty(___arguments.context.pageData, 'bundleGroup'));
    await BackendlessUI.Functions.Custom['fn_9be1843aef6b25e95f9ce7ebced72c53'](bundleGroup, bundle, 'BUNDLED', 'tempId');
    bundle['processStatus'] = 'success';
    Object.assign(bundle, result);

  } catch (error) {
    console.log(error);
    bundle['processStatus'] = 'failed';

  }
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0412633f7a73fd38a56a1ad6fa770e7e', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c331835796ae49f8304bab9fb998c999', false);
  (function (message) { alert(message) })('This can take a while. Keep this window open... and get a coffee while Tinny does all the hard work!');
  await bundleAll();
  console.log(___arguments.context.pageData);
  await new Promise(r => setTimeout(r, 200 || 0));
  (function (message) { alert(message) })('All done.');
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0412633f7a73fd38a56a1ad6fa770e7e', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c331835796ae49f8304bab9fb998c999', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-ForBundle/components/9553b6e97d4cbadd156363c80c2f7e32/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'notesLocum'));
  if (notes) {
    return (['Locum note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/fb865753d4a9fdd948c72753bba6565c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  extra = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'extra'));
  if (!(extra % 1 == 0)) {
    extra = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](extra));
  }
  return (['+ £',extra,' extra charge'].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/8447928c7327ef1f266e8fe299835d2e/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'issueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/6844f2f98d5c1771b6c69953801403b5/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'dueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/30a46c95baad6b47fd37da83fc65dba8/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'issueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/2a24edae06fc267fd3c87364ebda04b9/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'dueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/fb6dbf7f998464f1ed5b05cdc913db67/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  extra = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'extraAgency'));
  if (!(extra % 1 == 0)) {
    extra = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](extra));
  }

  return (['+ £',extra,' extra charge'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-ForBundle/components/662774575a658e81ff29b726b75938c9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  extra = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'extraLocum'));
  if (!(extra % 1 == 0)) {
    extra = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](extra));
  }
  return (['+ £',extra,' extra charge'].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
      console.log('\\/ ========= [Register] ========= \\/');
  ___arguments.context.pageData['types'] = ['agencies', 'clients', 'locums'];
  ___arguments.context.pageData['___ui'] = ({ [`readOnly`]: true });
  console.log('Page Data:');
  console.log(___arguments.context.pageData);
  console.log('/\\ ========= [Register] ========= /\\');

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin-Organisation/components/6d6479c8ffda8b8b18da34cafe972b36/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.dataModel);

  return ((getObjectProperty(___arguments.context.dataModel, 'objectId')) != null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/cf2a171ee7a1b6786cf46183a86596d2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}



  return (textToTitleCase(___arguments.context.getComponentDataStoreByUid('8cde17c2deabeb646f64657c226ee4fd')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/8cde17c2deabeb646f64657c226ee4fd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedType'] = ___arguments.context.dataModel;
  delete ___arguments.context.pageData['selected'];
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedType')) == ___arguments.context.dataModel) {
    return ['hps-list-item-selected'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/9cdd211335a8ab88248a5c7e5233bcf9/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  async ['onRecordSelect'](___arguments) {
    var parent2, response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_175bed3b3e7cf2c4284426d555ce2d29'](___arguments.context.pageData, (getObjectProperty(___arguments.selectedRecord, 'objectId')));

  },
  /* handler:onRecordSelect */
  /* handler:onTableNameAssignment */
  ['onTableNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedType'))

  },
  /* handler:onTableNameAssignment */
  /* handler:onColumnsAssignment */
  ['onColumnsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedType')) == 'locums') {
    return [({ [`field`]: 'approved',[`title`]: 'Appr\'d',[`searchable`]: true,[`hidden`]: false,[`width`]: 5,[`type`]: 'boolean' }), ({ [`field`]: 'name',[`title`]: 'Name',[`searchable`]: true,[`hidden`]: false }), ({ [`field`]: 'billingEmail',[`title`]: 'Billing Email',[`searchable`]: true,[`hidden`]: false })];
  } else {
    return [({ [`field`]: 'approved',[`title`]: 'Appr\'d',[`searchable`]: true,[`hidden`]: false,[`width`]: 5,[`type`]: 'boolean' }), ({ [`field`]: 'companyName',[`title`]: 'Company Name',[`searchable`]: true,[`hidden`]: false }), ({ [`field`]: 'billingEmail',[`title`]: 'Billing Email',[`searchable`]: true,[`hidden`]: false })];
  }

  },
  /* handler:onColumnsAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/9cd6a9fec3a7115c43c9789923c2fb02/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}



  return (textToTitleCase(getObjectProperty(___arguments.context.pageData, 'selectedType')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/59c335036267d04ee1130586285e8508/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var entityLabel;

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  entityLabel = textToTitleCase((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), true)));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')) == 'new') {
    return (String('New ') + String(entityLabel));
  } else {
    return (String('Selected ') + String(entityLabel));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/5e852caf519dd3e41d2be3991015929b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/c2d66dcb7ed704569e331ee9bda1f45c/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var singleEntity;

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  singleEntity = (await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), true));
  if (singleEntity == 'locum') {
    singleEntity = 'company';
  }

  return (String(textToTitleCase(singleEntity)) + String(' Name'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onRequiredStateAssignment */
  async ['onRequiredStateAssignment'](___arguments) {
      if ((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), null)) != 'locums') {
    return true;
  }

  },
  /* handler:onRequiredStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/e94a8bbeb6921b7a161629775dfc238f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    ___arguments.context.pageData['editable'] = false;
    await BackendlessUI.Functions.Custom['fn_c627fabbfaee5fd7f19b87b4e00b9624']((getObjectProperty(___arguments.context.pageData, 'selectedType')), ___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), (getObjectProperty(___arguments.context.pageData, 'selected.originalData')), (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')));

  } catch (error) {
    (function (message) { alert(message) })(error);
    ___arguments.context.pageData['editable'] = true;

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Organisation/components/1c5e25fe908a4e1995cff7f6f738b983/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('60f6a42dacd9d474dc5bb9e14532286f');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Organisation/components/60f6a42dacd9d474dc5bb9e14532286f/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('60f6a42dacd9d474dc5bb9e14532286f');
  try {
    console.log('Deleting:');
    console.log(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'));
    await Backendless.Request.delete(`${Backendless.appPath}/services/ACCOUNTS/Org`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'orgType': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), '___class')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')) });
    delete (getObjectProperty(___arguments.context.pageData, 'selected'))['data'];
    delete (getObjectProperty(___arguments.context.pageData, 'selected'))['originalData'];
    ___arguments.context.pageData['editable'] = false;
    console.log('Deleted');
    console.log(___arguments.context.pageData);

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* handler:titleLogic */
  async ['titleLogic'](___arguments) {
    function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}



  return (String('Delete ') + String(textToTitleCase((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), true)))))

  },
  /* handler:titleLogic */
  /* content */
}))

define('./pages/admin-Organisation/components/5fd5337b29b677f9640b21263c7feaf8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'selected'))['data'] = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c']((getObjectProperty(___arguments.context.pageData, 'selected.originalData'))));
  ___arguments.context.pageData['editable'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Organisation/components/d66e1edecf6f8b97911fc1e1110ba5f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selected'] = ({ [`data`]: ({ [`objectId`]: 'new',[`submitId`]: (generateUUID().short()),[`___class`]: (getObjectProperty(___arguments.context.pageData, 'selectedType')) }) });
  if ((getObjectProperty(___arguments.context.pageData, 'selectedType')) == 'locums') {
    Object.assign((getObjectProperty(___arguments.context.pageData, 'selected.data')), ({ [`prnType`]: 'GMC' }));
  }
  ___arguments.context.pageData['editable'] = true;
  console.log('NEW');
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}



  return (String('Add ') + String(textToTitleCase((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5']((getObjectProperty(___arguments.context.pageData, 'selectedType')), true)))))

  },
  /* handler:onTooltipTextAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/f1316bdcd06aba11c54360243edc57e6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/4e32d989ea2d14e6052e5213808afb65/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/fd313c720cfc15edcf79b691f0fa077f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/127a55b8733dc885e8da0e4d99930d4c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/ee48964ecce8c631e3b71c031fb749c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/d596bcd4e9c76d02c16220e2e904cb3b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/cdcad0e56cbc36167fef65c226fee7c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/bc25098be7d9e6c34deb275bdc9d50f3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/d41349be9a7fe36c3aa330c5f6ac6b15/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/13f9dda2be39688595d52e7d7db5558e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/4018122b5c4f38e519772c5800c6fd88/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/45e9f206d429bc4dfff9fed741234319/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/b73e62d57cebfc7fc5b35feabeefe77c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/b6461d6517d43347c1d3e23c0e26df5a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onVisibilityChange */
  ['onVisibilityChange'](___arguments) {
      if (___arguments.newValue) {
    ___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')['prnType'] = 'GMC';
  }

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/admin-Organisation/components/96765d6dcfdb96a5d47b3f78f934c34b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/4d331d53391fb53525ce5bbb332a014a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), '___class')) == 'locums')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/2f3645d1d31db51dc6591382ca4870ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedType'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/07af16b1a27b25dbdb861a36d188a376/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}



  return (String('APPROVE ') + String(textToTitleCase((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), true)))))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.Data.of((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), null))).deepSave( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')),[`approved`]: true }) );
    ___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')['approved'] = true;

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'approved')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/ccea647bfcb9d91fc0bb835d85315b64/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editable'] = false;
  delete ___arguments.context.pageData['selected'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Organisation/components/eff10b5bdbe5df29670cfd19e0e89a45/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, results, formData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  try {
    console.log('Submitted data:');
    console.log(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'));
    formData = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')));
    formData = (await BackendlessUI.Functions.Custom['fn_95ba4446c12cc8fc5e3977e10f082fff'](formData));
    console.log('Pruned Submitted Data:');
    console.log(formData);
    delete formData['objectId'];
    results = (await Backendless.Request.post(`${Backendless.appPath}/services/ACCOUNTS/addEntity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'data': formData,'orgType': (getObjectProperty(___arguments.context.pageData, 'selectedType')) }));
    (function (message) { alert(message) })((String(textToTitleCase((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), true)))) + String(' registered! 🎉')));
    (getObjectProperty(___arguments.context.pageData, 'selected'))['data'] = results;
    (getObjectProperty(___arguments.context.pageData, 'selected'))['originalData'] = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](results));
    ___arguments.context.pageData['editable'] = false;
    console.log('Page Data:');
    console.log(___arguments.context.pageData);

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Organisation/components/5798e559aee12975c4380f6c08fa32e9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var formData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')) == 'new')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/2a82d463beb561c7e1a20303ea4279f1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['selected'];
  delete ___arguments.context.pageData['editable'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Organisation/components/46a6b5e16364b1a4df9804f6a918f00c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}



  return (textToTitleCase((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), true))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/8aa341e97928e883d9b1285f68c18547/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editable'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Organisation/components/68baba8b0ba34dd458df92eb9a378143/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/97fb5853184570084cee3872c859c29e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.Data.of((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), null))).deepSave( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')),[`approved`]: false }) );
    ___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')['approved'] = false;

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (String('Unapprove ') + String((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), true))))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/3ec91da2ee080288510fb4075aac14d3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'editable')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')) != 'new')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/6492f43e180e77f9480cedaaec6f2655/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var response, orgId, orgType, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function toggleSpinner() {
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a3a2941c7cf1e0c95bd1ddfa0d2ccf0e', (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a3a2941c7cf1e0c95bd1ddfa0d2ccf0e')), 'display'))));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6492f43e180e77f9480cedaaec6f2655', (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6492f43e180e77f9480cedaaec6f2655')), 'display'))));
}


  orgId = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId'))));
  orgType = (await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), null));
  await toggleSpinner();
  try {
    console.log((await Backendless.Request.post(`${Backendless.appPath}/services/ADMIN/getFolderPathURL`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': orgId,'orgType': orgType })));
    console.log((await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})('')).setEncoding('utf8').send()));

  } catch (error) {
    console.log(error);

  }
  await toggleSpinner();
  if (!(getObjectProperty(___arguments.context.pageData, 'editable')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')) == orgId) {
    await BackendlessUI.Functions.Custom['fn_175bed3b3e7cf2c4284426d555ce2d29'](___arguments.context.pageData, orgId);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Organisation/components/a5a54a3e02a180dd22b6e7e7cdef63e1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')) == 'new') {
    return '(complete registration first)';
  } else {
    return '(click "Create GDrive Folder" to generate one)';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'folderPath')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/fd8860b508055ad86952ead61ab5de09/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/76d91661dcbba7ec8e8b0aa720d2771e/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')

  },
  /* handler:onContainerDataAssignment */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')) == 'new') {
    return true;
  } else {
    if ((await BackendlessUI.Functions.Custom['fn_1f082225135261fbb908c962d4b69eb8'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'children'))) {
      return true;
    } else if (getObjectProperty(___arguments.context.pageData, 'selected.parent')) {
      return false;
    } else {
      return true;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/bfa283825f87c8b5b3abca56804a02e8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selected.parent')) {
    if ((getObjectProperty(___arguments.context.pageData, 'selected.parent.companyName')) === null) {
      return (getObjectProperty(___arguments.context.pageData, 'selected.parent.name'));
    } else {
      return (getObjectProperty(___arguments.context.pageData, 'selected.parent.companyName'));
    }
  } else {
    return '--';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/5c1fd66ec22818617db324c659bb537d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var test;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something() {
  test = 0;
}



  return (getObjectProperty(___arguments.context.pageData, 'selected.parent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/850a74b85f757b2c082ce3665f084add/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    
  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/367a5d293e32022d0d9f6688e15cf9cd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/d9da851a4e3d882ff41c19ec8fd2787c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((getObjectProperty(___arguments.context.pageData, 'selectedType')).charAt(0).toUpperCase()) + String(' -'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/fc7cd8d9108d1028b65248382bcb94ee/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedType')) == 'agencies')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/2199929714030cbf178c80910a87308a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'dirtyOnXero')) == false) {
    return 'Synced on Xero : Yes';
  } else {
    return 'Synced on Xero : No';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/197e8368e2fe2152a4d1c31d3ddf1beb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var xeroSyncText, j, error, results, orgType;

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32270b8d872925db4024b84b2c9cddd1', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2c293ba6082a53343e171072d0165a6', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('197e8368e2fe2152a4d1c31d3ddf1beb', false);
  orgType = ['agencies', 'clients', 'locums'];
  xeroSyncText = '';
  for (var j_index in orgType) {
    j = orgType[j_index];
    try {
      xeroSyncText = [xeroSyncText,'Synchronising ',textToTitleCase(j),'...'].join('');
      (getObjectProperty(___arguments.context.pageData, '___ui'))['xeroSyncStatusText'] = xeroSyncText;
      results = (await Backendless.Request.post(`${Backendless.appPath}/services/XeroConnect/syncAllDirtyContacts`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(j)));
      xeroSyncText = [xeroSyncText,' (',results,' updated)','\n'].join('');
      (getObjectProperty(___arguments.context.pageData, '___ui'))['xeroSyncStatusText'] = xeroSyncText;
      console.log(results);
      console.log(xeroSyncText);

    } catch (error) {
      console.log(error);
      (function (message) { alert(message) })((['Error synchronising ',textToTitleCase(j),', check log.'].join('')));

    }
  }
  (function (message) { alert(message) })((['Sync with Xero complete','\n',xeroSyncText].join('')));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32270b8d872925db4024b84b2c9cddd1', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2c293ba6082a53343e171072d0165a6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('197e8368e2fe2152a4d1c31d3ddf1beb', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Organisation/components/9f9b081b39e8eb9b65a84d4eee5bf47a/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
    var orgType;


  orgType = (await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), true));

  return (['Turn this on if you wish to exclude this ',orgType,' from the payment CSV. This will allow you to make manual payment for them.'].join(''))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/0e32ee6c43948d2c8fa19ac4b087af22/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/ed322d2790e82a66553240eb865ea4c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var orgType;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orgType = (getObjectProperty(___arguments.context.pageData, 'selectedType'));

  return (orgType != 'clients')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Organisation/components/be952766cc09aeb2b22d1bfcd11ce948/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-Pay/components/099a86d985095d8717a9c02aa8b91525/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Pay/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var tableData, nextPayDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  nextPayDate = (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a']((await (async function() {
  	    const today = new Date();
  	    const dayOfWeek = today.getDay();
  	    let daysToAdd;

  	    if (dayOfWeek === 0 || dayOfWeek === 6) { // If today is Sunday (0) or Saturday (6)
  	        daysToAdd = 5 - dayOfWeek; // Calculate days until the next Friday
  	    } else {
  	        daysToAdd = 12 - dayOfWeek; // Calculate days until the second upcoming Friday
  	    }

  	    const specifiedFriday = new Date(today.setDate(today.getDate() + daysToAdd));
  	    const dd = String(specifiedFriday.getDate()).padStart(2, '0');
  	    const mm = String(specifiedFriday.getMonth() + 1).padStart(2, '0'); // January is 0!
  	    const yyyy = specifiedFriday.getFullYear();

  	    return `${dd}/${mm}/${yyyy}`;
  })()), '23:59'));
  ___arguments.context.pageData['nextPayDate'] = nextPayDate;
  tableData = (await BackendlessUI.Functions.Custom['fn_1a2f7f89a6cb872ca68d3c063693fd6a'](nextPayDate));
  ___arguments.context.pageData['locumTableData'] = (getObjectProperty(tableData, 'locum'));
  ___arguments.context.pageData['agencyTableData'] = (getObjectProperty(tableData, 'agency'));
  ___arguments.context.pageData['lastUpdated'] = (new Intl.DateTimeFormat(undefined, ({ [`dateStyle`]: 'short',[`timeStyle`]: 'short' })).format((new Date())));
  ___arguments.context.pageData['creditNotes'] = (await Backendless.Request.get(`${Backendless.appPath}/services/XeroConnect/CreditNotes`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin-Pay/components/38fc8523ad4f198733ddc479d06c4178/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var nextPayDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  nextPayDate = (getObjectProperty(___arguments.context.pageData, 'nextPayDate'));

  return (String('All payment due<br>before ') + String((await (async function(timestamp) {
	    const inputDate = new Date(timestamp);
	    const today = new Date();

	    // Get day of the week as a string
	    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	    const dayOfWeek = days[inputDate.getDay()];

	    // Calculate the end of this week and the next week
	    let endOfThisWeek = new Date(today);
	    endOfThisWeek.setDate(today.getDate() + (6 - today.getDay()));

	    let endOfNextWeek = new Date(endOfThisWeek);
	    endOfNextWeek.setDate(endOfThisWeek.getDate() + 7);

	    // Format date to Thu, DD/MM/YYYY
	    function formatDate(date) {
	        let day = date.getDate();
	        let month = date.getMonth() + 1;
	        let year = date.getFullYear();
	        return `${dayOfWeek}, ${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
	    }

	    // Check if the given date is this week, next week, or beyond
	    if (inputDate.setHours(0,0,0,0) <= endOfThisWeek.setHours(23,59,59,999)) {
	        return 'this ' + dayOfWeek;
	    } else if (inputDate.setHours(0,0,0,0) <= endOfNextWeek.setHours(23,59,59,999)) {
	        return 'next ' + dayOfWeek;
	    } else {
	        return formatDate(inputDate);
	    }

})(nextPayDate)).toUpperCase()))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Pay/components/1b8cbf6ab2ccfef17b7c2d9e6ee9c8b9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Last updated: ') + String(getObjectProperty(___arguments.context.pageData, 'lastUpdated')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Pay/components/032f8f7d389d3a16eab3b86f801272b8/bundle.js', [], () => ({
  /* content */
  /* handler:onMinDateAssignment */
  ['onMinDateAssignment'](___arguments) {
      return (new Date((new Date()).getTime() + ((1 * 86400) * 1000)))

  },
  /* handler:onMinDateAssignment */
  /* handler:onMaxDateAssignment */
  ['onMaxDateAssignment'](___arguments) {
      return (new Date((new Date()).getTime() + ((15 * 86400) * 1000)))

  },
  /* handler:onMaxDateAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var nextPayDate, tableData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  nextPayDate = ((new Date(___arguments.value)).toDateString());
  nextPayDate = (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a'](nextPayDate, '23:59'));
  tableData = (await BackendlessUI.Functions.Custom['fn_1a2f7f89a6cb872ca68d3c063693fd6a'](nextPayDate));
  ___arguments.context.pageData['locumTableData'] = (getObjectProperty(tableData, 'locum'));
  ___arguments.context.pageData['agencyTableData'] = (getObjectProperty(tableData, 'agency'));
  ___arguments.context.pageData['lastUpdated'] = (new Intl.DateTimeFormat(undefined, ({ [`dateStyle`]: 'short',[`timeStyle`]: 'short' })).format((new Date())));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin-Pay/components/de1299b81b7cc23f415bfc03d87aef31/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var selectedInvoicesIds, selectedCount, sessions;


  selectedInvoicesIds = ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('de1299b81b7cc23f415bfc03d87aef31')).map(item => item['objectId']));
  selectedCount = selectedInvoicesIds.length;
  ___arguments.context.pageData['selectedLInvCount'] = selectedCount;
  ___arguments.context.pageData['selectedLInvIds'] = selectedInvoicesIds;

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/admin-Pay/components/93b1644466bc8ff5c6126e699dc6f78e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var tableData, error, locumInvoiceIds, locumInvoices;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('de1299b81b7cc23f415bfc03d87aef31')).length) {
    (function (message) { alert(message) })('Select at least 1 locum invoice first.');
    return ;
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7a32bce4c885b0adca5e8d2dbee1a2f2', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('93b1644466bc8ff5c6126e699dc6f78e', false);
  locumInvoices = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('de1299b81b7cc23f415bfc03d87aef31'));
  locumInvoiceIds = (locumInvoices.map(item => item['objectId']));
  try {
    console.log((await Backendless.Request.post(`${Backendless.appPath}/services/ADMIN/paylist`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locumInvoiceIds': locumInvoiceIds })));
    console.log('=== DEBUG1');

  } catch (error) {
    console.log(error);
    (function (message) { alert(message) })(error);

  } finally {
    console.log('=== DEBUG2');
    tableData = (await BackendlessUI.Functions.Custom['fn_1a2f7f89a6cb872ca68d3c063693fd6a']((getObjectProperty(___arguments.context.pageData, 'nextPayDate'))));
    console.log('=== DEBUG3');
    ___arguments.context.pageData['locumTableData'] = (getObjectProperty(tableData, 'locum'));
    ___arguments.context.pageData['agencyTableData'] = (getObjectProperty(tableData, 'agency'));
    ___arguments.context.pageData['lastUpdated'] = (new Intl.DateTimeFormat(undefined, ({ [`dateStyle`]: 'short',[`timeStyle`]: 'short' })).format((new Date())));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7a32bce4c885b0adca5e8d2dbee1a2f2', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('93b1644466bc8ff5c6126e699dc6f78e', true);

  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['LOCUM PAYMENT RUN for ',(getObjectProperty(___arguments.context.pageData, 'selectedLInvCount')),' selected'].join(''))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin-Pay/components/4325065e616f7801a9530ecebfeabcea/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://drive.google.com/drive/folders/1mnKHmy0vVMe_J7WHbMKZ60sq2NH-lUbb', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Pay/components/ada6a30c64cb4bbe8e8cfff50d02be03/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var selectedCount;


  selectedCount = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('ada6a30c64cb4bbe8e8cfff50d02be03')).length;
  ___arguments.context.pageData['selectedAInvCount'] = selectedCount;

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/admin-Pay/components/dc62ed2db47c2a43d61731d2994cb0be/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var tableData, error, agencyInvoiceIds, agencyInvoices;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('ada6a30c64cb4bbe8e8cfff50d02be03')).length) {
    (function (message) { alert(message) })('Select at least 1 agency invoice first.');
    return ;
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d5fe4ebd4dd720847a982794ef54939d', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc62ed2db47c2a43d61731d2994cb0be', false);
  agencyInvoices = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('ada6a30c64cb4bbe8e8cfff50d02be03'));
  agencyInvoiceIds = (agencyInvoices.map(item => item['objectId']));
  try {
    console.log((await Backendless.Request.post(`${Backendless.appPath}/services/ADMIN/paylist`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'agencyInvoiceIds': agencyInvoiceIds })));
    console.log('=== DEBUG1');

  } catch (error) {
    console.log(error);
    (function (message) { alert(message) })(error);

  } finally {
    console.log('=== DEBUG2');
    tableData = (await BackendlessUI.Functions.Custom['fn_1a2f7f89a6cb872ca68d3c063693fd6a']((getObjectProperty(___arguments.context.pageData, 'nextPayDate'))));
    console.log('=== DEBUG3');
    ___arguments.context.pageData['locumTableData'] = (getObjectProperty(tableData, 'locum'));
    ___arguments.context.pageData['agencyTableData'] = (getObjectProperty(tableData, 'agency'));
    ___arguments.context.pageData['lastUpdated'] = (new Intl.DateTimeFormat(undefined, ({ [`dateStyle`]: 'short',[`timeStyle`]: 'short' })).format((new Date())));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d5fe4ebd4dd720847a982794ef54939d', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc62ed2db47c2a43d61731d2994cb0be', true);

  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['AGENCY PAYMENT RUN for ',(getObjectProperty(___arguments.context.pageData, 'selectedAInvCount')),' selected'].join(''))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin-Pay/components/81e74fdc7abff7acad3f140b88347f81/bundle.js', [], () => ({
  /* content */
  /* handler:rowsDataLogic */
  async ['rowsDataLogic'](___arguments) {
    var data, item, dateTimeFormat, whereClause, selectedInvIds;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedInvIds = (getObjectProperty(___arguments.context.pageData, 'selectedLInvIds'));
  console.log('DEBUG');
  console.log(selectedInvIds);
  if (selectedInvIds) {
    whereClause = ['invoiceId in (',(await Promise.all(selectedInvIds.map(async item => {; return (['\'',item,'\''].join(''));}))).join(','),')'].join('');
    console.log('WHERE CLAUSE');
    console.log(whereClause);
    data = (await Backendless.Data.of('invoicedSessions_adminView').find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setPageSize(100)));
    console.log('RESULT');
    console.log(data);
    data = (await Promise.all(data.map(async item => {  dateTimeFormat = ({ [`weekday`]: 'short',[`day`]: 'numeric',[`month`]: '2-digit',[`year`]: '2-digit',[`hour`]: '2-digit',[`minute`]: '2-digit',[`timeZone`]: 'Europe/London' });
      item['duration'] = ((getObjectProperty(item, 'duration')) / 60);
      item['start'] = (new Intl.DateTimeFormat('en-GB', dateTimeFormat).format((new Date((getObjectProperty(item, 'start'))))));
      item['end'] = (new Intl.DateTimeFormat('en-GB', dateTimeFormat).format((new Date((getObjectProperty(item, 'end'))))));
      item['xeroInvoiceId'] = (String('https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=') + String(getObjectProperty(item, 'xeroInvoiceId')));
      item['duration'] = (await BackendlessUI.Functions.Custom['fn_ac5be6b99b3b4a27fe69566b9a68d8e2']((getObjectProperty(item, 'duration')), 1));
      item['costLocumNet'] = (await BackendlessUI.Functions.Custom['fn_ac5be6b99b3b4a27fe69566b9a68d8e2']((getObjectProperty(item, 'costLocumNet')), 2));
    ; return item;})));
    return data;
  }

  },
  /* handler:rowsDataLogic */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments['cellParams']);
  await (async function(text) {
  	try {
  	  await navigator.clipboard.writeText(text);
  	  console.log('Text copied to clipboard');
  	} catch (err) {
  	  console.error('Failed to copy text: ', err);
  	}
  })((getObjectProperty(___arguments['cellParams'], 'data.locumEmail')));

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/admin-Reconcile/components/099a86d985095d8717a9c02aa8b91525/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Reconcile/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var nextPayDate;


  nextPayDate = (await (async function() {
  	    var today = new Date();
  	    var nextFriday = new Date();
  	    nextFriday.setDate(today.getDate() + (5 - today.getDay() + 7) % 7 + 7); // Get next Friday
  	    return nextFriday.getTime();
  })());
  ___arguments.context.pageData['nextPayDate'] = nextPayDate;
  ___arguments.context.pageData['tableData'] = (await BackendlessUI.Functions.Custom['fn_1a2f7f89a6cb872ca68d3c063693fd6a'](nextPayDate));
  ___arguments.context.pageData['lastUpdated'] = (new Intl.DateTimeFormat(undefined, ({ [`dateStyle`]: 'short',[`timeStyle`]: 'short' })).format((new Date())));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin-Reconcile/components/796d8ec72aaf1e25e653f0b6c6cb9eda/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://login.xero.com', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Reconcile/components/22035362bf1427814b36177d75aac808/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var syncTextItems, syncText, data, locumText, agencyText, clientText, locumResults, agencyResults, clientResults, results, error;

/**
 * Describe this function...
 */
async function buildSyncText(data) {
  syncText = null;
  syncTextItems = null;
  if (!data || !data.length) {
    syncText = 'Nothing new found on Xero';
  } else {
    syncText = String(data.length) + String(' invoice(s) marked as PAID:');
    syncTextItems = (data.map(item => item['invoiceRef'])).join('<br>');
  }
  return { [`summary`]: syncText,[`items`]: syncTextItems }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function buildSyncText_Client(data) {
  syncText = null;
  syncTextItems = null;
  if (!data || !data.length) {
    syncText = 'Nothing new found on Xero';
  } else {
    syncText = [data.length,' bundle(s) marked as PAID',' & associated agency inv. are changed to "AUTHORISED" status:'].join('');
    syncTextItems = (data.map(item => item['bundleRef'])).join('<br>');
  }
  return { [`summary`]: syncText,[`items`]: syncTextItems }
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('22035362bf1427814b36177d75aac808', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('212c6c3677730c7a8217aef41da5b5f8', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7307063b9671b65e8d8758d854751675', false);
  try {
    results = (await Backendless.Request.get(`${Backendless.appPath}/services/XeroConnect/syncInvoicesStatus/v2`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());

  } catch (error) {
    (function (message) { alert(message) })(error);

  } finally {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('22035362bf1427814b36177d75aac808', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('212c6c3677730c7a8217aef41da5b5f8', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7307063b9671b65e8d8758d854751675', true);

  }
  if (results) {
    clientResults = (getObjectProperty(results, 'clientBundles'));
    agencyResults = (getObjectProperty(results, 'agencyInvoices'));
    locumResults = (getObjectProperty(results, 'locumInvoices'));
    clientText = await buildSyncText_Client(clientResults);
    agencyText = await buildSyncText(agencyResults);
    locumText = await buildSyncText(locumResults);
  } else {
    console.log('NO RESULTS');
    clientText = ({ [`summary`]: 'none found',[`items`]: undefined });
    agencyText = ({ [`summary`]: 'none found',[`items`]: undefined });
    locumText = ({ [`summary`]: 'none found',[`items`]: undefined });
  }
  ___arguments.context.pageData['syncText'] = ({ [`client`]: clientText,[`agency`]: agencyText,[`locum`]: locumText,[`syncedAt`]: (new Intl.DateTimeFormat(undefined, ({ [`dateStyle`]: 'short',[`timeStyle`]: 'short' })).format((new Date()))) });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Reconcile/components/30c6f4e7b8572fac623c64ee4e0c27c5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Last synced at: ') + String(getObjectProperty(___arguments.context.pageData, 'syncText.syncedAt')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin-Timesheet/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var whereClause, defaultDateRange, defaultFilters;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setDefaultFilters() {
  defaultFilters = ({ [`timesheetStatus`]: ['CLIENT APPROVED'],[`timesheetUnapprovedOnly`]: true });
  await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'ui.defaultFilters', defaultFilters);
  await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'filters', (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](defaultFilters)));
}


  console.log('\\/ ========= [Timesheet] ========= \\/');
  ___arguments.context.pageData['types'] = ['agencies', 'clients', 'locums'];
  ___arguments.context.pageData['selectedAgency'] = null;
  defaultDateRange = (await BackendlessUI.Functions.Custom['fn_a2db9ec81f04b2cd4cabedd5ab774c1b'](0, null));
  ___arguments.context.pageData['start'] = (getObjectProperty(defaultDateRange, 'start'));
  ___arguments.context.pageData['end'] = (getObjectProperty(defaultDateRange, 'end'));
  await setDefaultFilters();
  whereClause = (await BackendlessUI.Functions.Custom['fn_d66839f8b842abc1e0232eb54a75c3fc']((getObjectProperty(___arguments.context.pageData, 'filters'))));
  await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'whereClause', whereClause);
  console.log('Page Data:');
  console.log(___arguments.context.pageData);
  console.log('/\\ ========= [Timesheet] ========= /\\');

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin-Timesheet/components/9cdd211335a8ab88248a5c7e5233bcf9/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  async ['onRecordSelect'](___arguments) {
    var error, data, j, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  try {
    data = (await Backendless.Data.of('timesheets').findById((getObjectProperty(___arguments.selectedRecord, 'objectId')), Backendless.DataQueryBuilder.create().setRelationsDepth(1)));
    data['sessions'] = (await asyncListSort((getObjectProperty(data, 'sessions')), 1, async (item) => {
       item['dates'] = ({ [`start`]: (await BackendlessUI.Functions.Custom['fn_3e05e1564369ba3972f29448d781cc2d']((getObjectProperty(item, 'start')))),[`end`]: (await BackendlessUI.Functions.Custom['fn_3e05e1564369ba3972f29448d781cc2d']((getObjectProperty(item, 'end')))) });


     return (getObjectProperty(item, 'start'));
    }));
    ___arguments.context.pageData['selected'] = ({ [`data`]: (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](data)),[`originalData`]: data });
    Object.assign((getObjectProperty(___arguments.context.pageData, '___ui')), ({ [`ts-autocomplete-locum`]: [({ [`label`]: (getObjectProperty(data, 'locum.name')),[`value`]: (getObjectProperty(data, 'locum.objectId')) })],[`ts-autocomplete-client`]: [({ [`label`]: (getObjectProperty(data, 'client.companyName')),[`value`]: (getObjectProperty(data, 'client.objectId')) })],[`ts-autocomplete-agency`]: [({ [`label`]: (getObjectProperty(data, 'agency.companyName')),[`value`]: (getObjectProperty(data, 'agency.objectId')) })] }));

  } catch (error) {
    console.log(error);
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onRecordSelect */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onWhereClauseAssignment */
  ['onWhereClauseAssignment'](___arguments) {
    var agencyId, whereClause, end, start, dateRange;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'whereClause'))

  },
  /* handler:onWhereClauseAssignment */
  /* content */
}))

define('./pages/admin-Timesheet/components/d66e1edecf6f8b97911fc1e1110ba5f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var end, start;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  start = (await (async function(hour) {
  	    // Validate the hour
  	    if (hour < 0 || hour > 23) {
  	        throw new Error("Invalid hour. Please provide an hour between 0 and 23.");
  	    }

  	    // Create a date object for today
  	    const now = new Date();

  	    // Convert to UTC
  	    const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

  	    // London is normally 0 hours ahead of UTC, but during DST it's +1 hour
  	    // Assuming DST in London is from the last Sunday in March to the last Sunday in October
  	    const lastSundayMarch = new Date(Date.UTC(utc.getUTCFullYear(), 2, 31));
  	    const lastSundayOctober = new Date(Date.UTC(utc.getUTCFullYear(), 9, 31));
  	    lastSundayMarch.setUTCDate(lastSundayMarch.getUTCDate() - lastSundayMarch.getUTCDay());
  	    lastSundayOctober.setUTCDate(lastSundayOctober.getUTCDate() - lastSundayOctober.getUTCDay());

  	    let londonOffset = 0; // UTC+0
  	    if (utc >= lastSundayMarch && utc < lastSundayOctober) {
  	        londonOffset = 60; // UTC+1 during DST
  	    }

  	    // Set time to the specified hour in London
  	    const londonTime = new Date(utc.getTime() + londonOffset * 60000);
  	    londonTime.setUTCHours(hour, 0, 0, 0);

  	    // Return the timestamp
  	    return londonTime.getTime();
  })(9));
  end = (await (async function(hour) {
  	    // Validate the hour
  	    if (hour < 0 || hour > 23) {
  	        throw new Error("Invalid hour. Please provide an hour between 0 and 23.");
  	    }

  	    // Create a date object for today
  	    const now = new Date();

  	    // Convert to UTC
  	    const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

  	    // London is normally 0 hours ahead of UTC, but during DST it's +1 hour
  	    // Assuming DST in London is from the last Sunday in March to the last Sunday in October
  	    const lastSundayMarch = new Date(Date.UTC(utc.getUTCFullYear(), 2, 31));
  	    const lastSundayOctober = new Date(Date.UTC(utc.getUTCFullYear(), 9, 31));
  	    lastSundayMarch.setUTCDate(lastSundayMarch.getUTCDate() - lastSundayMarch.getUTCDay());
  	    lastSundayOctober.setUTCDate(lastSundayOctober.getUTCDate() - lastSundayOctober.getUTCDay());

  	    let londonOffset = 0; // UTC+0
  	    if (utc >= lastSundayMarch && utc < lastSundayOctober) {
  	        londonOffset = 60; // UTC+1 during DST
  	    }

  	    // Set time to the specified hour in London
  	    const londonTime = new Date(utc.getTime() + londonOffset * 60000);
  	    londonTime.setUTCHours(hour, 0, 0, 0);

  	    // Return the timestamp
  	    return londonTime.getTime();
  })(11));
  ___arguments.context.pageData['selected'] = ({ [`data`]: (await BackendlessUI.Functions.Custom['fn_746c1e77c956a88fa12d075dabef5d64']('timesheet', (getObjectProperty(___arguments.context.appData, 'currentOrg.___class')))) });
  ___arguments.context.pageData['editable'] = true;
  console.log('NEW');
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  ['onTooltipTextAssignment'](___arguments) {
    function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}



  return (String('Add ') + String(textToTitleCase('')))

  },
  /* handler:onTooltipTextAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Timesheet/components/2a82d463beb561c7e1a20303ea4279f1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['selected'];
  delete ___arguments.context.pageData['editable'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Timesheet/components/8311117d922f3858ba4deb2e207886fa/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var result;


  result = (await BackendlessUI.Functions.Custom['fn_3a6150753c7cfa5c62852c7b6e221eaf'](___arguments['inputValue'], 'agencies', 'selectedAgencyOptions', ___arguments.context.pageData));
  if (result) {
    ___arguments.context.pageData['selectedAgency'] = result;
  }

  },
  /* handler:onChange */
  /* handler:onButtonClearClick */
  async ['onButtonClearClick'](___arguments) {
      await ( async function (componentUid, value) {  ___arguments.context.getComponentByUid(componentUid).setValue(value) })('8311117d922f3858ba4deb2e207886fa', null);
  ___arguments.context.pageData['selectedAgency'] = null;

  },
  /* handler:onButtonClearClick */
  /* handler:placeholderLogic */
  ['placeholderLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'selectedAgency'))) {
    return 'All timesheets';
  }

  },
  /* handler:placeholderLogic */
  /* content */
}))

define('./pages/admin-Timesheet/components/eb241f09a1fbc912f7b24969fa1cb2ba/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var dateRange, option;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  option = (Number(___arguments.value));
  if (typeof option === 'number' && !isNaN(option)) {
    dateRange = (await BackendlessUI.Functions.Custom['fn_a2db9ec81f04b2cd4cabedd5ab774c1b'](option, (await BackendlessUI.Functions.Custom['fn_0915b211c351e798c3c54ba97ad3350e']())));
    ___arguments.context.pageData['start'] = (getObjectProperty(dateRange, 'start'));
    ___arguments.context.pageData['end'] = (getObjectProperty(dateRange, 'end'));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin-Timesheet/components/88294d577d3eeec61b075e9d1bd6da4b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, '___ui.dateRange')) == 'custom')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Timesheet/components/1a23ff11a3cf8bfdf8773b285cb5d94d/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/admin-Timesheet/components/b501fb7475c33424b694e706ae4a7d86/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editable'] = true;
  ___arguments.context.pageData['adminEditable'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Timesheet/components/48d4b5c25fa262f8029a46d3c5047927/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'editable')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')) != 'new')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Timesheet/components/04549ba7da5889c362c8638f59f7217d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    ___arguments.context.pageData['editable'] = false;
    response = (await Backendless.Request.put(`${Backendless.appPath}/services/TIMESHEETS/`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'timesheets': [___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')],'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) }));
    console.log('Response');
    console.log(response);
    if ((getObjectProperty(response, 'failed')).length > 0) {
      ___arguments.context.pageData['editable'] = true;
      throw (new Error((getObjectProperty(((getObjectProperty(response, 'failed'))[0]), 'SERVER_MSG'))))
    }
    (function (message) { alert(message) })('🎉 Timesheet updated!');

  } catch (error) {
    (function (message) { alert(message) })(error);
    ___arguments.context.pageData['editable'] = true;
    ___arguments.context.pageData['adminEditable'] = null;

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Timesheet/components/fdaa47483c33c7fd5beb78f7fbb29632/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Timesheet/components/3c8fd7a97c68680623375dd9e4f70f41/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'selected'))['data'] = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c']((getObjectProperty(___arguments.context.pageData, 'selected.originalData'))));
  ___arguments.context.pageData['editable'] = false;
  ___arguments.context.pageData['adminEditable'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Timesheet/components/f8e71522b7bd1a432cac045295bf5db9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('== Deleting');
  try {
    ___arguments.context.pageData['editable'] = false;
    response = (await Backendless.Request.delete(`${Backendless.appPath}/services/TIMESHEETS/`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'timesheets': [___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')],'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) }));
    console.log('Response');
    console.log(response);
    if ((getObjectProperty(response, 'failed')).length > 0) {
      ___arguments.context.pageData['editable'] = true;
      throw (new Error((getObjectProperty(((getObjectProperty(response, 'failed'))[0]), 'SERVER_MSG'))))
    }
    (function (message) { alert(message) })('🎉 Timesheet updated!');

  } catch (error) {
    (function (message) { alert(message) })(error);
    ___arguments.context.pageData['editable'] = true;

  } finally {
    ___arguments.context.pageData['adminEditable'] = null;

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Timesheet/components/7e1fc0f688b96d7de61c2d08a62d942d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'approved')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'status')) == 'CLIENT APPROVED')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.Data.of('timesheets').deepSave( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')),[`approved`]: true }) );
    ___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')['approved'] = true;

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Timesheet/components/2d7d4691f2c8541af84d77faf87e912b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'approved')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'status')) == 'CLIENT APPROVED')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.Data.of('timesheets').deepSave( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')),[`approved`]: false }) );
    ___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')['approved'] = false;

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Timesheet/components/927ab38d30cad5466ae603778dedb3c5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')) == 'new')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Timesheet/components/af8c0b44f72ffaf61c9764633b881cef/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')) != 'new' && (getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-Timesheet/components/06f6a8f7f2bcd70e3877f05f3c45837e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f8e71522b7bd1a432cac045295bf5db9', ___arguments.value);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin-Timesheet/components/81a1323c0f627cd9285d73edfd54d8e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, response, errorMsg;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function handleErrors(error) {
  errorMsg = (getObjectProperty(error, 'message'));
  if (errorMsg.indexOf('rateLocum') + 1 > 0) {
    error['message'] = 'Some session(s) are missing "Cost". Please amend and resubmit.';
  } else if (errorMsg.indexOf('sessionHash') + 1 > 0) {
    error['message'] = 'Some session(s) have been submitted before. Please amend and resubmit.';
  }
  (function (message) { alert(message) })(error);
}


  try {
    ___arguments.context.pageData['editable'] = false;
    response = (await Backendless.Request.post(`${Backendless.appPath}/services/TIMESHEETS/`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'timesheets': [___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')],'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) }));
    console.log('---> SERVER RESPONSE');
    console.log(response);
    if ((getObjectProperty(response, 'failed')).length > 0) {
      ___arguments.context.pageData['editable'] = true;
      throw (new Error((getObjectProperty(((getObjectProperty(response, 'failed'))[0]), 'SERVER_MSG'))))
    }
    ___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')['objectId'] = (getObjectProperty(((getObjectProperty(response, 'success'))[0]), 'objectId'));
    (getObjectProperty(___arguments.context.pageData, 'selected'))['originalData'] = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36')));
    console.log('Page Data:');
    console.log(___arguments.context.pageData);

  } catch (error) {
    await handleErrors(error);
    ___arguments.context.pageData['editable'] = true;

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Timesheet/components/30895f81311cd443380c76945cdb0aaa/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var filters, defaultFilters;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'ui.showFilter')) {
    return 'Apply Filter';
  } else {
    defaultFilters = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c']((getObjectProperty(___arguments.context.pageData, 'ui.defaultFilters'))));
    filters = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c']((getObjectProperty(___arguments.context.pageData, 'filters'))));
    if ((await (async function(obj1, obj2) {

    	  // Both are the same object or both are null/undefined
    	  if (obj1 === obj2) {
    	    return true;
    	  }

    	  // One of them is null/undefined but not the other
    	  if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    	    return false;
    	  }

    	  const keys1 = Object.keys(obj1);
    	  const keys2 = Object.keys(obj2);

    	  // Different number of properties
    	  if (keys1.length !== keys2.length) {
    	    return false;
    	  }

    	  for (let key of keys1) {
    	    if (!keys2.includes(key)) {
    	      return false;
    	    }

    	    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
    	      // Recursive check for nested objects and arrays
    	      if (!isObjectSame(obj1[key], obj2[key])) {
    	        return false;
    	      }
    	    } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
    	      // Array comparison
    	      if (!deepArrayEqual(obj1[key], obj2[key])) {
    	        return false;
    	      }
    	    } else if (obj1[key] !== obj2[key]) {
    	      // Direct value comparison
    	      return false;
    	    }
    	  }

    	  return true;


    	function deepArrayEqual(arr1, arr2) {
    	  if (arr1.length !== arr2.length) {
    	    return false;
    	  }

    	  for (let i = 0; i < arr1.length; i++) {
    	    if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
    	      if (!deepArrayEqual(arr1[i], arr2[i])) {
    	        return false;
    	      }
    	    } else if (typeof arr1[i] === 'object' && typeof arr2[i] === 'object') {
    	      if (!isObjectSame(arr1[i], arr2[i])) {
    	        return false;
    	      }
    	    } else if (arr1[i] !== arr2[i]) {
    	      return false;
    	    }
    	  }

    	  return true;
    	}

    })(defaultFilters, filters))) {
      console.log('No diff');
      console.log(defaultFilters);
      console.log(filters);
      return 'Show Filter';
    } else {
      console.log('Has diff');
      console.log(defaultFilters);
      console.log(filters);
      return 'Edit Filter';
    }
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var whereClause, showFilter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  showFilter = (getObjectProperty(___arguments.context.pageData, 'ui.showFilter'));
  await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'ui.showFilter', (!showFilter));
  if (showFilter) {
    whereClause = (await BackendlessUI.Functions.Custom['fn_d66839f8b842abc1e0232eb54a75c3fc']((getObjectProperty(___arguments.context.pageData, 'filters'))));
    await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'whereClause', whereClause);
  }
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Timesheet/components/c3cf72a5a0acfb898f430a77a7a82436/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'ui.showFilter'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin-User_details/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var entities, entity, data;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

/**
 * Describe this function...
 */
async function setupPageForNew() {
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e6bc72664926eb6a67651691d9c4373c', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95955ada098d4603ec5ef7f4e9a2781c', true);
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('5b9475ddbc6ee6458b7639fdff56a719'))['content'] = (String('New ') + String(textToTitleCase(entity)));
  ___arguments.context.pageData['readOnly'] = false;
}

/**
 * Describe this function...
 */
async function setupPageForEdit() {
  data = (await Backendless.Data.of(entities).findById((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'formData')), 'objectId')), Backendless.DataQueryBuilder.create().setRelationsDepth(2)));
  ___arguments.context.pageData['originalData'] = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](data));
  ___arguments.context.pageData['formData'] = data;
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('5b9475ddbc6ee6458b7639fdff56a719'))['content'] = (['Edit ',textToTitleCase(entity),' Details'].join(''));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5775fbc37db8dcb5fe954dde141d5f35', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9ceca325c44d2d8c87ab7299a70974cc', true);
  Object.assign((getObjectProperty(___arguments.context.pageData, 'formData')), ({ [`readOnly`]: true }));
}


  console.log(['\\/ ======= [',((function () { return BackendlessUI.Navigator.getCurrentPageName() })()),'] ======= \\/'].join(''));
  entity = 'User';
  entities = 'Users';
  ___arguments.context.pageData['dataModel'] = ({ [`entity`]: entity,[`entities`]: entities });
  ___arguments.context.pageData['readOnly'] = true;
  if (getObjectProperty(___arguments.context.pageData, 'formData')) {
    await setupPageForEdit();
  } else {
    await setupPageForNew();
  }
  console.log(___arguments.context.pageData);
  console.log(['/\\ ======= [',((function () { return BackendlessUI.Navigator.getCurrentPageName() })()),'] ======= /\\'].join(''));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin-User_details/components/3d05cce6b5bcfc6c0d8fd633aa562ec6/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var response, error, submitData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  submitData = (await BackendlessUI.Functions.Custom['fn_0543ab417161d6f88b2ae5729dd41eec'](['readOnly', 'updated'], (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c'](___arguments.context.dataModel))));
  console.log('Submitted Data:');
  console.log(submitData);
  try {
    response = (await Backendless.Request.post(`${Backendless.appPath}/services/ADMIN/user`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(submitData)));
    (function (message) { alert(message) })('Save successful!');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'dataModel')), 'entities')), undefined);

  } catch (error) {
    (function (message) { alert(message) })(error);

  } finally {
    console.log(response);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/admin-User_details/components/ce07bcda2cc3029d20a1b22c44ddebce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.Data.of((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'dataModel')), 'entities'))).remove( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('3d05cce6b5bcfc6c0d8fd633aa562ec6'), 'objectId')) );
    (function (message) { alert(message) })('Deleted');
    (function () { window.history.back(); })();

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'email')) == 'bob@healthcare-payment-service.com'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-User_details/components/5775fbc37db8dcb5fe954dde141d5f35/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5775fbc37db8dcb5fe954dde141d5f35', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('660b4155f333b79f9bb4167b88ed2d28', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e19e1fefd3159ed693fafa949ffd844e', true);
  ___arguments.context.getComponentDataStoreByUid('3d05cce6b5bcfc6c0d8fd633aa562ec6')['readOnly'] = false;
  ___arguments.context.pageData['readOnly'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-User_details/components/91031cbacbebefa738c1a1bf48afcad9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-User_details/components/883d6ae85d6e4cd984eb754985bfc132/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-User_details/components/56aef15685b5b5bb57ca9ba558085a51/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-User_details/components/95955ada098d4603ec5ef7f4e9a2781c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-User_details/components/e123ef9486338cb9741212a34778aca3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-User_details/components/9ceca325c44d2d8c87ab7299a70974cc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9ceca325c44d2d8c87ab7299a70974cc', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95955ada098d4603ec5ef7f4e9a2781c', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ce1eeb983f8df8f6a9172bbd22bda11', true);

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin-User_details/components/6ce1eeb983f8df8f6a9172bbd22bda11/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ce1eeb983f8df8f6a9172bbd22bda11', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95955ada098d4603ec5ef7f4e9a2781c', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9ceca325c44d2d8c87ab7299a70974cc', true);
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('3d05cce6b5bcfc6c0d8fd633aa562ec6'), 'password')) {
    delete ___arguments.context.getComponentDataStoreByUid('3d05cce6b5bcfc6c0d8fd633aa562ec6')['password'];
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-User_details/components/f2b9867e80e7ffcb7c89ed2b734ddbb4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'originalData')) {
    return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'originalData')), 'userStatus')) == 'EMAIL_CONFIRMATION_PENDING');
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('909b0ba3d60beea2d4fcaeaacbd41642', true);
    await Backendless.UserService.resendEmailConfirmation((getObjectProperty(___arguments.context.getComponentDataStoreByUid('3d05cce6b5bcfc6c0d8fd633aa562ec6'), 'email')));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('909b0ba3d60beea2d4fcaeaacbd41642', false);
    (function (message) { alert(message) })('Email resent');

  } catch (error) {
    (function (message) { alert(message) })(error);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('909b0ba3d60beea2d4fcaeaacbd41642', false);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin-Users/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var transformedResult, result, Tx, bundles, agencyInvoices, locumInvoices, agency, client, timesheetsForAgencyInvoice, locum, data;

/**
 * Describe this function...
 */
async function do_something() {
  await new Promise(r => setTimeout(r, 2000 || 0));
  console.log('\\/ ======== [Bundler] ======== \\/');
  console.log('===> [Bundler], Page Data:');
  console.log(___arguments.context.pageData);
  Tx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async () => {
    const _uow = Tx;

    _uow.find('timesheets', Backendless.DataQueryBuilder.create().setWhereClause('status = \'PROCESSING\'').setProperties(['client.clientName as clientName']).setRelated(['sessions', 'client', 'agency', 'agency']).setRelationsDepth(2).setGroupBy(['clientName']).setPageSize(10));

  })();

  result = (await Backendless.Data.of('timesheets').find(Backendless.DataQueryBuilder.create().setWhereClause('tinnyStatus = \'PROCESSING\'').setProperties(['objectId AS timesheetId', 'client.objectId AS clientId', 'agency.objectId AS agencyId', 'locum.objectId AS locumId']).setRelated(['sessions']).setPageSize(100)));
  console.log(result);
  transformedResult = (await (async function(data, runTest) {
  	if (!runTest) {
  	  return transformData(data);
  	} else {
  	  return testTransformData(data);
  	}

  	function transformData(data) {
  	  let result = {
  	    agencies: []
  	  };
  	  let agenciesMap = {};

  	  data.forEach(item => {
  	    let {
  	      agencyId, clientId, locumId, timesheetId, sessions
  	    } = item;

  	    if (!agenciesMap[agencyId]) {
  	      agenciesMap[agencyId] = {
  	        agencyId,
  	        clients: [],
  	        clientsMap: {}
  	      };
  	      result.agencies.push(agenciesMap[agencyId]);
  	    }

  	    let agency = agenciesMap[agencyId];

  	    if (!agency.clientsMap[clientId]) {
  	      agency.clientsMap[clientId] = {
  	        clientId,
  	        locums: [],
  	        locumsMap: {}
  	      };
  	      agency.clients.push(agency.clientsMap[clientId]);
  	    }

  	    let client = agency.clientsMap[clientId];

  	    if (!client.locumsMap[locumId]) {
  	      client.locumsMap[locumId] = {
  	        locumId,
  	        timesheets: [],
  	        timesheetsMap: {}
  	      };
  	      client.locums.push(client.locumsMap[locumId]);
  	    }

  	    let locum = client.locumsMap[locumId];

  	    if (!locum.timesheetsMap[timesheetId]) {
  	      locum.timesheetsMap[timesheetId] = {
  	        timesheetId,
  	        sessions: []
  	      };
  	      locum.timesheets.push(locum.timesheetsMap[timesheetId]);
  	    }

  	    let timesheet = locum.timesheetsMap[timesheetId];
  	    timesheet.sessions = sessions;
  	  });

  	  // Removing Maps used for quick lookup
  	  result.agencies.forEach(agency => {
  	    delete agency.clientsMap;
  	    agency.clients.forEach(client => {
  	      delete client.locumsMap;
  	      client.locums.forEach(locum => {
  	        delete locum.timesheetsMap;
  	      });
  	    });
  	  });

  	  return result;
  	}

  	// Assuming 'data' contains the array of objects.
  	// let transformedData = transformData(data);
  	// console.log(transformedData);


  	// -------- TEST --------
  	function testTransformData() {
  	    var transformedData = transformData(data); // assuming data is your input array
  	    var hasError = false;

  	    data.forEach(function(item) {
  	        var { agencyId, clientId, locumId, timesheetId, sessions } = item;

  	        var agency = transformedData.agencies.find(a => a.agencyId === agencyId);
  	        if (!agency) {
  	            console.log('Error: Agency not found: ' + agencyId);
  	            hasError = true;
  	            return;
  	        }

  	        var client = agency.clients.find(c => c.clientId === clientId);
  	        if (!client) {
  	            console.log('Error: Client not found: ' + clientId + ' in Agency: ' + agencyId);
  	            hasError = true;
  	            return;
  	        }

  	        var locum = client.locums.find(l => l.locumId === locumId);
  	        if (!locum) {
  	            console.log('Error: Locum not found: ' + locumId + ' in Client: ' + clientId);
  	            hasError = true;
  	            return;
  	        }

  	        var timesheet = locum.timesheets.find(t => t.timesheetId === timesheetId);
  	        if (!timesheet) {
  	            console.log('Error: Timesheet not found: ' + timesheetId + ' in Locum: ' + locumId);
  	            hasError = true;
  	            return;
  	        }

  	        if (JSON.stringify(timesheet.sessions) !== JSON.stringify(sessions)) {
  	            console.log('Error: Sessions mismatch in Timesheet: ' + timesheetId);
  	            hasError = true;
  	        }
  	    });

  	    if (!hasError) {
  	        console.log('Test Passed!');
  	    }
  	}

  })(result, false));
  console.log(transformedResult);
  await buildBundles(transformedResult);
  console.log('/\\ ======== [Bundler] ======== /\\');
}

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin-Users/components/2e4f090b8323703928b527ed9b3509c6/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  ['onRecordSelect'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('adminUser_details', ({ [`formData`]: ___arguments.selectedRecord }));

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/admin-Users/components/acacf60981059f9d347c18df2a0807bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('adminUser_details', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/loading/components/469f77c23f226e5ef75f574747b98e99/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var userItems, currentOrg, redirectPage;

/**
 * Describe this function...
 */
async function setupEnvVars() {
  ___arguments.context.appData['rates'] = ({ [`feeA`]: 0.01,[`feeB`]: 0.0299,[`vat`]: 0.2 });
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function directToLoginPage() {
  if (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).slice(0, 6) != 'public') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('publicLogin', undefined);
  }
}

/**
 * Describe this function...
 */
async function getUserDetails() {
  if (!(getObjectProperty(___arguments.context.appData, 'currentUser'))) {
    ___arguments.context.appData['currentUser'] = (await Backendless.UserService.getCurrentUser(false));
  }
}

/**
 * Describe this function...
 */
async function checkAndGetUserItems() {
  if (!(getObjectProperty(___arguments.context.appData, 'timesheets')) || !(getObjectProperty(___arguments.context.appData, 'sessions'))) {
    userItems = (await Backendless.Request.get(`${Backendless.appPath}/services/WEBAPP/getUserInfo`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    Object.assign(___arguments.context.appData, userItems);
    ___arguments.context.appData['currentOrg'] = (await setCurrentOrg());
    (getObjectProperty(___arguments.context.appData, 'currentUser'))['orgType'] = (getObjectProperty((await setCurrentOrg()), '___class'));
    (getObjectProperty(___arguments.context.appData, 'currentUser'))['roles'] = (await Backendless.UserService.getUserRoles());
  }
}

/**
 * Describe this function...
 */
async function setCurrentOrg() {
  if ((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'lastUsedOrgId')) == 'admin') {
    return ({ [`objectId`]: 'admin',[`___class`]: 'admin' });
  } else {
    currentOrg = (await BackendlessUI.Functions.Custom['fn_b58d8df10e261e42867bdfc29fe57c28']((await BackendlessUI.Functions.Custom['fn_9cfbe496e6b8ffd1c2ab3ee189e875f2'](userItems)), 'objectId', (getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'lastUsedOrgId'))));
    if (currentOrg) {
      return currentOrg;
    }
    currentOrg = (await (async function(data) {
    	    for (let key in data) {
    	        if (data.hasOwnProperty(key) && Array.isArray(data[key]) && data[key].length > 0) {
    	            return data[key][0];
    	        }
    	    }
    	    return null;
    })(userItems));
  }
  return currentOrg
}

/**
 * Describe this function...
 */
async function loadLibraries() {
  await BackendlessUI.Functions.Custom['fn_0825bd12567a646d018a52644f41183e']();
}

/**
 * Describe this function...
 */
async function redirectToPage() {
  redirectPage = (getObjectProperty(___arguments.context.pageData, 'redirect'));
  if (redirectPage && redirectPage != ((function () { return BackendlessUI.Navigator.getCurrentPageName() })())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })(redirectPage, undefined);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin-Timesheet', undefined);
  }
}

/**
 * Describe this function...
 */
async function checkIfAdmin() {
  if ((getObjectProperty(___arguments.context.appData, 'currentUser.email')).indexOf('@healthcare-payment-service.com') + 1 > 0) {
    (getObjectProperty(___arguments.context.appData, 'currentUser'))['admin'] = true;
  }
}


  console.log('\\/ ======== [Loading Page] ======== \\/');
  console.log('Source page:');
  console.log(getObjectProperty(___arguments.context.pageData, 'redirect'));
  await loadLibraries();
  await getUserDetails();
  await checkAndGetUserItems();
  await checkIfAdmin();
  await setupEnvVars();
  console.log('---> App Data:');
  console.log(___arguments.context.appData);
  console.log('/\\ ======== [Loading Page] ======== /\\');
  console.log('');
  await new Promise(r => setTimeout(r, 500 || 0));
  await redirectToPage();

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/owner-Revenue/components/099a86d985095d8717a9c02aa8b91525/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/owner-Revenue/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var nextPayDate;


  nextPayDate = (await (async function() {
  	    var today = new Date();
  	    var nextFriday = new Date();
  	    nextFriday.setDate(today.getDate() + (5 - today.getDay() + 7) % 7 + 7); // Get next Friday
  	    return nextFriday.getTime();
  })());
  ___arguments.context.pageData['nextPayDate'] = nextPayDate;
  ___arguments.context.pageData['tableData'] = (await BackendlessUI.Functions.Custom['fn_1a2f7f89a6cb872ca68d3c063693fd6a'](nextPayDate));
  ___arguments.context.pageData['lastUpdated'] = (new Intl.DateTimeFormat(undefined, ({ [`dateStyle`]: 'short',[`timeStyle`]: 'short' })).format((new Date())));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/owner-Revenue/components/796d8ec72aaf1e25e653f0b6c6cb9eda/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_cffa0ba7bef80f0a47bb556ff72ecae4'](((new Date()).valueOf()), 6, ___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/owner-Revenue/components/325a05bfa80befe690bdb0e8bcbd487d/bundle.js', [], () => ({
  /* content */
  /* handler:columnDefsLogic */
  ['columnDefsLogic'](___arguments) {
      return [({ [`field`]: 'isoMonthString',[`headerName`]: 'Month',[`flex`]: 1,[`minWidth`]: 100,[`pinned`]: 'left',[`sort`]: 'asc',[`type`]: ['shaded'] }), ({ [`field`]: 'bundlesTotal',[`headerName`]: 'Factored',[`flex`]: 1,[`minWidth`]: 100,[`type`]: ['numericColumn'] }), ({ [`field`]: 'bundlesCnt',[`headerName`]: '# of Paid/UnPaid',[`flex`]: 1,[`minWidth`]: 150,[`type`]: ['rightAligned'] }), ({ [`field`]: 'bundlesPaid',[`headerName`]: 'Paid',[`flex`]: 1,[`minWidth`]: 90,[`type`]: ['numericColumn'] }), ({ [`field`]: 'allInvPP_Paid',[`headerName`]: 'PP Paid',[`flex`]: 1,[`minWidth`]: 90,[`type`]: ['numericColumn'] }), ({ [`field`]: 'allInvPP_Due',[`headerName`]: 'PP Due',[`flex`]: 1,[`minWidth`]: 90,[`type`]: ['numericColumn'] }), ({ [`field`]: 'profit',[`flex`]: 1,[`minWidth`]: 90,[`type`]: ['numericColumn'] }), ({ [`field`]: 'totalFee',[`headerName`]: 'Fee',[`flex`]: 1,[`minWidth`]: 80,[`type`]: ['numericColumn'] }), ({ [`field`]: 'AInvPP_OOP',[`headerName`]: 'A Adv',[`flex`]: 1,[`minWidth`]: 90,[`type`]: ['numericColumn'] }), ({ [`field`]: 'LInvPP_OOP',[`headerName`]: 'L Adv',[`flex`]: 1,[`minwidth`]: 90,[`type`]: ['numericColumn'] }), ({ [`field`]: 'usedFacilitySize',[`flex`]: 1,[`minWidth`]: 200,[`type`]: ['numericColumn'] })]

  },
  /* handler:columnDefsLogic */
  /* content */
}))

define('./pages/owner-Revenue/components/1f63081aad9c8db8df1180bdf54bc7c3/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'overall')) {
    return (String(Math.round(((getObjectProperty(___arguments.context.pageData, 'overall.currentFacilitySize')) / (getObjectProperty(___arguments.context.pageData, 'overall.unpaidBundles'))) * 100)) + String('%'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/owner-Revenue/components/17c55da3970bc9fce062debe5388a942/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_ac5be6b99b3b4a27fe69566b9a68d8e2']((getObjectProperty(___arguments.context.pageData, 'overall.totalFactored')), 0))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/owner-Revenue/components/e1a826efc81691dfd73a7be956895df2/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_ac5be6b99b3b4a27fe69566b9a68d8e2']((getObjectProperty(___arguments.context.pageData, 'overall.securedProfit')), 0))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/owner-Revenue/components/933df6b18132db68c21a5bcffa7425ea/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_ac5be6b99b3b4a27fe69566b9a68d8e2']((getObjectProperty(___arguments.context.pageData, 'overall.currentFacilitySize')), 0))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/owner-Revenue/components/48a637958348f45a4f6d7e56eaa81ada/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_ac5be6b99b3b4a27fe69566b9a68d8e2']((getObjectProperty(___arguments.context.pageData, 'overall.unpaidBundles')), 0))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/owner-Revenue/components/ead6a133d7a59e39790438a93076f8ff/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'overall')) {
    return (String(Math.round(((getObjectProperty(___arguments.context.pageData, 'overall.projectedFacilitySize')) / (getObjectProperty(___arguments.context.pageData, 'overall.unpaidBundles'))) * 100)) + String('%'));
  }

  return 0

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/owner-Revenue/components/44bd3edc688ce0aec01c4e27e53e5352/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_ac5be6b99b3b4a27fe69566b9a68d8e2']((getObjectProperty(___arguments.context.pageData, 'overall.projectedFacilitySize')), 0))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/owner-Revenue/components/f24f51142d51b5e9d19afa017324a21f/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_ac5be6b99b3b4a27fe69566b9a68d8e2']((getObjectProperty(___arguments.context.pageData, 'overall.avgDailyProfit')), 0))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/owner-Revenue/components/844268cc9fd898f34ebb65a74981a4bb/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'overall')) {
    return (String(Math.round((((getObjectProperty(___arguments.context.pageData, 'overall.avgDailyProfit')) * 365) / (getObjectProperty(___arguments.context.pageData, 'overall.currentFacilitySize'))) * 1000) / 10) + String('%'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/owner-Revenue/components/92f193cd4eb869db0331ecf2681274dd/bundle.js', [], () => ({
  /* content */
  /* handler:datasetsLogic */
  ['datasetsLogic'](___arguments) {
    var trendData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  trendData = (getObjectProperty(___arguments.context.pageData, 'trendData'));
  if (trendData) {
    return [({ [`label`]: 'Used Facility Size',[`data`]: (trendData.map(item => item['usedFacilitySize'])),[`borderColor`]: 'red',[`backgroundColor`]: '#999999' }), ({ [`label`]: 'Projected Facility Size',[`data`]: (trendData.map(item => item['projectedFacilitySize'])),[`borderColor`]: 'blue',[`backgroundColor`]: '#666666' })];
  }

  },
  /* handler:datasetsLogic */
  /* handler:labelsLogic */
  ['labelsLogic'](___arguments) {
    var trendData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  trendData = (getObjectProperty(___arguments.context.pageData, 'trendData'));
  if (trendData) {
    return (trendData.map(item => item['dateTime']));
  }

  },
  /* handler:labelsLogic */
  /* content */
}))

define('./pages/owner-Revenue/components/282f1668826901346d1bb856e3e91752/bundle.js', [], () => ({
  /* content */
  /* handler:datasetsLogic */
  ['datasetsLogic'](___arguments) {
    var trendData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  trendData = (getObjectProperty(___arguments.context.pageData, 'trendData'));
  if (trendData) {
    return [({ [`label`]: 'Avg Daily Profit',[`data`]: (trendData.map(item => item['avgDailyProfit'])),[`borderColor`]: 'red',[`backgroundColor`]: '#999999' })];
  }

  },
  /* handler:datasetsLogic */
  /* handler:labelsLogic */
  ['labelsLogic'](___arguments) {
    var trendData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  trendData = (getObjectProperty(___arguments.context.pageData, 'trendData'));
  if (trendData) {
    return (trendData.map(item => item['dateTime']));
  }

  },
  /* handler:labelsLogic */
  /* content */
}))

define('./pages/owner-Revenue/components/a33c51023964040ccfba575b112249fc/bundle.js', [], () => ({
  /* content */
  /* handler:labelsLogic */
  ['labelsLogic'](___arguments) {
    var trendData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  trendData = (getObjectProperty(___arguments.context.pageData, 'trendData'));
  if (trendData) {
    return (trendData.map(item => item['dateTime']));
  }

  },
  /* handler:labelsLogic */
  /* handler:datasetsLogic */
  ['datasetsLogic'](___arguments) {
    var trendData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  trendData = (getObjectProperty(___arguments.context.pageData, 'trendData'));
  if (trendData) {
    return [({ [`label`]: 'Avg Yield %',[`data`]: (trendData.map(item => item['avgYield'])),[`borderColor`]: 'blue',[`backgroundColor`]: '#666666' })];
  }

  },
  /* handler:datasetsLogic */
  /* content */
}))

define('./pages/profile/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var transformedResult, result, Tx, bundles, agencyInvoices, locumInvoices, agency, client, timesheetsForAgencyInvoice, locum, data;

/**
 * Describe this function...
 */
async function do_something() {
  await new Promise(r => setTimeout(r, 2000 || 0));
  console.log('\\/ ======== [Bundler] ======== \\/');
  console.log('===> [Bundler], Page Data:');
  console.log(___arguments.context.pageData);
  Tx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async () => {
    const _uow = Tx;

    _uow.find('timesheets', Backendless.DataQueryBuilder.create().setWhereClause('status = \'PROCESSING\'').setProperties(['client.clientName as clientName']).setRelated(['sessions', 'client', 'agency', 'agency']).setRelationsDepth(2).setGroupBy(['clientName']).setPageSize(10));

  })();

  result = (await Backendless.Data.of('timesheets').find(Backendless.DataQueryBuilder.create().setWhereClause('tinnyStatus = \'PROCESSING\'').setProperties(['objectId AS timesheetId', 'client.objectId AS clientId', 'agency.objectId AS agencyId', 'locum.objectId AS locumId']).setRelated(['sessions']).setPageSize(100)));
  console.log(result);
  transformedResult = (await (async function(data, runTest) {
  	if (!runTest) {
  	  return transformData(data);
  	} else {
  	  return testTransformData(data);
  	}

  	function transformData(data) {
  	  let result = {
  	    agencies: []
  	  };
  	  let agenciesMap = {};

  	  data.forEach(item => {
  	    let {
  	      agencyId, clientId, locumId, timesheetId, sessions
  	    } = item;

  	    if (!agenciesMap[agencyId]) {
  	      agenciesMap[agencyId] = {
  	        agencyId,
  	        clients: [],
  	        clientsMap: {}
  	      };
  	      result.agencies.push(agenciesMap[agencyId]);
  	    }

  	    let agency = agenciesMap[agencyId];

  	    if (!agency.clientsMap[clientId]) {
  	      agency.clientsMap[clientId] = {
  	        clientId,
  	        locums: [],
  	        locumsMap: {}
  	      };
  	      agency.clients.push(agency.clientsMap[clientId]);
  	    }

  	    let client = agency.clientsMap[clientId];

  	    if (!client.locumsMap[locumId]) {
  	      client.locumsMap[locumId] = {
  	        locumId,
  	        timesheets: [],
  	        timesheetsMap: {}
  	      };
  	      client.locums.push(client.locumsMap[locumId]);
  	    }

  	    let locum = client.locumsMap[locumId];

  	    if (!locum.timesheetsMap[timesheetId]) {
  	      locum.timesheetsMap[timesheetId] = {
  	        timesheetId,
  	        sessions: []
  	      };
  	      locum.timesheets.push(locum.timesheetsMap[timesheetId]);
  	    }

  	    let timesheet = locum.timesheetsMap[timesheetId];
  	    timesheet.sessions = sessions;
  	  });

  	  // Removing Maps used for quick lookup
  	  result.agencies.forEach(agency => {
  	    delete agency.clientsMap;
  	    agency.clients.forEach(client => {
  	      delete client.locumsMap;
  	      client.locums.forEach(locum => {
  	        delete locum.timesheetsMap;
  	      });
  	    });
  	  });

  	  return result;
  	}

  	// Assuming 'data' contains the array of objects.
  	// let transformedData = transformData(data);
  	// console.log(transformedData);


  	// -------- TEST --------
  	function testTransformData() {
  	    var transformedData = transformData(data); // assuming data is your input array
  	    var hasError = false;

  	    data.forEach(function(item) {
  	        var { agencyId, clientId, locumId, timesheetId, sessions } = item;

  	        var agency = transformedData.agencies.find(a => a.agencyId === agencyId);
  	        if (!agency) {
  	            console.log('Error: Agency not found: ' + agencyId);
  	            hasError = true;
  	            return;
  	        }

  	        var client = agency.clients.find(c => c.clientId === clientId);
  	        if (!client) {
  	            console.log('Error: Client not found: ' + clientId + ' in Agency: ' + agencyId);
  	            hasError = true;
  	            return;
  	        }

  	        var locum = client.locums.find(l => l.locumId === locumId);
  	        if (!locum) {
  	            console.log('Error: Locum not found: ' + locumId + ' in Client: ' + clientId);
  	            hasError = true;
  	            return;
  	        }

  	        var timesheet = locum.timesheets.find(t => t.timesheetId === timesheetId);
  	        if (!timesheet) {
  	            console.log('Error: Timesheet not found: ' + timesheetId + ' in Locum: ' + locumId);
  	            hasError = true;
  	            return;
  	        }

  	        if (JSON.stringify(timesheet.sessions) !== JSON.stringify(sessions)) {
  	            console.log('Error: Sessions mismatch in Timesheet: ' + timesheetId);
  	            hasError = true;
  	        }
  	    });

  	    if (!hasError) {
  	        console.log('Test Passed!');
  	    }
  	}

  })(result, false));
  console.log(transformedResult);
  await buildBundles(transformedResult);
  console.log('/\\ ======== [Bundler] ======== /\\');
}

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/profile/components/8a1e27c64fe86232c498332acc7ff607/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  delete ___arguments.context.appData['currentUser'];
  delete ___arguments.context.appData['timesheets'];
  delete ___arguments.context.appData['sessions'];
  console.log('User logged out, Cache cleared');
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('publicLogin', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/profile/components/5b0efe41b1c4740cc0468bf13f78e27a/bundle.js', [], () => ({
  /* content */
  /* handler:optionsLogic */
  async ['optionsLogic'](___arguments) {
    var profiles, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  profiles = [];
  if ((getObjectProperty(___arguments.context.appData, 'currentUser.admin')) == true) {
    addItemToList(profiles, ({ [`groupLabel`]: 'Admin',[`children`]: [({ [`label`]: 'HPS Admin',[`value`]: ({ [`objectId`]: 'admin',[`___class`]: 'admin' }) })] }));
  }
  if ((getObjectProperty(___arguments.context.appData, 'agencies')).length > 0) {
    addItemToList(profiles, ({ [`groupLabel`]: 'Agencies',[`children`]: (await Promise.all((getObjectProperty(___arguments.context.appData, 'agencies')).map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'companyName')),[`value`]: item });}))) }));
  }
  if ((getObjectProperty(___arguments.context.appData, 'clients')).length > 0) {
    addItemToList(profiles, ({ [`groupLabel`]: 'Clients',[`children`]: (await Promise.all((getObjectProperty(___arguments.context.appData, 'clients')).map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'companyName')),[`value`]: item });}))) }));
  }
  if ((getObjectProperty(___arguments.context.appData, 'locums')).length > 0) {
    addItemToList(profiles, ({ [`groupLabel`]: 'Locums',[`children`]: (await Promise.all((getObjectProperty(___arguments.context.appData, 'locums')).map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'name')),[`value`]: item });}))) }));
  }

  return profiles

  },
  /* handler:optionsLogic */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var OrgId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.appData['currentOrg'] = ___arguments['inputValue'];
  await Backendless.Data.of('Users').save(({ [`objectId`]: (getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')),[`lastUsedOrgId`]: (getObjectProperty(___arguments['inputValue'], 'objectId')) }), true);
  console.log(___arguments.context.appData);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/profile/components/9c72e78d822f976c9af29fa8f486b3ea/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  if (getObjectProperty(___arguments.context.appData, 'currentOrg')) {
    return (textToTitleCase(getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentOrg')), '___class')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/239aa28f85aff568c93bd68e43e5d6b3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'currentOrg')) {
    if ((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentOrg')), '___class')) == 'locums') {
      return (getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentOrg')), 'name'));
    } else {
      return (getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentOrg')), 'companyName'));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/publicLanding/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'first';
  console.log((function () { return window.location.href })());
  await (async function() {
  	// Create a URLSearchParams object from the current page URL
  	const params = new URLSearchParams(window.location.search);

  	// Use the .get() method to retrieve the value of the "data" parameter
  	const dataValue = params.get('data');

  	console.log(dataValue);
  })();

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/publicLogin/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var user, error, password, username, stayLoggedIn, logging, buttonLogin, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    if ((getObjectProperty(user, 'email')).indexOf('@healthcare-payment-service.com') + 1 > 0) {
      user['admin'] = true;
    }
    ___arguments.context.appData['currentUser'] = user;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loading', undefined);
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/publicLogin/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
      if (Backendless.getCurrentUserToken()) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('timesheets', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/searchTest/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var proformaBundles, bundleObject, bundleRef, bundle, agencyInvoices, latestBundleNo, today, agencyTotalIncVat, agencyTimesheets, bundles, locumInvoices, invoice, locum, locumTotalInvVat, timesheets, counter, cost, session, sessions, vatRate, totalIncVat, vatNo;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function buildProformaBundles(bundles) {
  proformaBundles = [];
  latestBundleNo = 1000;
  today = (new Date());
  for (var bundle_index in bundles) {
    bundle = bundles[bundle_index];
    latestBundleNo = (typeof latestBundleNo == 'number' ? latestBundleNo : 0) + 1;
    bundleRef = String(latestBundleNo) + String(getObjectProperty(bundle, 'agency.agencyCode'));
    agencyTimesheets = await getAgencyTimesheets(bundle);
    agencyTotalIncVat = await getAgencyTotal(agencyTimesheets);
    agencyInvoices = [({ [`agency`]: ({ [`objectId`]: (getObjectProperty(bundle, 'agency.objectId')) }),[`invoiceRef`]: String(latestBundleNo) + String('-A'),[`timesheets`]: agencyTimesheets,[`totalIncVat`]: agencyTotalIncVat,[`vat`]: await getVat(agencyTotalIncVat, (getObjectProperty(bundle, 'agency.vatNo'))),[`issueDate`]: today,[`dueDate`]: (new Date(today.getTime() + (((getObjectProperty(bundle, 'agency.terms')) * 86400) * 1000))),[`status`]: 'NOT SENT' })];
    bundleObject = ({ [`bundleNo`]: latestBundleNo,[`bundleRef`]: bundleRef,[`client`]: ({ [`objectId`]: (getObjectProperty(bundle, 'client.objectId')) }),[`agencyInvoices`]: agencyInvoices,[`locumInvoices`]: await locumInvoices2(bundle, bundleRef) });
    addItemToList(proformaBundles, bundleObject);
  }
  console.log('==== Proforma Bundles');
  console.log(proformaBundles);
}

/**
 * Describe this function...
 */
async function locumInvoices2(bundle, bundleRef) {
  locumInvoices = [];
  counter = 0;
  var locum_list = (getObjectProperty(bundle, 'locums'));
  for (var locum_index in locum_list) {
    locum = locum_list[locum_index];
    counter = (typeof counter == 'number' ? counter : 0) + 1;
    timesheets = (getObjectProperty(locum, 'timesheets'));
    locumTotalInvVat = await getLocumTotal(timesheets);
    invoice = ({ [`locum`]: ({ [`objectId`]: (getObjectProperty(locum, 'objectId')) }),[`invoiceRef`]: [bundleRef,'-',counter].join(''),[`timesheets`]: timesheets,[`totalIncVat`]: locumTotalInvVat,[`vat`]: await getVat(locumTotalInvVat, (getObjectProperty(locum, 'locum.vatNo'))),[`issueDate`]: today,[`dueDate`]: (new Date(today.getTime() + (((getObjectProperty(locum, 'locum.terms')) * 86400) * 1000))),[`status`]: 'NOT SENT' });
    addItemToList(locumInvoices, invoice);
  }
  return locumInvoices
}

/**
 * Describe this function...
 */
async function getSessionsFromTimesheets(timesheets) {
  return (await (async function(timesheets) {
	  if (!Array.isArray(timesheets)) {
	    // console.error('Input is not an array');
	    return [];
	  }

	  // Extract sessions from each timesheet
	  let sessions = timesheets.reduce((acc, timesheet) => {
	    if (timesheet.sessions && Array.isArray(timesheet.sessions)) {
	      return acc.concat(timesheet.sessions);
	    }
	    return acc;
	  }, []);

	  // Sort the sessions by start
	  sessions.sort((a, b) => a.start - b.start);

	  return sessions;
})(timesheets))
}

/**
 * Describe this function...
 */
async function getAgencyTimesheets(bundle) {
  timesheets = [];
  var locum_list2 = (getObjectProperty(bundle, 'locums'));
  for (var locum_index2 in locum_list2) {
    locum = locum_list2[locum_index2];
    addItemToList(timesheets, (getObjectProperty(locum, 'timesheets')));
  }
  return timesheets
}

/**
 * Describe this function...
 */
async function defineFilter() {
  await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'filters.statusOptions', [({ [`label`]: 'DRAFT',[`value`]: 'DRAFT' }), ({ [`label`]: 'PROCESSING',[`value`]: 'PROCESSING' }), ({ [`label`]: 'SUBMITTED',[`value`]: 'SUBMITTED' }), ({ [`label`]: 'DELETED',[`value`]: 'DELETED' }), ({ [`label`]: 'AUTHORISED',[`value`]: 'AUTHORISED' }), ({ [`label`]: 'PAID',[`value`]: 'PAID' }), ({ [`label`]: 'VOIDED',[`value`]: 'VOIDED' })]);
  Object.assign((getObjectProperty(___arguments.context.pageData, 'filters')), ({ [`daterange`]: undefined,[`start`]: (new Date()),[`end`]: undefined,[`status`]: ['PROCESSING', 'SUBMITTED', 'DELETED', 'AUTHORISED'] }));
}

/**
 * Describe this function...
 */
async function getAgencyTotal(timesheets) {
  sessions = await getSessionsFromTimesheets(timesheets);
  cost = 0;
  for (var session_index in sessions) {
    session = sessions[session_index];
    cost = (getObjectProperty(session, 'costAgency')) + cost;
  }
  return cost
}

/**
 * Describe this function...
 */
async function getVat(totalIncVat, vatNo) {
  vatRate = 0.2;
  if (vatNo) {
    return null;
  } else {
    cost = totalIncVat - totalIncVat / (vatRate + 1);
    return cost;
  }
}

/**
 * Describe this function...
 */
async function getLocumTotal(timesheets) {
  sessions = await getSessionsFromTimesheets(timesheets);
  cost = 0;
  for (var session_index2 in sessions) {
    session = sessions[session_index2];
    cost = (getObjectProperty(session, 'costLocum')) + cost;
  }
  return cost
}


  console.log('\\/ ========= [For Bundling] ========= \\/');
  await defineFilter();
  bundles = (await BackendlessUI.Functions.Custom['fn_67837848890441d1a81faaf4aee44b29']((getObjectProperty(___arguments.context.pageData, 'filters'))));
  ___arguments.context.pageData['bundleGroup'] = bundles;
  console.log('Page Data:');
  console.log(___arguments.context.pageData);
  console.log('/\\ ========= [For Bundling] ========= /\\');

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/searchTest/components/c3c6af16b991f541fa3d42377d8877ff/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.dataModel, 'key')),' (',(getObjectProperty(___arguments.context.dataModel, 'items')).length,')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/977a5d1858a6fa847acc7f691dd75243/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7866951acae68c6ee00c371b59e1b9f5'), 'client.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/searchTest/components/26fe4709ff29dcaa3aead22f0eadc278/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var cost, session, timesheet, locum;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7866951acae68c6ee00c371b59e1b9f5'), 'totalIncVat'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/7866951acae68c6ee00c371b59e1b9f5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedBundle'] = ___arguments.context.dataModel;
  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'locum') {
    ___arguments.context.pageData['selectedOrg'] = null;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'agency' && !(getObjectProperty(___arguments.context.pageData, 'selectedBundle.agencyInvoice'))) {
    ___arguments.context.pageData['selectedOrg'] = null;
  }
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedBundle.objectId')) == (getObjectProperty(___arguments.context.dataModel, 'objectId'))) {
    return ['hps-list-item-selected'];
  } else if ((getObjectProperty(___arguments.context.dataModel, 'processStatus')) == 'success') {
    return ['hps-list-item-processed'];
  } else if ((getObjectProperty(___arguments.context.dataModel, 'processStatus')) == 'failed') {
    return ['hps-list-item-failed'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/searchTest/components/2f3645d1d31db51dc6591382ca4870ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedBundle'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/searchTest/components/86dca91febd9732604485b0cc7f51cd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice.agency.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice.agency.companyName')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice.agency.companyName'));
  } else {
    return '-- None --';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/d388b232cac5ad08b3adda8ab2e5dc2a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'client.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/searchTest/components/d99919def9d34a9c80620bba5f365838/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('35d34a15bea8107e4d14a6443fea95e4'), 'locum.approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/searchTest/components/d72a77ee97fe484ed42a92943fc7a66a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('510775ff32b2b76efb5693cdf428eaea'), 'approved'))) {
    return ['missingApproval'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/searchTest/components/f04d91dc243ecf7e09a5a7a79a3a00f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = ({ [`type`]: 'client',[`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'client.objectId')) });
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'client') {
    return ['hps-list-item-selected'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/searchTest/components/79e7f665eebddafee96a015f008fdfc3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'client')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/searchTest/components/29f75ba8f666036820feece92f654eef/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agencyInvoice'))) {
    return [];
  }
  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'agency') {
    return ['hps-list-item-selected'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = ({ [`type`]: 'agency',[`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'agency.objectId')) });
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/searchTest/components/35d34a15bea8107e4d14a6443fea95e4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = ({ [`type`]: 'locum',[`objectId`]: (getObjectProperty(___arguments.context.dataModel, 'invoiceRef')) });
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.objectId')) == (getObjectProperty(___arguments.context.dataModel, 'invoiceRef'))) {
    return ['hps-list-item-selected'];
  } else {
    return ['hps-list-item'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/searchTest/components/6d6479c8ffda8b8b18da34cafe972b36/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'agency')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/searchTest/components/a6689b74c2af0206a0582022bc3d04d3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedOrg.type')) == 'locum')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/searchTest/components/9f0c1a39cb7b5b11876cca74d888219a/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status'));
  } else {
    return 'DRAFT';
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/searchTest/components/29f13e5abb0325c858c2029d9bdbc1a5/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_f4453866f827b3e206511b54bcec8ab3'](___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b')))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/searchTest/components/265812dfff3bbc5c07fbafdf0f5abc24/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var sessionCount;


  sessionCount = ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5').length;
  if (sessionCount <= 1) {
    return (['Session (',sessionCount,')'].join(''));
  } else {
    return (['Sessions (',sessionCount,')'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/searchTest/components/82f2a0097d7653e6967bc27adc98d005/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5')

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/searchTest/components/cda1386232e40c28bf85936a749ad244/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var breaksText, duration, timeText, dateText, breaks, separator, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  text = (await BackendlessUI.Functions.Custom['fn_3fc304d26564606fdef10399a96a1a45']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'end'))));
  separator = text.lastIndexOf(', ') + 1;
  dateText = text.slice(0, separator - 1);
  timeText = text.slice(((separator + 2) - 1), text.length);

  return ([dateText,'<br>',timeText].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/94edbd0550165fc28ed498260be7b961/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extraText, extra, breaks, breaksText, duration, separator, text, timeText, dateText;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'breaks'));
  breaksText = null;
  if (breaks) {
    breaksText = [' + ',breaks,'m breaks'].join('');
  }
  return (['(',(await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc'](((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'duration')) / 60))),'hr x £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'rate')))),'/hr)',breaksText].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/1e55234a60542365b042a65470f8b4ef/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'cost'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/872d37481c85ae8ef55cfb7ece31b0b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalCost, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  totalCost = null;
  var j_list = ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalCost = totalCost + (getObjectProperty(j, 'cost'));
  }
  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](totalCost))));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/482a0135a28eb78671a1035560b50a58/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalDuration, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  totalDuration = null;
  var j_list = ___arguments.context.getComponentDataStoreByUid('29f13e5abb0325c858c2029d9bdbc1a5');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalDuration = totalDuration + (getObjectProperty(j, 'duration'));
  }
  return (String((await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc']((totalDuration / 60)))) + String(' hrs total'));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/adba68becc34256c34459f85d056770c/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'status')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'status')) == 'FOR BUNDLING') {
    return 'NOT ISSUED';
  } else {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'invoiceRef'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/searchTest/components/296414d37792394e9fc53bcb6455297e/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_f4453866f827b3e206511b54bcec8ab3']((getObjectProperty(___arguments.context.pageData, 'selectedBundle'))))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/searchTest/components/3faa99a561282f4c85711d6be85d86f6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var breaksText, breaks, extraText, extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'breaks'));
  breaksText = null;
  if (breaks) {
    breaksText = [' + ',breaks,'m breaks'].join('');
  }
  return (['(',(await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc'](((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'duration')) / 60))),'hr x £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'rateAgency')))),'/hr)',breaksText].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/eaedf938e87e4747cc4869428f51a197/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var timeText, dateText, separator, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  text = (await BackendlessUI.Functions.Custom['fn_3fc304d26564606fdef10399a96a1a45']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'end'))));
  separator = text.lastIndexOf(', ') + 1;
  dateText = text.slice(0, separator - 1);
  timeText = text.slice(((separator + 2) - 1), text.length);

  return ([dateText,'<br>',timeText].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/4e3462bb23f803ee0096f80d642c4347/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e')

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/searchTest/components/e2832243fdfbc2df0b93a5913114bdee/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'notesAgency'));
  if (notes) {
    return (['Agency note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/33ef96345a25da170d5f093318eb88ee/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'notesAgency'));
  if (notes) {
    return (['Note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/3a7ef4dd27fdeae12ad23d5e94d66153/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalCost, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'totalIncVat'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/f2636c4a9655430eb4e6d13d11a87861/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalDuration, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e')) {
    return ;
  }
  totalDuration = 0;
  var j_list = ___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalDuration = totalDuration + (getObjectProperty(j, 'duration'));
  }
  return (String((await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc']((totalDuration / 60)))) + String(' hrs total'));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/3b6c45d503850b639ad64279e3050919/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'costAgency'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/e94bf9ddc95e9d2241de036252772fa4/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b58d8df10e261e42867bdfc29fe57c28']((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'selectedBundle')), 'locumInvoices')), 'invoiceRef', (getObjectProperty(___arguments.context.pageData, 'selectedOrg.objectId'))))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/searchTest/components/35baebb984bf7c2af89f517ee4e462b5/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'locum.prnType')),'-',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'locum.prn'))].join(''))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/searchTest/components/aa8ffef7004969a9900472058d5ea5ba/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'status')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'status')) == 'FOR BUNDLING') {
    return 'NOT ISSUED';
  } else {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'invoiceRef'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/searchTest/components/8a63d58a0f017f51d96d50a54dcc7468/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var sessionCount;


  sessionCount = ___arguments.context.getComponentDataStoreByUid('296414d37792394e9fc53bcb6455297e').length;
  if (sessionCount <= 1) {
    return (['Session (',sessionCount,')'].join(''));
  } else {
    return (['Sessions (',sessionCount,')'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/45b44049df1be25dc6a37a66444e9e1c/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  async ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_fdb94a6c4612bd388ffc675dd1fc0c5d']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'timesheets'))))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/searchTest/components/b05113c728e90069569e401f877120bf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var separator, text, timeText, dateText;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  text = (await BackendlessUI.Functions.Custom['fn_3fc304d26564606fdef10399a96a1a45']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'end'))));
  separator = text.lastIndexOf(', ') + 1;
  dateText = text.slice(0, separator - 1);
  timeText = text.slice(((separator + 2) - 1), text.length);

  return ([dateText,'<br>',timeText].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/f3928bbb52be2243223e10f9f4c6472d/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('45b44049df1be25dc6a37a66444e9e1c')

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/searchTest/components/d405e0d4d22414727fa5abc2b0194c4b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var breaksText, breaks, extraText, session, extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'breaks'));
  breaksText = null;
  if (breaks) {
    breaksText = [' + ',breaks,'m breaks'].join('');
  }
  return (['(',(await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc'](((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'duration')) / 60))),'hr x £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'rateLocum')))),'/hr)',breaksText].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/c03d79b79bac79c7ba01a3fc6768bf47/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'notesLocum'));
  if (notes) {
    return (['Note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/04fbde667f982c08e09576f798b2e20b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'costLocum'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/e677bbca4855585c7afd9b1845088598/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var totalDuration, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  totalDuration = null;
  var j_list = ___arguments.context.getComponentDataStoreByUid('45b44049df1be25dc6a37a66444e9e1c');
  for (var j_index in j_list) {
    j = j_list[j_index];
    totalDuration = totalDuration + (getObjectProperty(j, 'duration'));
  }
  return (String((await BackendlessUI.Functions.Custom['fn_b05c7583efeabed9193f45e5570114cc']((totalDuration / 60)))) + String(' hrs total'));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/180df389bea17f4ad7ce0803e4e7adb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'totalIncVat'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/0778b8464b17c9011c133bae0da51ab3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var sessionCount;


  sessionCount = ___arguments.context.getComponentDataStoreByUid('45b44049df1be25dc6a37a66444e9e1c').length;
  if (sessionCount <= 1) {
    return (['Session (',sessionCount,')'].join(''));
  } else {
    return (['Sessions (',sessionCount,')'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/f67e74742ac58df7436b7d28be9e6fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'status')) == 'FOR BUNDLING') {
    return 'NOT ISSUED';
  } else {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'bundleRef'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/searchTest/components/c0b8572bcdca92abbe4cfabb17421e15/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['(of which £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'vat')))),' is VAT)'].join(''))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'vat'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/searchTest/components/b2d55872b3d9bb2cb9c81ed93404fa8a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['(of which £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'vat')))),' is VAT)'].join(''))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'vat'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/searchTest/components/9553b6e97d4cbadd156363c80c2f7e32/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'notesLocum'));
  if (notes) {
    return (['Locum note: "',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/fb865753d4a9fdd948c72753bba6565c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  extra = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('fdb868d76fe1a8e432ad92f2095832c5'), 'extra'));
  if (!(extra % 1 == 0)) {
    extra = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](extra));
  }
  return (['+ £',extra,' extra charge'].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/8447928c7327ef1f266e8fe299835d2e/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'issueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/searchTest/components/6844f2f98d5c1771b6c69953801403b5/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'dueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/searchTest/components/30a46c95baad6b47fd37da83fc65dba8/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'issueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/searchTest/components/2a24edae06fc267fd3c87364ebda04b9/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'dueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/searchTest/components/fb6dbf7f998464f1ed5b05cdc913db67/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  extra = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fea1f2a16ad7714d063511adf4bcbb9'), 'extraAgency'));
  if (!(extra % 1 == 0)) {
    extra = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](extra));
  }

  return (['+ £',extra,' extra charge'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/662774575a658e81ff29b726b75938c9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  extra = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1d0dcb05308c6ce7b0281c1db2220a5c'), 'extraLocum'));
  if (!(extra % 1 == 0)) {
    extra = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](extra));
  }
  return (['+ £',extra,' extra charge'].join(''));

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/7807bd6a10ea0fa7d58b70e7a4d179c4/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'issueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/searchTest/components/7516127cb3659cab2d8df6781e1ebc86/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_62f22d354471f48466f1159176eeb226']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'dueDate'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/searchTest/components/9245637fb29bab11fdf9ceb22bae4e6a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://drive.google.com/drive/folders/') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'googleFolderId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/searchTest/components/eea19a13a33abb17f2579554d1646114/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://drive.google.com/drive/folders/') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'googleFolderId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/searchTest/components/e9136d8dbf885fd720456216e434cf01/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://drive.google.com/drive/folders/') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'googleFolderId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/searchTest/components/6436d21ac5de8d0943efa27a050d8957/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var statusDesc, status2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  status2 = (getObjectProperty(___arguments.context.dataModel, 'key'));
  if (status2 == 'DRAFT') {
    statusDesc = 'Draft for Locum to confirm, not on Xero';
  }
  if (status2 == 'PROCESSING') {
    statusDesc = 'Not yet on Xero';
  }
  if (status2 == 'SUBMITTED') {
    statusDesc = 'On Xero, awaiting approval';
  }
  if (status2 == 'AUTHORISED') {
    statusDesc = 'On Xero, approved & emailed';
  }

  return statusDesc

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/searchTest/components/e57c4e6c540728ee8f66c17ac6d1f2b2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'status')) == 'SUBMITTED')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/searchTest/components/c30381d3edaedd6f12ba8feb209ad6a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newBundle, bundleGroup, response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cffa51815041ec1d93431a1fb3abc941', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79fdfd44e617ca51e12a530cc2d1fc24', false);
  try {
    response = (await Backendless.Request.post(`${Backendless.appPath}/services/XeroConnect/approveAndEmailBundle`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4'), 'objectId'))])));
    console.log(response);
    newBundle = ___arguments.context.getComponentDataStoreByUid('ad3c99647ec50fb17a474b3eaa96baf4');
    bundleGroup = (getObjectProperty(___arguments.context.pageData, 'bundleGroup'));
    newBundle['status'] = 'AUTHORISED';
    await BackendlessUI.Functions.Custom['fn_9be1843aef6b25e95f9ce7ebced72c53'](bundleGroup, newBundle, 'AUTHORISED');

  } catch (error) {
    console.log(error);
    (function (message) { alert(message) })(error);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cffa51815041ec1d93431a1fb3abc941', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79fdfd44e617ca51e12a530cc2d1fc24', true);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/searchTest/components/281dbc3f2a97ed93ce456df11f46296a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a1caef759c323c0a0b0d7d126a271d7b'), 'xeroInvoiceId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/searchTest/components/532f637268d89971637b18281a03ee97/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e94bf9ddc95e9d2241de036252772fa4'), 'xeroInvoiceId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/searchTest/components/8467df6c338371557b32bd826269e459/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String('https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b15e3770a27958775199c2ea65b489c'), 'xeroInvoiceId'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/searchTest/components/094c75cc2144f21f6877b5dbe3244265/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'ui.filterOn', (!(getObjectProperty(___arguments.context.pageData, 'ui.filterOn'))));
  console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1ccbcf2560f57b0758349237a3392f1d'));

  },
  /* handler:onClick */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var state;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  state = (getObjectProperty(___arguments.context.pageData, 'ui.filterOn'));
  if (state) {
    return 'close';
  } else {
    return 'filter alt';
  }

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/testComponent/components/b7fb1854282838c7488309dc8724e2bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log((await BackendlessUI.Functions.Custom['fn_a80c86cafe9ac2ba7d276a696a9fdb1c']((getObjectProperty(___arguments.context.pageData, 'startString')), (getObjectProperty(___arguments.context.pageData, 'endString')))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheet_approval/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var timesheet;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function preparePage() {
  timesheet = (getObjectProperty(___arguments.context.pageData, 'timesheet'));
  if ((getObjectProperty(timesheet, 'status')) == 'DRAFT') {
    await setupForDraft();
  } else if ((getObjectProperty(timesheet, 'status')) == 'INVOICED') {
    await setupForInvoiced();
  } else if ((getObjectProperty(timesheet, 'status')) == 'APPROVED') {
    await setupForApproved();
  } else if ((getObjectProperty(timesheet, 'status')) == 'REJECTED') {
    await setupForRejected();
  } else if ((getObjectProperty(timesheet, 'status')) == 'VOID') {
    await setupForVoided();
  }
}

/**
 * Describe this function...
 */
async function setupForDraft() {
  ___arguments.context.pageData['formTitle'] = 'Approve Timesheet';
  ___arguments.context.pageData['readOnlyMode'] = true;
}

/**
 * Describe this function...
 */
async function setupForRejected() {
  ___arguments.context.pageData['formTitle'] = 'Timesheet';
  ___arguments.context.pageData['formInfoText'] = 'If you feel this is an error, please contact support@healthcare-payment-service.com. Alternatively, delete this timesheet and submit a new one for approval.';
  ___arguments.context.pageData['readOnlyMode'] = true;
}

/**
 * Describe this function...
 */
async function setupForApproved() {
  ___arguments.context.pageData['formTitle'] = 'Ready for Invoice';
  ___arguments.context.pageData['formInfoText'] = (['Below sessions will be included in your next Invoice to this client. Invoices are issued every Friday (Next: ',((await (async function(timestamp) {
  	    // Convert the timestamp to a Date object
  	    const dateObj = new Date(timestamp);

  	    // Calculate the number of days to the next Friday
  	    let daysToFriday = (5 - dateObj.getUTCDay() + 7) % 7;
  	    if (daysToFriday === 0) {
  	        daysToFriday = 7;  // If today is Friday, then add 7 days to get the next Friday
  	    }

  	    // Add the days to the date object
  	    dateObj.setUTCDate(dateObj.getUTCDate() + daysToFriday);

  	    return dateObj;
  })((new Date()))).toDateString()),').'].join(''));
  ___arguments.context.pageData['readOnlyMode'] = true;
}

/**
 * Describe this function...
 */
async function setupForInvoiced() {
  ___arguments.context.pageData['formTitle'] = 'Timesheet';
  ___arguments.context.pageData['formInfoText'] = 'Refer to "Invoices" to see payment status.';
  ___arguments.context.pageData['readOnlyMode'] = true;
}

/**
 * Describe this function...
 */
async function setupForVoided() {
  ___arguments.context.pageData['formTitle'] = 'Timesheet';
  ___arguments.context.pageData['formInfoText'] = 'If you feel this is an error, please contact support@healthcare-payment-service.com. Alternatively, delete this timesheet and submit a new one for approval.';
  ___arguments.context.pageData['readOnlyMode'] = true;
}


  console.log('\\/ ======== [Timesheet_approval] ======== \\/');
  console.log(___arguments.context.pageData);
  if (!(getObjectProperty(___arguments.context.pageData, 'timesheet'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('timesheets', undefined);
  } else {
    console.log('===> [Timesheet], Page Data:');
    console.log(___arguments.context.pageData);
    await preparePage();
  }
  console.log('/\\ ======== [Timesheet_approval] ======== /\\');

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/timesheet_approval/components/307fdce79ac4b2955cd240fa919c129a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'formInfoText'))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'formInfoText'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheet_approval/components/ad4bf20e021ba53296d436a6564d3bc3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'formTitle'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheet_approval/components/2abf5d847c79c83cadcd41cd412f147f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (new Intl.DateTimeFormat('en-GB', ({ 'weekday': 'short','day': 'numeric','month': '2-digit','timeZone': 'Europe/London' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cc879e88f50739ef441979fa379ba22c'), 'start'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheet_approval/components/711c52ec09165ab83869932d52ee8cbc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(Math.round((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cc879e88f50739ef441979fa379ba22c'), 'duration')) * 10) / 10) + String(' hr'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheet_approval/components/7b0f8404bc486c09ca3ceac9c153e1e6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([Math.round((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cc879e88f50739ef441979fa379ba22c'), 'duration')) * 10) / 10,' x £',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cc879e88f50739ef441979fa379ba22c'), 'rateLocum')),'/hr'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheet_approval/components/1b1495c7bb298769014f05386041c504/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cc879e88f50739ef441979fa379ba22c'), 'costLocum'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheet_approval/components/7779a0065987d324ac5833fea9ce9201/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var end, start;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  start = (new Intl.DateTimeFormat('en-GB', ({ 'hour': '2-digit','minute': '2-digit','timeZone': 'Europe/London' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cc879e88f50739ef441979fa379ba22c'), 'start'))));
  end = (new Intl.DateTimeFormat('en-GB', ({ 'hour': '2-digit','minute': '2-digit','timeZone': 'Europe/London' })).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cc879e88f50739ef441979fa379ba22c'), 'end'))));

  return ([start,'-',end].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheet_approval/components/d9442cb873da486b89eae5c61791ecaa/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cc879e88f50739ef441979fa379ba22c'), 'notes')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cc879e88f50739ef441979fa379ba22c'), 'notesLocum')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheet_approval/components/27575cd92583f4d6085401ef53e8aa9a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var sessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sessions = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055'), 'sessions'));
  if (sessions) {
    return (['Sessions (',sessions.length,')'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheet_approval/components/70e397245a67d577e0ab1e0c1cd89bef/bundle.js', [], () => ({
  /* content */
  /* handler:onSaveSignatureClick */
  async ['onSaveSignatureClick'](___arguments) {
    var error, response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function readBrowserFileAs(file, type) {
   const readers = { base64: "readAsDataURL", utf8: "readAsText", bytes: "readAsArrayBuffer" }

   return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader[readers[type]](file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}


  console.log(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055'), 'approverName'));
  console.log(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055'), 'approverRole'));
  try {
    response = (await Backendless.Request.post(`${Backendless.appPath}/services/timesheets/addSignature`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'timesheetId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055'), 'timesheetId')),'approverSignature': (await readBrowserFileAs(___arguments['signature'], 'base64')),'approverName': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055'), 'approverName')),'approverRole': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055'), 'approverRole')) }));
    (function (message) { alert(message) })('🎉 Timesheet approved!');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loading', ({ 'redirect': 'timesheets' }));

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSaveSignatureClick */
  /* content */
}))

define('./pages/timesheet_details/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var timesheet, agency, client, locum, loadedTimesheet, currentOrg, j, sessions, orgType;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function preparePage() {
  ___arguments.context.pageData['outsideUK'] = ((await (async function() {
  	return Intl.DateTimeFormat().resolvedOptions().timeZone;
  })()) != 'Europe/London');
  ___arguments.context.pageData['removeIds'] = [];
  ___arguments.context.pageData['showCheckboxes'] = false;
  ___arguments.context.pageData['safetyOn'] = true;
  console.log('---> Check if New, Edit or View timesheet');
  if (!timesheet) {
    await setupForNew();
  } else {
    await prepareExistingSessionData();
    if ((getObjectProperty(timesheet, 'status')) == 'INVOICED') {
      await setupForInvoiced();
    } else if ((getObjectProperty(timesheet, 'status')) == 'CLIENT APPROVED') {
      await setupForSigned();
    } else if ((getObjectProperty(timesheet, 'status')) == 'REJECTED') {
      await setupForRejected();
    } else if ((getObjectProperty(timesheet, 'status')) == 'VOID') {
      await setupForVoided();
    } else {
      await setupForDraft();
    }
  }
}

/**
 * Describe this function...
 */
async function prepareDropdowns() {
  locum = (getObjectProperty(timesheet, 'locum'));
  if (locum) {
    (getObjectProperty(___arguments.context.pageData, 'ui'))['autoCompleteLocum'] = [({ [`value`]: (getObjectProperty(locum, 'objectId')),[`label`]: [(getObjectProperty(locum, 'name')),' (',(getObjectProperty(locum, 'prn')),')'].join('') })];
    console.log(___arguments.context.pageData);
  }
  client = (getObjectProperty(timesheet, 'client'));
  if (client) {
    Object.assign((getObjectProperty(___arguments.context.pageData, 'ui')), ({ [`autoCompleteClient`]: [({ [`value`]: (getObjectProperty(client, 'objectId')),[`label`]: [(getObjectProperty(client, 'companyName')),' (',(getObjectProperty(client, 'crn')),')'].join('') })] }));
  }
  agency = (getObjectProperty(timesheet, 'agency'));
  if (agency) {
    Object.assign((getObjectProperty(___arguments.context.pageData, 'ui')), ({ [`autoCompleteAgency`]: [({ [`value`]: (getObjectProperty(agency, 'objectId')),[`label`]: [(getObjectProperty(agency, 'companyName')),' (',(getObjectProperty(agency, 'crn')),')'].join('') })] }));
  }
}

/**
 * Describe this function...
 */
async function loadTimesheet() {
  loadedTimesheet = (await Backendless.Request.get(`${Backendless.appPath}/services/TIMESHEETS/`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'orgId': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentOrg')), 'objectId'))),'timesheetIds': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'timesheet')), 'objectId'))) }).send());
  console.log('===> loaded Timesheet');
  ___arguments.context.pageData['timesheet'] = loadedTimesheet;
  console.log(___arguments.context.pageData);
}

/**
 * Describe this function...
 */
async function setupForDraft() {
  ___arguments.context.pageData['formTitle'] = 'Timesheet2';
  console.log('Allow Edit Button if TS is owned by User\'s Org');
  if ((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) == (getObjectProperty(timesheet, 'ownerOrgId'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2eac8098ccf8fb71d7e8ee54fe984ef9', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('382d3a6d4ab62d0ba43890eacb820986', true);
  }
  ___arguments.context.pageData['readOnlyMode'] = true;
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * Describe this function...
 */
async function setupForNew() {
  ___arguments.context.pageData['formTitle'] = 'New Timesheet';
  timesheet = ({ [`sessions`]: [({ [`rowId`]: (generateUUID().short()),[`date`]: ((new Date()).valueOf()),[`start`]: (await (async function() {
  	    // Get today's date
  	    const today = new Date();

  	    // Set time to 9am
  	    today.setHours(9, 0, 0, 0);

  	    // Return the timestamp
  	    return today.getTime();
  })()),[`end`]: (await (async function() {
  	    // Get today's date
  	    const today = new Date();

  	    // Set time to 9am
  	    today.setHours(11, 0, 0, 0);

  	    // Return the timestamp
  	    return today.getTime();
  })()),[`duration`]: 2,[`rateLocum`]: 85,[`extraLocum`]: null,[`notesLocum`]: null })] });
  timesheet['submitId'] = (generateUUID().short());
  currentOrg = (getObjectProperty(___arguments.context.appData, 'currentOrg'));
  if ((getObjectProperty(currentOrg, '___class')) == 'locums') {
    timesheet['locum'] = currentOrg;
  } else if ((getObjectProperty(currentOrg, '___class')) == 'agencies') {
    timesheet['agency'] = currentOrg;
  } else {
    timesheet['client'] = currentOrg;
  }
  // Set the default date, start & end time
  ___arguments.context.pageData['timesheet'] = timesheet;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d3cd7d491bf1f4d999794dab98b47579', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3f1ae12410c2d3823e86320701730116', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('073644169f514b65704bcd8a0221e6c2', true);
}

/**
 * Describe this function...
 */
async function prepareExistingSessionData() {
  sessions = (getObjectProperty(timesheet, 'sessions'));
  for (var j_index in sessions) {
    j = sessions[j_index];
    j['rowId'] = (generateUUID().short());
  }
}

/**
 * Describe this function...
 */
async function setupForRejected() {
  ___arguments.context.pageData['formTitle'] = 'Timesheet';
  ___arguments.context.pageData['formInfoText'] = 'If you feel this is an error, please contact support@healthcare-payment-service.com. Alternatively, delete this timesheet and submit a new one for approval.';
  if ((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) == (getObjectProperty(timesheet, 'ownerOrgId'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('73c54084270656a5a00c93c2659f6695', true);
  }
  ___arguments.context.pageData['readOnlyMode'] = true;
}

/**
 * Describe this function...
 */
async function orgSpecificVisibility() {
  orgType = (getObjectProperty(___arguments.context.appData, 'currentOrg.___class'));
  if (orgType == 'agencies') {
    ___arguments.context.pageData['isAgency'] = true;
  } else {
    ___arguments.context.pageData['isLocum'] = true;
  }
}

/**
 * Describe this function...
 */
async function setupForSigned() {
  ___arguments.context.pageData['formTitle'] = 'Timesheet';
  ___arguments.context.pageData['formInfoText'] = (['Below sessions will be included in your next Invoice to this client. Invoices are issued every Friday (Next: ',((await (async function(timestamp) {
  	    // Convert the timestamp to a Date object
  	    const dateObj = new Date(timestamp);

  	    // Calculate the number of days to the next Friday
  	    let daysToFriday = (5 - dateObj.getUTCDay() + 7) % 7;
  	    if (daysToFriday === 0) {
  	        daysToFriday = 7;  // If today is Friday, then add 7 days to get the next Friday
  	    }

  	    // Add the days to the date object
  	    dateObj.setUTCDate(dateObj.getUTCDate() + daysToFriday);

  	    return dateObj;
  })((new Date()))).toDateString()),').'].join(''));
  if ((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) == (getObjectProperty(timesheet, 'ownerOrgId'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2eac8098ccf8fb71d7e8ee54fe984ef9', true);
  }
  ___arguments.context.pageData['readOnlyMode'] = true;
}

/**
 * Describe this function...
 */
async function setupForInvoiced() {
  ___arguments.context.pageData['formTitle'] = 'Timesheet';
  ___arguments.context.pageData['formInfoText'] = 'Refer to "Invoices" to see payment status.';
  ___arguments.context.pageData['readOnlyMode'] = true;
}

/**
 * Describe this function...
 */
async function setupForVoided() {
  ___arguments.context.pageData['formTitle'] = 'Timesheet';
  ___arguments.context.pageData['formInfoText'] = 'If you feel this is an error, please contact support@healthcare-payment-service.com. Alternatively, delete this timesheet and submit a new one for approval.';
  ___arguments.context.pageData['readOnlyMode'] = true;
}


  console.log('\\/ ======== [TIMESHEET] ======== \\/');
  if (!(getObjectProperty(___arguments.context.pageData, 'isNew')) && !(getObjectProperty(___arguments.context.pageData, 'timesheet'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('timesheets', undefined);
  } else {
    console.log('===> [Timesheet], Page Data:');
    console.log(___arguments.context.pageData);
    timesheet = (getObjectProperty(___arguments.context.pageData, 'timesheet'));
    ___arguments.context.pageData['ui'] = ({ [`autoCompleteLocum`]: [],[`autoCompleteClient`]: [],[`autoCompleteAgency`]: [] });
    await preparePage();
    await prepareDropdowns();
    await orgSpecificVisibility();
  }
  console.log('/\\ ======== [TIMESHEET] ======== /\\');

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/timesheet_details/components/307fdce79ac4b2955cd240fa919c129a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'formInfoText'))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'formInfoText'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheet_details/components/d3cd7d491bf1f4d999794dab98b47579/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var newTimesheet, sessions, timesheet, error, response, errorMsg;


  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).submit() })('1c8e70dccf514475898fd4cc73a48055');

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055'), 'sessions')) {
    return (['Submit ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055'), 'sessions')).length,' session(s)'].join(''));
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/timesheet_details/components/adabc1d52f8401683a2e523cc0fed1af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var timesheet, sessions, error, response, x;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function prepareData() {
  sessions = (await (async function(sessions) {
  	    // Deep copy the sessions array
  	    const copiedSessions = JSON.parse(JSON.stringify(sessions));

  	    return copiedSessions;
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055'), 'sessions'))));
  timesheet = ___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055');
  console.log('---> SUBMITTED TIMESHEET');
  console.log(timesheet);
  return timesheet
}

/**
 * Describe this function...
 */
async function updateTimesheet() {
  try {
    response = (await Backendless.Request.put(`${Backendless.appPath}/services/TIMESHEETS/`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'timesheets': [await prepareData()],'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) }));
    if ((getObjectProperty(response, 'failed')).length > 0) {
      throw (new Error((getObjectProperty(((getObjectProperty(response, 'failed'))[0]), 'SERVER_MSG'))))
    }
    (function (message) { alert(message) })('🎉 Timesheet updated!');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loading', ({ [`redirect`]: 'timesheets' }));

  } catch (error) {
    (function (message) { alert(message) })(error);

  }
}


  ___arguments.context.pageData['readOnlyMode'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('adabc1d52f8401683a2e523cc0fed1af', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('73c54084270656a5a00c93c2659f6695', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a43d366542aef3ed95efebf2679a90b', true);
  await updateTimesheet();
  await new Promise(r => setTimeout(r, 1000 || 0));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a43d366542aef3ed95efebf2679a90b', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('73c54084270656a5a00c93c2659f6695', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('adabc1d52f8401683a2e523cc0fed1af', true);
  ___arguments.context.pageData['readOnlyMode'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheet_details/components/73c54084270656a5a00c93c2659f6695/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var error, response;


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('73c54084270656a5a00c93c2659f6695', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('adabc1d52f8401683a2e523cc0fed1af', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60da2c0324710951120720fd601e35dc', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheet_details/components/2eac8098ccf8fb71d7e8ee54fe984ef9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2eac8098ccf8fb71d7e8ee54fe984ef9', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('382d3a6d4ab62d0ba43890eacb820986', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a43d366542aef3ed95efebf2679a90b', true);
  await new Promise(r => setTimeout(r, 500 || 0));
  ___arguments.context.pageData['readOnlyMode'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a43d366542aef3ed95efebf2679a90b', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('adabc1d52f8401683a2e523cc0fed1af', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('73c54084270656a5a00c93c2659f6695', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheet_details/components/020197de7c89dbfbf4b7742c03611e19/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function delete2() {
  try {
    response = (await Backendless.Request.delete(`${Backendless.appPath}/services/TIMESHEETS/`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'timesheets': [({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055'), 'objectId')) })],'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) }));
    (function (message) { alert(message) })('Timesheet deleted.');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loading', ({ [`redirect`]: 'timesheets' }));

  } catch (error) {
    await errorHandling(error);

  }
}

/**
 * Describe this function...
 */
async function errorHandling(error) {
  if ((getObjectProperty(error, 'code')) == 31) {
    (function (message) { alert(message) })(error);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loading', ({ [`redirect`]: 'timesheets' }));
  } else {
    (function (message) { alert(message) })(error);
  }
}


  ___arguments.context.pageData['readOnlyMode'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60da2c0324710951120720fd601e35dc', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('adabc1d52f8401683a2e523cc0fed1af', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a43d366542aef3ed95efebf2679a90b', true);
  await delete2();
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a43d366542aef3ed95efebf2679a90b', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60da2c0324710951120720fd601e35dc', true);
  ___arguments.context.pageData['safetyOn'] = true;
  ___arguments.context.pageData['readOnlyMode'] = false;

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'safetyOn'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/timesheet_details/components/9a7831ea639c28ff07f93ca552dd6f3d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['safetyOn'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60da2c0324710951120720fd601e35dc', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('73c54084270656a5a00c93c2659f6695', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('adabc1d52f8401683a2e523cc0fed1af', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheet_details/components/5ca9219c7343bc869646c9caee3de376/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['safetyOn'] = (!(getObjectProperty(___arguments.context.pageData, 'safetyOn')));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheet_details/components/382d3a6d4ab62d0ba43890eacb820986/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('timesheet_approval', ({ 'timesheet': ___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055') }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheet_details/components/1c8e70dccf514475898fd4cc73a48055/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var timesheet, sessions, error, response, errorMsg;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function prepareData() {
  sessions = (await (async function(sessions) {
  	    // Deep copy the sessions array
  	    const copiedSessions = JSON.parse(JSON.stringify(sessions));

  	    return copiedSessions;
  })((getObjectProperty(___arguments.context.dataModel, 'sessions'))));
  timesheet = ___arguments.context.dataModel;
  console.log('---> SUBMITTED TIMESHEET');
  console.log(timesheet);
  return timesheet
}

/**
 * Describe this function...
 */
async function submitTimesheet(timesheet) {
  try {
    console.log('---> ORGID');
    console.log(getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'));
    response = (await Backendless.Request.post(`${Backendless.appPath}/services/test/`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'timesheets': [timesheet],'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) }));
    console.log('---> SERVER RESPONSE');
    console.log(response);
    if ((getObjectProperty(response, 'failed')).length > 0) {
      throw (new Error((getObjectProperty(((getObjectProperty(response, 'failed'))[0]), 'SERVER_MSG'))))
    }
    console.log('---> saveForLater');
    console.log(getObjectProperty(___arguments.context.pageData, 'saveForLater'));
    if (getObjectProperty(___arguments.context.pageData, 'saveForLater')) {
      (function (message) { alert(message) })('🎉 Timesheet saved!');
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loading', ({ [`redirect`]: 'timesheets' }));
      ___arguments.context.pageData['saveForLater'] = false;
    } else {
      (function (message) { alert(message) })('🎉 Timesheet submitted!');
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('timesheet_approval', ({ [`timesheet`]: response }));
    }

  } catch (error) {
    ___arguments.context.pageData['readOnlyMode'] = false;
    await handleErrors(error);

  }
}

/**
 * Describe this function...
 */
async function handleErrors(error) {
  errorMsg = (getObjectProperty(error, 'message'));
  if (errorMsg.indexOf('rateLocum') + 1 > 0) {
    error['message'] = 'Some session(s) are missing "Cost". Please amend and resubmit.';
  } else if (errorMsg.indexOf('sessionHash') + 1 > 0) {
    error['message'] = 'Some session(s) have been submitted before. Please amend and resubmit.';
  }
  (function (message) { alert(message) })(error);
}


  ___arguments.context.pageData['readOnlyMode'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d3cd7d491bf1f4d999794dab98b47579', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3f1ae12410c2d3823e86320701730116', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a43d366542aef3ed95efebf2679a90b', true);
  timesheet = await prepareData();
  await submitTimesheet(timesheet);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a43d366542aef3ed95efebf2679a90b', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3f1ae12410c2d3823e86320701730116', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d3cd7d491bf1f4d999794dab98b47579', true);
  ___arguments.context.pageData['readOnlyMode'] = false;

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/timesheet_details/components/3f1ae12410c2d3823e86320701730116/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['saveForLater'] = true;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).submit() })('1c8e70dccf514475898fd4cc73a48055');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheet_details/components/f569d2455eab11c4a5ac3253f89a1b2d/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('1c8e70dccf514475898fd4cc73a48055')

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/timesheets/components/6bad4b3df9b7b01ac0b37505c0cb9029/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('timesheet_details', ({ [`isNew`]: true }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var timesheets;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('\\/ ======== [TIMESHEETS] ======== \\/');
  if ((getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'locums') {
    ___arguments.context.pageData['viewMode'] = 'list';
  } else {
    ___arguments.context.pageData['viewMode'] = 'table';
  }
  timesheets = (await Backendless.Request.get(`${Backendless.appPath}/services/TIMESHEETS/`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'orgId': JSON.stringify((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))) }).send());
  ___arguments.context.appData['timesheets'] = timesheets;
  ___arguments.context.pageData['timesheets'] = (getObjectProperty(___arguments.context.appData, 'timesheets'));
  console.log('Page data:');
  console.log(___arguments.context.pageData);
  console.log('/\\ ======== [TIMESHEETS] ======== /\\');

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/timesheets/components/8b66c495e3fbe7648e43c3061daeb076/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.dataModel, 'timesheets'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/timesheets/components/cf12aca31a1dd6b4297987ecfe61125a/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  async ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await (async function(timesheets, statusOrder) {
	    if (!Array.isArray(timesheets)) {
	        // console.error('The provided input is not a valid array of timesheets');
	        console.log('No valid timesheet array provided');
	        return [];
	    }

	    if (!Array.isArray(statusOrder)) {
	        console.error('The provided statusOrder is not a valid array');
	        return [];
	    }

	    // First, group timesheets by their status.
	    const groupedByStatus = timesheets.reduce((acc, timesheet) => {
	        const status = timesheet.status;
	        if (!acc[status]) {
	            acc[status] = [];
	        }
	        acc[status].push(timesheet);
	        return acc;
	    }, {});

	    // Then, transform this object into an array of objects.
	    const groupedTimesheets = Object.keys(groupedByStatus).map(status => ({
	        status,
	        timesheets: groupedByStatus[status]
	    }));

	    // Sort the groupedTimesheets array by the custom order provided in the statusOrder array.
	    groupedTimesheets.sort((a, b) => {
	        const indexA = statusOrder.indexOf(a.status);
	        const indexB = statusOrder.indexOf(b.status);
	        return indexA - indexB;
	    });

	    // Sort the timesheets within each status group by the 'created' timestamp in ascending order.
	    for (const group of groupedTimesheets) {
	        group.timesheets.sort((a, b) => a.created - b.created);
	    }

	    return groupedTimesheets;
})((getObjectProperty(___arguments.context.pageData, 'timesheets')), ['REJECTED', 'DRAFT', 'SIGNED', 'INVOICED', 'VOID']))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/timesheets/components/15e3de48778f330892b827bc55629966/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('25b31b1d4d8669bae5c5b4382dfb0da8', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4be471660b813a1a861562c001995b5', false);
  await new Promise(r => setTimeout(r, 50 || 0));
  console.log('Items to pass on:');
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('timesheet_details', ({ [`timesheet`]: ___arguments.context.dataModel }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets/components/339b0c58d62e41638db1f1e57db6c3b5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'status')) == 'VOID') {
    return 'VOIDED (last 30 days)';
  } else if ((getObjectProperty(___arguments.context.dataModel, 'status')) == 'INVOICED') {
    return 'INVOICED (last 30 days)';
  } else {
    return (getObjectProperty(___arguments.context.dataModel, 'status'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets/components/f477a274a119fd9cf30fb573cec7ee2f/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('24d59db54a52991ea9681a4a3c513817'), 'status')) == 'REJECTED') {
    return 'error';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('24d59db54a52991ea9681a4a3c513817'), 'status')) == 'DRAFT') {
    return 'sticky note 2';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('24d59db54a52991ea9681a4a3c513817'), 'status')) == 'INVOICED') {
    return 'mark email read';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('24d59db54a52991ea9681a4a3c513817'), 'status')) == 'VOID') {
    return 'delete forever';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('24d59db54a52991ea9681a4a3c513817'), 'status')) == 'SIGNED') {
    return 'tag faces';
  }

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/timesheets/components/65fac655eea6b87c1da6174205047c81/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Last updated: ') + String((await BackendlessUI.Functions.Custom['fn_6982ce25f4e59172a2474f4a2811c67c']((getObjectProperty(___arguments.context.appData, 'lastUpdated'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets/components/71d61a1af4e1384e29a14cee2a7c3ca2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loading', ({ 'redirect': ((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets/components/6b310926ee53c43bbcf759a4a1bc53f8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['viewMode'] = 'list';

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'table')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/2a67184a2fae00e813b99bb31ead3498/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'list')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['viewMode'] = 'table';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets/components/1d6646ef4e811d615c49b58ffeb51a9c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'table')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/11e6fcd89e0225f3fe5ffbf5601c7bb8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'list')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/9553321636d4ccf5b7f3962b21e3f268/bundle.js', [], () => ({
  /* content */
  /* handler:columnDefsLogic */
  ['columnDefsLogic'](___arguments) {
    var columns;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.appData, 'currentOrg'))) {
    return ;
  }
  columns = [({ [`field`]: 'status',[`flex`]: 20,[`maxWidth`]: 80 }), ({ [`field`]: 'createdByAgency',[`flex`]: 13,[`maxWidth`]: 155 }), ({ [`field`]: 'agency',[`flex`]: 20 }), ({ [`field`]: 'client',[`flex`]: 20 }), ({ [`field`]: 'locum',[`flex`]: 20 }), ({ [`field`]: '£',[`flex`]: 20,[`maxWidth`]: 80 }), ({ [`field`]: 'sessions',[`flex`]: 10,[`maxWidth`]: 100 }), ({ [`field`]: 'hours',[`flex`]: 10,[`maxWidth`]: 80 }), ({ [`field`]: 'timesheetFilename',[`flex`]: 10 }), ({ [`field`]: 'submitId',[`flex`]: 1,[`maxWidth`]: 0 }), ({ [`field`]: 'objectId',[`flex`]: 1,[`maxWidth`]: 0 })];
  if ((getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'agencies') {
    columns.splice(6, 0, ({ [`field`]: 'fee',[`flex`]: 20,[`maxWidth`]: 80 }));
  }

  return columns

  },
  /* handler:columnDefsLogic */
  /* handler:rowsDataLogic */
  async ['rowsDataLogic'](___arguments) {
    var rows, rowData, fee, j, hours, sessions, amount, session, timesheets;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  timesheets = (getObjectProperty(___arguments.context.pageData, 'timesheets'));
  if (!timesheets) {
    return ;
  }
  rows = [];
  for (var j_index in timesheets) {
    j = timesheets[j_index];
    amount = null;
    hours = null;
    sessions = (getObjectProperty(j, 'sessions'));
    for (var session_index in sessions) {
      session = sessions[session_index];
      hours = hours + (getObjectProperty(session, 'duration'));
    }
    amount = (await BackendlessUI.Functions.Custom['fn_8277f9b1052729382e74263567a5484b'](sessions));
    fee = (await BackendlessUI.Functions.Custom['fn_b9cb147c2b12b97283a200f7517fb5af'](sessions));
    rowData = ({ [`agency`]: (getObjectProperty(j, 'agency.companyName')),[`client`]: (getObjectProperty(j, 'client.companyName')),[`locum`]: (getObjectProperty(j, 'locum.name')),[`timesheetFilename`]: (getObjectProperty(j, 'timesheetFilename')),[`createdByAgency`]: (getObjectProperty(j, 'createdByAgency')) ? '✔️' : '',[`submitId`]: (getObjectProperty(j, 'submitId')),[`status`]: (getObjectProperty(j, 'status')),[`£`]: String('£') + String(amount),[`sessions`]: sessions.length,[`hours`]: hours,[`objectId`]: (getObjectProperty(j, 'objectId')) });
    if ((getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'agencies') {
      rowData['fee'] = (String('£') + String(fee));
    }
    addItemToList(rows, rowData);
  }

  return rows

  },
  /* handler:rowsDataLogic */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('timesheet_details', ({ [`timesheet`]: (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'timesheets')), async (item) => {
     console.log(getObjectProperty(item, 'objectId'));


   return ((getObjectProperty(___arguments['cellParams'], 'data.objectId')) == (getObjectProperty(item, 'objectId')));
  }))[0] }));

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/timesheets/components/574bf0835dbd1c12e378a3d9fbf683eb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Created: ') + String((await BackendlessUI.Functions.Custom['fn_6982ce25f4e59172a2474f4a2811c67c']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('15e3de48778f330892b827bc55629966'), 'created'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets/components/9f28cb054d1529e7d576f16eb3a295da/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var sessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sessions = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('15e3de48778f330892b827bc55629966'), 'sessions'));
  if (sessions) {
    return (['£',(await BackendlessUI.Functions.Custom['fn_8277f9b1052729382e74263567a5484b'](sessions)),' (',sessions.length,' sessions)'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets/components/198116d637e843f6de178e7d5339a4dc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var sessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sessions = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('15e3de48778f330892b827bc55629966'), 'sessions'));
  if (sessions) {
    return (['+ £',(await BackendlessUI.Functions.Custom['fn_b9cb147c2b12b97283a200f7517fb5af'](sessions)),' (agency fee)'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.appData, 'currentOrg')) {
    if ((getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'agencies') {
      return true;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/ba0ee8c7ddbd981d29d710b9092b52ce/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item, timesheets, j, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'timesheets')), (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'timesheets')), async (item) => {


     return ((getObjectProperty(item, 'status')) == 'VOID');
    })));
  } else {
    ___arguments.context.pageData['timesheets'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'timesheets')), async (item) => {


     return ((getObjectProperty(item, 'status')) != 'VOID');
    }));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets/components/6944689ae725bcd1e04d50ef4367354d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'timesheets')), (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'timesheets')), async (item) => {


     return ((getObjectProperty(item, 'status')) == 'INVOICED');
    })));
  } else {
    ___arguments.context.pageData['timesheets'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'timesheets')), async (item) => {


     return ((getObjectProperty(item, 'status')) != 'INVOICED');
    }));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets/components/1bfa78bf4b3ad38cac78970d45b4924d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('15e3de48778f330892b827bc55629966'), 'createdByAgency'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_71fa64de931d3e483a491b9aa66d53ba/logic/836e9b6827641c933fed7249ac2affc6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
      return (String('Managed by ') + String(___arguments.context.rootComponentData))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/1d153c2297362d55df8959c7e49890e6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'outsideUK'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (['You are in timezone "',(await (async function() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
})()),'", only the UK time will be submitted.'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/d611ff96c7e88537ee48225eb019e4a9/bundle.js', [], () => ({
  /* content */
  /* handler:disabledLogic */
  ['disabledLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'locums' || !(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:disabledLogic */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var result;


  result = (await BackendlessUI.Functions.Custom['fn_3a6150753c7cfa5c62852c7b6e221eaf'](___arguments['inputValue'], 'locums', 'ts-autocomplete-locum', ___arguments.context.pageData));
  if (result) {
    await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.rootComponentData, 'locum.objectId', result);
  }

  },
  /* handler:onChange */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
      ___arguments.context.rootComponentData['locum'] = null;

  },
  /* handler:onButtonClearClick */
  /* handler:placeholderLogic */
  ['placeholderLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.rootComponentData, 'locum.objectId')) {
    return 'Locum';
  } else {
    return '-- Select Locum --';
  }

  },
  /* handler:placeholderLogic */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, '___ui.ts-autocomplete-locum'))

  },
  /* handler:optionsLogic */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/9ee4a7afe938921fc4bc597582f95118/bundle.js', [], () => ({
  /* content */
  /* handler:disabledLogic */
  ['disabledLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'clients' || !(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:disabledLogic */
  /* handler:placeholderLogic */
  ['placeholderLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.rootComponentData, 'client.objectId')) {
    return 'Client';
  } else {
    return '-- Select Client --';
  }

  },
  /* handler:placeholderLogic */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
      ___arguments.context.rootComponentData['client'] = null;

  },
  /* handler:onButtonClearClick */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var result;


  result = (await BackendlessUI.Functions.Custom['fn_3a6150753c7cfa5c62852c7b6e221eaf'](___arguments['inputValue'], 'clients', 'ts-autocomplete-client', ___arguments.context.pageData));
  if (result) {
    await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.rootComponentData, 'client.objectId', result);
  }

  },
  /* handler:onChange */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, '___ui.ts-autocomplete-client'))

  },
  /* handler:optionsLogic */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/b0044e13f62e2a92ad8ebc46dbaea371/bundle.js', [], () => ({
  /* content */
  /* handler:disabledLogic */
  ['disabledLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'agencies' || !(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:disabledLogic */
  /* handler:placeholderLogic */
  ['placeholderLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.rootComponentData, 'agency.objectId')) {
    return 'Agency';
  } else {
    return '-- Select Agency (optional) --';
  }

  },
  /* handler:placeholderLogic */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
      ___arguments.context.rootComponentData['agency'] = null;

  },
  /* handler:onButtonClearClick */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var result;


  result = (await BackendlessUI.Functions.Custom['fn_3a6150753c7cfa5c62852c7b6e221eaf'](___arguments['inputValue'], 'agencies', 'ts-autocomplete-agency', ___arguments.context.pageData));
  if (result) {
    await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.rootComponentData, 'agency.objectId', result);
  }

  },
  /* handler:onChange */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, '___ui.ts-autocomplete-agency'))

  },
  /* handler:optionsLogic */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/7be21bb32e2414377e7f73ecc08247aa/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/9c53860ff726eb3b2016398d10f10e8d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/27575cd92583f4d6085401ef53e8aa9a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var sessionCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sessionCount = (getObjectProperty(___arguments.context.rootComponentData, 'sessions'));
  if (sessionCount) {
    if (sessionCount.length == 1) {
      return (String(sessionCount.length) + String(' Session:'));
    } else {
      return (String(sessionCount.length) + String(' Sessions:'));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/b76303f939f4af052e533c9f5cde5029/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'showCheckboxes'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/0a5425eb6ecebf11c582e30952582ac7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var id, currentItemId, removeIdsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  removeIdsList = (getObjectProperty(___arguments.context.pageData, 'removeIds'));
  currentItemId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'rowId'));
  if (___arguments.value) {
    addItemToList(removeIdsList, currentItemId);
  } else {
    ___arguments.context.pageData['removeIds'] = (await asyncListFilter(removeIdsList, async (id) => {


     return (currentItemId != id);
    }));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/53be2bf4628fbf758581d1c643d324eb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    
  },
  /* handler:onChange */
  /* handler:onMaxDateAssignment */
  ['onMaxDateAssignment'](___arguments) {
      return (new Date())

  },
  /* handler:onMaxDateAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/7065efa66a630f56dcd15a936c928e0b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await (async function(timestamp) {
	    // If timestamp is null or undefined, return false
	    if (!timestamp) return false;

	    // Convert the timestamp to the user's local time
	    const localDate = new Date(timestamp);

	    // Extract the hour
	    const localHour = localDate.getHours();

	    // Check if the hour is between 1am and 6am
	    return localHour >= 1 && localHour < 6;
})((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'start'))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/96f98a359afad7c84f417f96574ccb03/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'outsideUK'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/d4a904e9fc75edafb0c34e2c73144d56/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'start')) {
    return (String(new Intl.DateTimeFormat(undefined, ({ 'hour': '2-digit','minute': '2-digit','timeZone': 'Europe/London' })).format((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'start')))))) + String(' (UK)'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/93883bda44af4c8157bda9077f2edf29/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['end'] = (___arguments.value + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'duration')) * 3600000);

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/6a4da03a37232abf0ddba8e8b50c5909/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'duration')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/9456f7f4076fad33a4ea4365dfac006d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await (async function(timestamp) {
	    // If timestamp is null or undefined, return false
	    if (!timestamp) return false;

	    // Convert the timestamp to the user's local time
	    const localDate = new Date(timestamp);

	    // Extract the hour
	    const localHour = localDate.getHours();

	    // Check if the hour is between 1am and 6am
	    return localHour >= 1 && localHour < 6;
})((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'end'))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/cf3edcf8f053163da74d4b3eea544793/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'outsideUK'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/0bd6b99f8f0fa04ae3228be32bde0728/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'end')) {
    return (String(new Intl.DateTimeFormat(undefined, ({ 'hour': '2-digit','minute': '2-digit','timeZone': 'Europe/London' })).format((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'end')))))) + String(' (UK)'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/d079f1d32f535b9dd951cd7d93a3aae3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['duration'] = (await BackendlessUI.Functions.Custom['fn_f72a166723522cd1028ba41fe8aef554']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'start')), ___arguments.value, null));
  ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['end'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'start')) + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'duration')) * 3600000);

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/9be29ac81e1e46412c8175cff354f1b7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'locums') {
    return 'Your note (visible to all)';
  } else {
    return 'Locum\'s note (visible to all)';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/6b4772dbcb21b24d8f18174ab9ed84dc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('8b9516de7c48af5528216d0ced0385f2'), 'rateLocum')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/0c4a8e441fbf22f575a65f63dac3986e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onBlur */
  ['onBlur'](___arguments) {
    var num;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  num = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8b9516de7c48af5528216d0ced0385f2'), 'rateLocum'));
  if (num) {
    ___arguments.context.getComponentDataStoreByUid('8b9516de7c48af5528216d0ced0385f2')['rateLocum'] = (Math.round(num * 100) / 100);
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/b476e744ae710039de3d034fb8490ca2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onBlur */
  ['onBlur'](___arguments) {
    var num;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  num = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8b9516de7c48af5528216d0ced0385f2'), 'extraLocum'));
  if (num) {
    ___arguments.context.getComponentDataStoreByUid('8b9516de7c48af5528216d0ced0385f2')['extraLocum'] = (Math.round(num * 100) / 100);
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/c41a124249daa5bb71a9eb994119b127/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'agencies' || (getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'admin')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/81cd464585aab8bf369679c4c1f9dd6b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/7bcc85a351151881641a8e47f1e41d69/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onBlur */
  ['onBlur'](___arguments) {
    var num;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  num = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c41a124249daa5bb71a9eb994119b127'), 'rateAgency'));
  if (num) {
    ___arguments.context.getComponentDataStoreByUid('c41a124249daa5bb71a9eb994119b127')['rateAgency'] = (Math.round(num * 100) / 100);
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/13e701968407de6123c28fbf28a80c25/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onBlur */
  ['onBlur'](___arguments) {
    var num;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  num = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c41a124249daa5bb71a9eb994119b127'), 'extraAgency'));
  if (num) {
    ___arguments.context.getComponentDataStoreByUid('c41a124249daa5bb71a9eb994119b127')['extraAgency'] = (Math.round(num * 100) / 100);
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/b95da8b9ce76b3b228be1617c725037b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}


  ___arguments.context.pageData['showCheckboxes'] = (!(getObjectProperty(___arguments.context.pageData, 'showCheckboxes')));
  if (!(getObjectProperty(((getObjectProperty(___arguments.context.rootComponentData, 'sessions'))[0]), 'rowId'))) {
    ___arguments.context.pageData['removeIds'] = [];
    var j_list = (getObjectProperty(___arguments.context.rootComponentData, 'sessions'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['rowId'] = (generateUUID().short());
    }
  }
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/939af8e68a67aee9c5fb429b4a99ea25/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'removeIds')) {
    return ((getObjectProperty(___arguments.context.pageData, 'removeIds')).length);
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, removeIds;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  removeIds = (getObjectProperty(___arguments.context.pageData, 'removeIds'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b246f2c7682ece05669f4814a49cc5a7', (await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b246f2c7682ece05669f4814a49cc5a7')), async (item) => {


   return (!(removeIds.includes((getObjectProperty(item, 'rowId')))));
  })));
  ___arguments.context.pageData['removeIds'] = [];
  ___arguments.context.pageData['showCheckboxes'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/208f58528729070844d172b60d95383a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var sessions, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  sessions = (getObjectProperty(___arguments.context.rootComponentData, 'sessions'));
  sessions = (await asyncListSort(sessions, 1, async (item) => {


   return (getObjectProperty(item, 'start'));
  }));
  ___arguments.context.rootComponentData['sessions'] = sessions;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'showCheckboxes')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/2bf665866fb3e5a5d3cb58bd8159e305/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newSession, mySessions;

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  mySessions = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b246f2c7682ece05669f4814a49cc5a7'));
  if (mySessions.length == 0) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b246f2c7682ece05669f4814a49cc5a7', [({ 'rowId': (generateUUID().short()),'date': ((new Date()).valueOf()),'start': (await (async function() {
    	    // Get today's date
    	    const today = new Date();

    	    // Set time to 9am
    	    today.setHours(9, 0, 0, 0);

    	    // Return the timestamp
    	    return today.getTime();
    })()),'end': (await (async function() {
    	    // Get today's date
    	    const today = new Date();

    	    // Set time to 9am
    	    today.setHours(11, 0, 0, 0);

    	    // Return the timestamp
    	    return today.getTime();
    })()),'duration': 2,'rateLocum': 85,'extraLocum': null,'notesLocum': null })]);
  } else {
    newSession = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c']((mySessions.slice(-1)[0])));
    newSession['rowId'] = (generateUUID().short());
    delete newSession['objectId'];
    addItemToList(mySessions, newSession);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/f1a8d3939cab14e6135f8f6d5a68f33f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var agencyFeeText, totalCostLocum, totalDuration, totalCostAgency, costAgency, costLocum, duration, j, extra, rate, durationHrs, x;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function calculateCost(duration, rate, extra) {
  return (await (async function(duration, rate, extra) {
	/** Code works, but duration was still in Hours here **/

	// // Validate and substitute non-numeric values with 0
	// duration = (typeof duration === 'number' && !isNaN(duration)) ? duration: 0;
	// rate = (typeof rate === 'number' && !isNaN(rate)) ? rate: 0;
	// extra = (typeof extra === 'number' && !isNaN(extra)) ? extra: 0;

	// // Perform the calculation
	// return (rate * duration) + extra


	// Validate and substitute non-numeric values with 0
	duration = (typeof duration === 'number' && !isNaN(duration)) ? duration: 0;
	rate = (typeof rate === 'number' && !isNaN(rate)) ? rate: 0;
	extra = (typeof extra === 'number' && !isNaN(extra)) ? extra: 0;

	// Convert duration from minutes to hours
	const durationInHours = duration / 60;

	// Perform the calculation
	return (rate * durationInHours) + extra;


})(duration, rate, extra))
}


  if (!(getObjectProperty(___arguments.context.rootComponentData, 'sessions'))) {
    return ;
  }
  totalCostAgency = 0;
  totalCostLocum = 0;
  totalDuration = 0;
  var j_list = (getObjectProperty(___arguments.context.rootComponentData, 'sessions'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    duration = (getObjectProperty(j, 'duration'));
    costLocum = await calculateCost(duration, (getObjectProperty(j, 'rateLocum')), (getObjectProperty(j, 'extraLocum')));
    costAgency = await calculateCost(duration, (getObjectProperty(j, 'rateAgency')), (getObjectProperty(j, 'extraAgency')));
    if (duration) {
      totalDuration = duration + totalDuration;
    }
    totalCostLocum = costLocum + totalCostLocum;
    totalCostAgency = costAgency + totalCostAgency;
  }
  if ((getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) != 'locums') {
    agencyFeeText = String(', Agency fee: £') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](totalCostAgency)));
  }

  return (['Total : ',(await BackendlessUI.Functions.Custom['fn_ab1c9e4dddcc718449eda1a4d06cdfce'](totalDuration)),', Locum fee: £',(await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](totalCostLocum)),agencyFeeText].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/b7790c73fae354ed8e4fc70fe47d3d2b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'editable')) && (getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'admin')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'adminEditable.ts-status-editable', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/43c1a4263da981845e150b96b86c1a34/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.rootComponentData['status'] = (getObjectProperty(___arguments.context.pageData, 'selected.originalData.status'));
  ___arguments.context.rootComponentData['statusNote'] = (getObjectProperty(___arguments.context.pageData, 'selected.originalData.statusNote'));
  await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.pageData, 'adminEditable.ts-status-editable', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/f289ef8a61bca02143332e512f52adca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'adminEditable.ts-status-editable'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/03be5c9b629d4dacaf8568c9ccb55cdf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'adminEditable.ts-status-editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/6ae79b7842d705cf9cdd1fd4511c39b2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}


  ___arguments.context.pageData['showCheckboxes'] = (!(getObjectProperty(___arguments.context.pageData, 'showCheckboxes')));
  ___arguments.context.pageData['removeIds'] = [];
  if (!(getObjectProperty(((getObjectProperty(___arguments.context.rootComponentData, 'sessions'))[0]), 'rowId'))) {
    ___arguments.context.pageData['removeIds'] = [];
    var j_list = (getObjectProperty(___arguments.context.rootComponentData, 'sessions'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['rowId'] = (generateUUID().short());
    }
  }
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'showCheckboxes')) {
    return 'Cancel';
  } else {
    return 'Delete session';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/f4630e6b5c7c963a1e499c1bf6ee35af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newSession, mySessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  mySessions = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b246f2c7682ece05669f4814a49cc5a7'));
  if (mySessions.length == 0) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b246f2c7682ece05669f4814a49cc5a7', [(await BackendlessUI.Functions.Custom['fn_746c1e77c956a88fa12d075dabef5d64']('session', (getObjectProperty(___arguments.context.appData, 'currentOrg.___ui'))))]);
  } else {
    newSession = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c']((mySessions.slice(-1)[0])));
    newSession['rowId'] = (generateUUID().short());
    delete newSession['objectId'];
    addItemToList(mySessions, newSession);
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'showCheckboxes')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/89667a2a79ac41dc4ea9f1c696aef3a4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Submitted: ') + String(new Intl.DateTimeFormat('en-GB', ({ [`timeZone`]: 'Europe/London',[`dateStyle`]: 'short',[`timeStyle`]: 'short' })).format((getObjectProperty(___arguments.context.rootComponentData, 'created')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/a8fc71bea0629cc1b41dee7bdfd48a9a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Last Updated: ') + String(new Intl.DateTimeFormat('en-GB', ({ [`timeZone`]: 'Europe/London',[`dateStyle`]: 'short',[`timeStyle`]: 'short' })).format((getObjectProperty(___arguments.context.rootComponentData, 'updated')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/9ef915297449c52653bf4c75ed2d4015/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.time')) != ___arguments['value']) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start'))['time'] = ___arguments['value'];
    ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['start'] = (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.date')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.time'))));
    ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['end'] = (await BackendlessUI.Functions.Custom['fn_14e6171198bfcd5c156a26f3f49af858']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'breaks')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'duration'))));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.end'))['time'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_3e05e1564369ba3972f29448d781cc2d']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'end')))), 'time'));
  }

  },
  /* handler:onComplete */
  /* handler:onChangeValue */
  ['onChangeValue'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start'))['partial'] = ___arguments['value'];

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalTime;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.partial')).indexOf('_') !== -1) {
    originalTime = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.time'));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start'))['time'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start'))['time'] = originalTime;
  }

  },
  /* handler:onLostFocusEvent */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/ee011771d5285e69bdb599829521f528/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    var breaks, end, start, dayOffset, startDateString, endTimeString, startTimeString, currentEndDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.end.time')) != ___arguments['value']) {
    console.log('=== DEBUG, New End Time string entered');
    start = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'start'));
    breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'breaks'));
    startDateString = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.date'));
    startTimeString = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.time'));
    endTimeString = ___arguments['value'];
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.end'))['time'] = endTimeString;
    console.log('Update End');
    dayOffset = (await BackendlessUI.Functions.Custom['fn_a80c86cafe9ac2ba7d276a696a9fdb1c'](startTimeString, endTimeString));
    console.log('dayOffset');
    console.log(dayOffset);
    end = (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a'](startDateString, endTimeString)) + dayOffset * 86400000;
    console.log(end);
    ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['end'] = end;
    console.log('Update Duration');
    ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['duration'] = (await BackendlessUI.Functions.Custom['fn_f72a166723522cd1028ba41fe8aef554'](start, end, breaks));
  }

  },
  /* handler:onComplete */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalTime;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.end.partial')).indexOf('_') !== -1) {
    originalTime = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.end.time'));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.end'))['time'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.end'))['time'] = originalTime;
  }

  },
  /* handler:onLostFocusEvent */
  /* handler:onChangeValue */
  ['onChangeValue'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.end'))['partial'] = ___arguments['value'];

  },
  /* handler:onChangeValue */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/2fff17afce32cbea21e5caab48486ca4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var dateString;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dateString = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.date'));
  if (dateString) {
    return (!((await BackendlessUI.Functions.Custom['fn_9bc571d21c383c3a238bc45bd609c301'](dateString)) == true));
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9bc571d21c383c3a238bc45bd609c301']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.date'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/a3e8212834ec4de68589fb93d6b125a9/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.date')) != ___arguments['value']) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start'))['date'] = ___arguments['value'];
    ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['start'] = (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.date')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.time'))));
    ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['end'] = (await BackendlessUI.Functions.Custom['fn_14e6171198bfcd5c156a26f3f49af858']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'breaks')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'duration'))));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates'))['end'] = (await BackendlessUI.Functions.Custom['fn_3e05e1564369ba3972f29448d781cc2d']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'end'))));
  }

  },
  /* handler:onComplete */
  /* handler:onChangeValue */
  ['onChangeValue'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start'))['partialDate'] = ___arguments['value'];

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.partialDate')).indexOf('d') !== -1) || ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.partialDate')).indexOf('m') !== -1) || ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.partialDate')).indexOf('y') !== -1)) {
    console.log('invalid date detected');
    originalDate = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.date'));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start'))['date'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start'))['date'] = originalDate;
  }

  },
  /* handler:onLostFocusEvent */
  /* handler:blocksLogic */
  async ['blocksLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await (async function(str) {
  	return /\d/.test(str)
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'dates.start.partialDate'))))) {
    return [({ [`name`]: 'dd',[`block`]: ({ [`maskType`]: 'Range',[`from`]: 1,[`to`]: 31,[`placeholderChar`]: '_',[`autofix`]: 'pad' }) }), ({ [`name`]: 'mm',[`block`]: ({ [`maskType`]: 'Range',[`from`]: 1,[`to`]: 12,[`placeholderChar`]: '_',[`autofix`]: 'pad' }) }), ({ [`name`]: 'yy',[`block`]: ({ [`maskType`]: 'Range',[`from`]: 23,[`to`]: 30,[`placeholderChar`]: '_',[`autofix`]: 'pad' }) })];
  } else {
    return [({ [`name`]: 'dd',[`block`]: ({ [`maskType`]: 'Range',[`from`]: 1,[`to`]: 31,[`placeholderChar`]: 'd',[`autofix`]: 'pad' }) }), ({ [`name`]: 'mm',[`block`]: ({ [`maskType`]: 'Range',[`from`]: 1,[`to`]: 12,[`placeholderChar`]: 'm',[`autofix`]: 'pad' }) }), ({ [`name`]: 'yy',[`block`]: ({ [`maskType`]: 'Range',[`from`]: 23,[`to`]: 30,[`placeholderChar`]: 'y',[`autofix`]: 'pad' }) })];
  }

  },
  /* handler:blocksLogic */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/5a8547b6bf912168fd4e60e3b8e58e62/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (new Intl.DateTimeFormat(undefined, ({ [`timeZone`]: 'Europe/London',[`dateStyle`]: 'short',[`timeStyle`]: 'short' })).format((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'start'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/4e52e98f6b1b86afed78949d7fe3f0b4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (new Intl.DateTimeFormat(undefined, ({ [`timeZone`]: 'Europe/London',[`dateStyle`]: 'short',[`timeStyle`]: 'short' })).format((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'end'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/ac12e2a7135feb7897a74abfb52ac708/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'showCheckboxes')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/dec6c7c7218123a81d01859fba29537b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'showCheckboxes')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/6e4f78b6ecce209cc66f1a25b5d97dec/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_ab1c9e4dddcc718449eda1a4d06cdfce']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'duration'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/0b3f1d838cb769f5b7ed2a14e8c8fc63/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'showCheckboxes'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/d671fd64cb0e83582f428fbadc569caa/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'showCheckboxes'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/b06193232796fea9df9111ce0d4f52b3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var breaks, breaksText, dateText, duration, durationText, separator, text, timeText;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  text = (await BackendlessUI.Functions.Custom['fn_3fc304d26564606fdef10399a96a1a45']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'end'))));
  breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'breaks'));
  separator = text.lastIndexOf(', ') + 1;
  dateText = text.slice(0, separator - 1);
  timeText = text.slice(((separator + 2) - 1), text.length);
  duration = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'duration'));
  if (breaks) {
    breaksText = [' + ',breaks,'m breaks'].join('');
  }

  return ([dateText,'<br>',timeText,' (',(await BackendlessUI.Functions.Custom['fn_ab1c9e4dddcc718449eda1a4d06cdfce'](duration)),breaksText,')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/617d995e4923b438c532c0b8bce4a9af/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var rate, cost;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  cost = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'costLocum'));
  rate = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'rateLocum'));
  if (!(cost % 1 == 0)) {
    cost = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](cost));
  }
  if (!(rate % 1 == 0)) {
    rate = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](rate));
  }

  return (['£',cost,' @ £',rate,'/hr'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/668574cedad44c0ed4a4a5cc2a734b14/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String((await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'cost'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/b246f2c7682ece05669f4814a49cc5a7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'editable'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/577d2902d7cc235d2ae6675821e5bb9d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  extra = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'extraLocum'));
  if (!(extra % 1 == 0)) {
    extra = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](extra));
  }

  return (['+ £',extra,' extra charge'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/ea826cf8ec3642efdbf4c1d1b1134571/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var extra;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  extra = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'extraAgency'));
  if (!(extra % 1 == 0)) {
    extra = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](extra));
  }

  return (['+ £',extra,' extra charge'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/d2ca7312b6ca6a4fecef9f9a2b3b8798/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var cost, formattedCost, formattedRate, rate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  cost = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'costAgency'));
  rate = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'rateAgency'));
  if (!cost) {
    formattedCost = 0;
  } else if (!(cost % 1 == 0)) {
    formattedCost = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](cost));
  } else {
    formattedCost = cost;
  }
  if (!rate) {
    formattedRate = 0;
  } else if (!(rate % 1 == 0)) {
    formattedRate = (await BackendlessUI.Functions.Custom['fn_8d0fb074024e2b3901fa17c7542fe12b'](rate));
  } else {
    formattedRate = rate;
  }

  return (['£',formattedCost,' @ £',formattedRate,'/hr'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/496cc2afac5483f4869dc7c42c22ec56/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'notesAgency'));
  if (notes) {
    return (['"',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/54dd0b3897e1f4b2c15ecdd234c5729e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var notes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  notes = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc5a4f126788bf9edfc6f0f4e2bd6dad'), 'notesLocum'));
  if (notes) {
    return (['"',notes,'"'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/073644169f514b65704bcd8a0221e6c2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'editable'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/8cdba464b3855ba5c2b8241283467f74/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/83aeaf37dfe3779efd03b6765bd1ae7c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['duration'] = (await BackendlessUI.Functions.Custom['fn_f72a166723522cd1028ba41fe8aef554']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'end')), ___arguments.value));

  },
  /* handler:onChange */
  /* handler:onBlur */
  ['onBlur'](___arguments) {
    var breaks;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  breaks = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'), 'breaks'));
  if (breaks) {
    if (typeof breaks === 'number' && !isNaN(breaks)) {
      if (!(breaks % 1 == 0)) {
        ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['breaks'] = (Math.round(breaks));
      }
    } else {
      ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc')['breaks'] = null;
    }
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/56713014373dff8aef4d33fe5b634022/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'showCheckboxes'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/dfb5207946829994153632ef48eefcd7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'showCheckboxes')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_1dfdb47a27cf52ba3dbbd27f7c512b35/logic/0ad4725a4192f299ab1d8c8e22d9f1d5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['£',(Number.parseFloat((getObjectProperty((await BackendlessUI.Functions.Custom['fn_3b54db2e49ee77fe587cf71b49b8bdc9'](___arguments.context.appData, ___arguments.context.getComponentDataStoreByUid('e93933138bf8c9a56984567b027474cc'))), 'rateLocumNet'))).toFixed(2)),'/hr'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_74531e4714a6666aa7d15a48c821803a/logic/e89225b19242c03f5ee988aa6a05bb1a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'editable'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.rootComponentData, 'children')), ___arguments.context.getComponentDataStoreByUid('7411fb5ace782a68e35fa5b62198f386'), '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_74531e4714a6666aa7d15a48c821803a/logic/fdee56a190b2147a66a6219bbc6a7ff7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'selected.parent')) && (getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3007cf82998445a2e06dc3ec74ed8a38', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_74531e4714a6666aa7d15a48c821803a/logic/d284fd9184f525447bd15dac6cda6c6d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var child, children, entityType, options, selectedOrgId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  selectedOrgId = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a0ddfa4541797e90aa9b025bf8b096d')), 'content'));
  entityType = (getObjectProperty(___arguments.context.pageData, 'selectedType'));
  if (selectedOrgId.indexOf('-') + 1 == 9 && selectedOrgId.lastIndexOf('-') + 1 == 24) {
    options = (await (async function(arr, value) {
    	    for (let obj of arr) {
    	        if (obj.value === value) {
    	            return obj.label;
    	        }
    	    }
    	    return null;
    })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4403bab6304254fc0b99a4fd86a98e68')), 'options')), selectedOrgId));
    if (!(getObjectProperty(___arguments.context.rootComponentData, 'children'))) {
      ___arguments.context.rootComponentData['children'] = [];
    }
    children = (getObjectProperty(___arguments.context.rootComponentData, 'children'));
    if (entityType == 'locums') {
      child = ({ [`name`]: (await (async function(input) {
      	const endIndex = input.indexOf('(');
      	    if(endIndex === -1) return input.trim();
      	    return input.substring(0, endIndex).trim();
      })(options)),[`billingEmail`]: (await (async function(input) {
      	    const match = input.match(/\(([^)]+)\)/);
      	    if(!match) return ''; // Return empty string if no match found
      	    return match[1];
      })(options)),[`objectId`]: selectedOrgId });
    } else {
      child = ({ [`companyName`]: (await (async function(input) {
      	const endIndex = input.indexOf('(');
      	    if(endIndex === -1) return input.trim();
      	    return input.substring(0, endIndex).trim();
      })(options)),[`billingEmail`]: (await (async function(input) {
      	    const match = input.match(/\(([^)]+)\)/);
      	    if(!match) return ''; // Return empty string if no match found
      	    return match[1];
      })(options)),[`objectId`]: selectedOrgId });
    }
  }
  if (!(await (async function(arr, str, property) {
  	return Array.isArray(arr) && arr.some(obj => obj[property] === str);

  })(children, selectedOrgId, 'objectId'))) {
    addItemToList(children, child);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3007cf82998445a2e06dc3ec74ed8a38', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_74531e4714a6666aa7d15a48c821803a/logic/4403bab6304254fc0b99a4fd86a98e68/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var entityList, j, label, matchingEntities, tableName, whereClause;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function populateDropdown() {
  tableName = (getObjectProperty(___arguments.context.pageData, 'selectedType'));
  if (tableName == 'locums') {
    label = 'name';
  } else {
    label = 'companyName';
  }
  entityList = [];
  matchingEntities = (await Backendless.Data.of(tableName).find(Backendless.DataQueryBuilder.create().setWhereClause((await whereClause2(tableName, label))).setPageSize(100)));
  for (var j_index in matchingEntities) {
    j = matchingEntities[j_index];
    addItemToList(entityList, ({ [`value`]: (getObjectProperty(j, 'objectId')),[`label`]: [(getObjectProperty(j, label)),' (',(getObjectProperty(j, 'billingEmail')),')'].join('') }));
  }
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4403bab6304254fc0b99a4fd86a98e68'))['options'] = entityList;
  ___arguments.context.pageData['relatedOrgs'] = entityList;
}

/**
 * Describe this function...
 */
async function whereClause2(tableName, label) {
  if (tableName == 'locums') {
    label = 'name';
  } else {
    label = 'companyName';
  }
  whereClause = ['(',label,' LIKE \'%',___arguments['inputValue'],'%\' OR billingEmail LIKE \'%',___arguments['inputValue'],'%\') AND objectId !=\'',(getObjectProperty(___arguments.context.rootComponentData, 'objectId')),'\' AND ',tableName,'[children].objectId IS NULL',' AND children.objectId IS NULL'].join('');
  return whereClause
}


  stopSetTimeout('lazySearch');
  if (___arguments['inputValue'].indexOf('-') + 1 == 9 && ___arguments['inputValue'].lastIndexOf('-') + 1 == 24) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a0ddfa4541797e90aa9b025bf8b096d'))['content'] = ___arguments['inputValue'];
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4403bab6304254fc0b99a4fd86a98e68'))['value'] = ___arguments['inputValue'];
    await new Promise(r => setTimeout(r, 1 || 0));
  } else if (!___arguments['inputValue']) {
  } else {

    ;(function() {
      const callback = async () => {
          await populateDropdown();

      };

      const timerId = 'lazySearch';
      const timerDelay = 500;

      runSetTimeout(timerId, callback, timerDelay)
    })()
  }

  },
  /* handler:onChange */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a0ddfa4541797e90aa9b025bf8b096d'))['content'] = '{{selectedUserId}}';

  },
  /* handler:onButtonClearClick */
  /* content */
}))

define('./components/reusable/r_74531e4714a6666aa7d15a48c821803a/logic/31c4d68d82549eee8d23d951c4098ab6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var children;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  children = (getObjectProperty(___arguments.context.rootComponentData, 'children'));
  if (children) {
    return (children.length == 0);
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selected.parent')) {
    return '(Can\'t have subsidaries if it\'s already part of a group)';
  } else {
    return 'None';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_74531e4714a6666aa7d15a48c821803a/logic/463a5ab86d0df6bc00fa88b12e94e39c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedType')) == 'locums') {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7411fb5ace782a68e35fa5b62198f386'), 'name'));
  } else {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7411fb5ace782a68e35fa5b62198f386'), 'companyName'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_06bb6be6743e391aec5cea1643a9c4ba/logic/e89225b19242c03f5ee988aa6a05bb1a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'editable'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.rootComponentData, 'users')), ___arguments.context.getComponentDataStoreByUid('7411fb5ace782a68e35fa5b62198f386'), '');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_06bb6be6743e391aec5cea1643a9c4ba/logic/666zcls6miubiam38wsj6f0un4dl51aq/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'editable'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('h68g8s5k4xiitxtvksoc7hgzf24j7tyz', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_06bb6be6743e391aec5cea1643a9c4ba/logic/d284fd9184f525447bd15dac6cda6c6d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var user, users, selectedUserId, nameAndEmail;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  selectedUserId = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a0ddfa4541797e90aa9b025bf8b096d')), 'content'));
  if (selectedUserId.indexOf('-') + 1 == 9 && selectedUserId.lastIndexOf('-') + 1 == 24) {
    nameAndEmail = (await (async function(arr, value) {
    	    for (let obj of arr) {
    	        if (obj.value === value) {
    	            return obj.label;
    	        }
    	    }
    	    return null;
    })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4403bab6304254fc0b99a4fd86a98e68')), 'options')), selectedUserId));
    if (!(getObjectProperty(___arguments.context.rootComponentData, 'users'))) {
      ___arguments.context.rootComponentData['users'] = [];
    }
    users = (getObjectProperty(___arguments.context.rootComponentData, 'users'));
    user = ({ [`name`]: (await (async function(input) {
    	const endIndex = input.indexOf('(');
    	    if(endIndex === -1) return input.trim();
    	    return input.substring(0, endIndex).trim();
    })(nameAndEmail)),[`email`]: (await (async function(input) {
    	    const match = input.match(/\(([^)]+)\)/);
    	    if(!match) return ''; // Return empty string if no match found
    	    return match[1];
    })(nameAndEmail)),[`objectId`]: selectedUserId });
  }
  if (!(await (async function(arr, str, property) {
  	return Array.isArray(arr) && arr.some(obj => obj[property] === str);

  })(users, selectedUserId, 'objectId'))) {
    addItemToList(users, user);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('h68g8s5k4xiitxtvksoc7hgzf24j7tyz', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_06bb6be6743e391aec5cea1643a9c4ba/logic/4403bab6304254fc0b99a4fd86a98e68/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var userList, j, matchingUsers;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function populateDropdown() {
  userList = [];
  matchingUsers = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['name LIKE \'%',___arguments['inputValue'],'%\' OR email LIKE \'%',___arguments['inputValue'],'%\''].join(''))).setPageSize(100)));
  for (var j_index in matchingUsers) {
    j = matchingUsers[j_index];
    addItemToList(userList, ({ [`value`]: (getObjectProperty(j, 'objectId')),[`label`]: [(getObjectProperty(j, 'name')),' (',(getObjectProperty(j, 'email')),')'].join('') }));
  }
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4403bab6304254fc0b99a4fd86a98e68'))['options'] = userList;
  ___arguments.context.pageData['relatedUsers'] = userList;
}


  stopSetTimeout('lazySearch');
  if (___arguments['inputValue'].indexOf('-') + 1 == 9 && ___arguments['inputValue'].lastIndexOf('-') + 1 == 24) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a0ddfa4541797e90aa9b025bf8b096d'))['content'] = ___arguments['inputValue'];
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4403bab6304254fc0b99a4fd86a98e68'))['value'] = ___arguments['inputValue'];
    await new Promise(r => setTimeout(r, 1 || 0));
  } else if (!___arguments['inputValue']) {
  } else {

    ;(function() {
      const callback = async () => {
          await populateDropdown();

      };

      const timerId = 'lazySearch';
      const timerDelay = 500;

      runSetTimeout(timerId, callback, timerDelay)
    })()
  }

  },
  /* handler:onChange */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a0ddfa4541797e90aa9b025bf8b096d'))['content'] = '{{selectedUserId}}';

  },
  /* handler:onButtonClearClick */
  /* content */
}))

define('./components/reusable/r_06bb6be6743e391aec5cea1643a9c4ba/logic/ulsg6l9f4ztmn4xijsvq75ezkhk56iry/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var users;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  users = (getObjectProperty(___arguments.context.rootComponentData, 'users'));
  if (users) {
    return (users.length == 0);
  }

  return true

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/46a6b5e16364b1a4df9804f6a918f00c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}



  return (textToTitleCase((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.rootComponentData, true))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/4d331d53391fb53525ce5bbb332a014a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, '___class')) == 'locums')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/5e852caf519dd3e41d2be3991015929b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/b6461d6517d43347c1d3e23c0e26df5a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onVisibilityChange */
  ['onVisibilityChange'](___arguments) {
      if (___arguments.newValue) {
    ___arguments.context.rootComponentData['prnType'] = 'GMC';
  }

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/96765d6dcfdb96a5d47b3f78f934c34b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/c2d66dcb7ed704569e331ee9bda1f45c/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var singleEntity;

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  singleEntity = (await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.rootComponentData, true));
  if (singleEntity == 'locum') {
    singleEntity = 'company';
  }

  return (String(textToTitleCase(singleEntity)) + String(' Name'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onRequiredStateAssignment */
  async ['onRequiredStateAssignment'](___arguments) {
      if ((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.rootComponentData, null)) != 'locums') {
    return true;
  }

  },
  /* handler:onRequiredStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/fd313c720cfc15edcf79b691f0fa077f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/fc7cd8d9108d1028b65248382bcb94ee/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedType')) == 'agencies')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/5c1fd66ec22818617db324c659bb537d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var test;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selected.parent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/bfa283825f87c8b5b3abca56804a02e8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selected.parent')) {
    if ((getObjectProperty(___arguments.context.pageData, 'selected.parent.companyName')) === null) {
      return (getObjectProperty(___arguments.context.pageData, 'selected.parent.name'));
    } else {
      return (getObjectProperty(___arguments.context.pageData, 'selected.parent.companyName'));
    }
  } else {
    return '--';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/127a55b8733dc885e8da0e4d99930d4c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/ee48964ecce8c631e3b71c031fb749c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/d596bcd4e9c76d02c16220e2e904cb3b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/cdcad0e56cbc36167fef65c226fee7c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/bc25098be7d9e6c34deb275bdc9d50f3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/d41349be9a7fe36c3aa330c5f6ac6b15/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/be952766cc09aeb2b22d1bfcd11ce948/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/45e9f206d429bc4dfff9fed741234319/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/4018122b5c4f38e519772c5800c6fd88/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/13f9dda2be39688595d52e7d7db5558e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/b73e62d57cebfc7fc5b35feabeefe77c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/4e32d989ea2d14e6052e5213808afb65/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/ed322d2790e82a66553240eb865ea4c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var orgType;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orgType = (getObjectProperty(___arguments.context.rootComponentData, '___class'));

  return (orgType != 'clients')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/9f9b081b39e8eb9b65a84d4eee5bf47a/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
    var orgType;


  orgType = (await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.rootComponentData, true));

  return (['Turn this on if you wish to exclude this ',orgType,' from the payment CSV. This will allow you to make manual payment for them.'].join(''))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/0e32ee6c43948d2c8fa19ac4b087af22/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/d9da851a4e3d882ff41c19ec8fd2787c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((getObjectProperty(___arguments.context.rootComponentData, '___class')).charAt(0).toUpperCase()) + String(' -'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/367a5d293e32022d0d9f6688e15cf9cd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/a5a54a3e02a180dd22b6e7e7cdef63e1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.rootComponentData, 'objectId')) == 'new') {
    return '(complete registration first)';
  } else {
    return '(click "Create GDrive Folder" to generate one)';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.rootComponentData, 'folderPath')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/2199929714030cbf178c80910a87308a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.rootComponentData, 'dirtyOnXero')) == false) {
    return 'Synced on Xero : Yes';
  } else {
    return 'Synced on Xero : No';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/fd8860b508055ad86952ead61ab5de09/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
      return ___arguments.context.rootComponentData

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/76d91661dcbba7ec8e8b0aa720d2771e/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
      return ___arguments.context.rootComponentData

  },
  /* handler:onContainerDataAssignment */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'objectId')) == 'new') {
    return true;
  } else {
    if ((await BackendlessUI.Functions.Custom['fn_1f082225135261fbb908c962d4b69eb8'](___arguments.context.getComponentDataStoreByUid('6d6479c8ffda8b8b18da34cafe972b36'), 'children'))) {
      return true;
    } else if (getObjectProperty(___arguments.context.pageData, 'selected.parent')) {
      return false;
    } else {
      return true;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_4d1d675ac04f9314fb6b31bef755e171/logic/60f6a42dacd9d474dc5bb9e14532286f/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('60f6a42dacd9d474dc5bb9e14532286f');
  try {
    console.log('Deleting:');
    console.log(___arguments.context.rootComponentData);
    await Backendless.Request.delete(`${Backendless.appPath}/services/ACCOUNTS/Org`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'orgType': (getObjectProperty(___arguments.context.rootComponentData, '___class')),'objectId': (getObjectProperty(___arguments.context.rootComponentData, 'objectId')) });
    delete (getObjectProperty(___arguments.context.pageData, 'selected'))['data'];
    delete (getObjectProperty(___arguments.context.pageData, 'selected'))['originalData'];
    ___arguments.context.pageData['editable'] = false;
    console.log('Deleted');
    console.log(___arguments.context.pageData);

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* handler:titleLogic */
  async ['titleLogic'](___arguments) {
    function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}



  return (String('Delete ') + String(textToTitleCase((await BackendlessUI.Functions.Custom['fn_dc1b276aaa6103ad46639636515e1fd5'](___arguments.context.rootComponentData, true)))))

  },
  /* handler:titleLogic */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/05497bb788739c43e4299435e6c93718/bundle.js', [], () => ({
  /* content */
  /* handler:onStartDateChange */
  async ['onStartDateChange'](___arguments) {
    var fromDate, localFromDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign((getObjectProperty(___arguments.context.rootComponentData, 'bundleIssueDate')), ({ [`localFromDate`]: ___arguments['startDate'],[`fromDate`]: (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a']((___arguments['startDate'].toDateString()), '00:00')) }));

  },
  /* handler:onStartDateChange */
  /* handler:onEndDateChange */
  async ['onEndDateChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign((getObjectProperty(___arguments.context.rootComponentData, 'bundleIssueDate')), ({ [`localToDate`]: ___arguments['endDate'],[`toDate`]: (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a'](((new Date(___arguments['endDate'].getTime() + ((1 * 86400) * 1000))).toDateString()), '00:00')) }));

  },
  /* handler:onEndDateChange */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/058391aafd72d85341f61448101c1493/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['filters'] = (await BackendlessUI.Functions.Custom['fn_8111343ee683711cbe8724295429ac7c']((getObjectProperty(___arguments.context.pageData, 'ui.defaultFilters'))));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/4f455246dcf601d99b8dd219343351ae/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var toDate, fromDate, localToDate, localFromDate;


  if (___arguments.value) {
    ___arguments.context.rootComponentData['bundleIssueDate'] = (await BackendlessUI.Functions.Custom['fn_0915b211c351e798c3c54ba97ad3350e']());
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/7856b69da3826b9fba0dfb55e21c6e8e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.rootComponentData, 'bundleStatus', (___arguments['selectValue'].map(item => item['value'])));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/e429898a3a27d4b459a1cded555a4c33/bundle.js', [], () => ({
  /* content */
  /* handler:onStartDateChange */
  async ['onStartDateChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign((getObjectProperty(___arguments.context.rootComponentData, 'bundlePaidDate')), ({ [`localFromDate`]: ___arguments['startDate'],[`fromDate`]: (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a']((___arguments['startDate'].toDateString()), '00:00')) }));

  },
  /* handler:onStartDateChange */
  /* handler:onEndDateChange */
  async ['onEndDateChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign((getObjectProperty(___arguments.context.rootComponentData, 'bundlePaidDate')), ({ [`localToDate`]: ___arguments['endDate'],[`toDate`]: (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a'](((new Date(___arguments['endDate'].getTime() + ((1 * 86400) * 1000))).toDateString()), '00:00')) }));

  },
  /* handler:onEndDateChange */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/edb41a9adb22de06b462752f407886e6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var toDate, fromDate, localToDate, localFromDate;


  if (___arguments.value) {
    ___arguments.context.rootComponentData['bundlePaidDate'] = (await BackendlessUI.Functions.Custom['fn_0915b211c351e798c3c54ba97ad3350e']());
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/e799b39ffe7dd37fb835210eb6131d17/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      if (___arguments.value) {
    ___arguments.context.rootComponentData['timesheetSubmittedDate'] = (await BackendlessUI.Functions.Custom['fn_0915b211c351e798c3c54ba97ad3350e']());
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/20bd3c5a563e1048949850bd8b83fc6e/bundle.js', [], () => ({
  /* content */
  /* handler:onStartDateChange */
  async ['onStartDateChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign((getObjectProperty(___arguments.context.rootComponentData, 'timesheetSubmittedDate')), ({ [`localFromDate`]: ___arguments['startDate'],[`fromDate`]: (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a']((___arguments['startDate'].toDateString()), '00:00')) }));

  },
  /* handler:onStartDateChange */
  /* handler:onEndDateChange */
  async ['onEndDateChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign((getObjectProperty(___arguments.context.rootComponentData, 'timesheetSubmittedDate')), ({ [`localToDate`]: ___arguments['endDate'],[`toDate`]: (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a'](((new Date(___arguments['endDate'].getTime() + ((1 * 86400) * 1000))).toDateString()), '00:00')) }));

  },
  /* handler:onEndDateChange */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/beda3c0e0894b82eccb42c021296472e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      if (___arguments.value) {
    ___arguments.context.rootComponentData['sessionDates'] = (await BackendlessUI.Functions.Custom['fn_0915b211c351e798c3c54ba97ad3350e']());
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/82ae947863ca5f5d44a8e456903b7d01/bundle.js', [], () => ({
  /* content */
  /* handler:onStartDateChange */
  async ['onStartDateChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign((getObjectProperty(___arguments.context.rootComponentData, 'sessionDates')), ({ [`localFromDate`]: ___arguments['startDate'],[`fromDate`]: (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a']((___arguments['startDate'].toDateString()), '00:00')) }));

  },
  /* handler:onStartDateChange */
  /* handler:onEndDateChange */
  async ['onEndDateChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Object.assign((getObjectProperty(___arguments.context.rootComponentData, 'sessionDates')), ({ [`localToDate`]: ___arguments['endDate'],[`toDate`]: (await BackendlessUI.Functions.Custom['fn_df0fb7e70159da6cb31112e4d8da310a'](((new Date(___arguments['endDate'].getTime() + ((1 * 86400) * 1000))).toDateString()), '00:00')) }));

  },
  /* handler:onEndDateChange */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/c8a936fa810c1ed223571f935053a718/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.rootComponentData, 'agencyInvStatus', (___arguments['selectValue'].map(item => item['value'])));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_bda1de2337b62c085f28c64e0e574958/logic/dac1b06154c495c7a8f3f18fd85644d9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_a6649aae860ad2589b2b1691eca72b44'](___arguments.context.rootComponentData, 'timesheetStatus', (___arguments['selectValue'].map(item => item['value'])));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_9de3d35d230d8a5364c643ef87b855cd/logic/412a7ed7efdd715afd29f3fb12342cc8/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'currentUser'))

  },
  /* handler:onContainerDataAssignment */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var userItems, embeddedTimesheets, timesheets;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function directToLoginPage() {
  if (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).slice(0, 6) != 'public') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('publicLogin', undefined);
  }
}


  console.log('\\/ ======== [Main_Nav] ======== \\/');
  if (Backendless.getCurrentUserToken()) {
    if (!(getObjectProperty(___arguments.context.appData, 'currentUser'))) {
      console.log('---> No App Data present, redirecting to loading');
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loading', ({ 'redirect': ((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) }));
    }
    console.log('---> App Data (current page)');
    console.log(___arguments.context.appData);
  } else {
    await directToLoginPage();
  }
  console.log('/\\ ======== [Main_Nav] ======== /\\');
  console.log('');

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_9de3d35d230d8a5364c643ef87b855cd/logic/ab3923cdc21270fb3c72581a9afc683a/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  ['onTextAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('412a7ed7efdd715afd29f3fb12342cc8'), 'firstName')).charAt(0)) + String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('412a7ed7efdd715afd29f3fb12342cc8'), 'lastName')).charAt(0)))

  },
  /* handler:onTextAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_9de3d35d230d8a5364c643ef87b855cd/logic/3bf2f37d934c9b9245abfd1e3dcabad4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('publicLanding', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_9de3d35d230d8a5364c643ef87b855cd/logic/58521108429f0db41f042856c22bca48/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var message;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('412a7ed7efdd715afd29f3fb12342cc8'), 'objectId')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_9de3d35d230d8a5364c643ef87b855cd/logic/bd1f7d9ba899dd9a92e129b954c9f90c/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      if (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('_') + 1 == 0) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3bf2f37d934c9b9245abfd1e3dcabad4', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c83fddbb55d66dc9541c1fabac1493d4', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3bf2f37d934c9b9245abfd1e3dcabad4', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c83fddbb55d66dc9541c1fabac1493d4', true);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_9de3d35d230d8a5364c643ef87b855cd/logic/c83fddbb55d66dc9541c1fabac1493d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function () { window.history.back(); })();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_9de3d35d230d8a5364c643ef87b855cd/logic/4534d842a9b462ecfad97a692101d86f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('profile', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_9de3d35d230d8a5364c643ef87b855cd/logic/d59cb8e380f31e9b3d8f1f2945195108/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.appData, 'currentOrg.___class')) == 'admin')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_9de3d35d230d8a5364c643ef87b855cd/logic/4d6ef1c9205ee8a5442db8ae36312d52/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(String('User is Admin: ') + String((await BackendlessUI.Functions.Custom['fn_953ad59737414af3d40b6a3d8c5f2201'](___arguments.context.appData))));
  console.log(String('Page editable: ') + String(getObjectProperty(___arguments.context.pageData, 'editable')));
  console.log('--------------------');
  console.log('Page Data');
  console.log(___arguments.context.pageData);
  console.log('--------------------');
  console.log('App Data:');
  console.log(___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_9de3d35d230d8a5364c643ef87b855cd/logic/bf7dfacb07a883534cd7ae69330f0e9c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var roles;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  roles = (getObjectProperty(___arguments.context.appData, 'currentUser.roles'));
  if (roles) {
    return (roles.includes('Admin'));
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

